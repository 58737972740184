import petitionPost from "../../../../../services/petitionPost";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import { formatTimeForReport } from "../../utils";

const smsReportService = async (selectedNumbers, timeframe) => {
  const data = { phone_numbers: selectedNumbers.map((number) => number.number)};

  const timeStart = timeframe.timeStart;
  const timeEnd = timeframe.timeEnd;
  const hourStart = formatTimeForReport(timeframe.hourStart, false);
  const hourEnd = formatTimeForReport(timeframe.hourEnd, true);

  // Formatear las fechas en el formato requerido: YYYY-MM-DDThh:mm:ss
  const formattedStartDate = `${timeStart}T${hourStart}`;
  const formattedEndDate = `${timeEnd}T${hourEnd}`;

  const parameter = `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

  const { data: result } = await petitionPost("smsCallReports", { data, parameter })

  result.result.forEach((number) => {
    number.formatedNumber = formatPhoneNumber(number.Phone)
  })

  return result.result
}

export default smsReportService;