import { useState } from "react";
import { LoadingButton } from "../../../../../../../components";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";

const RecordSound = ({ addMediaFile, loadingButton }) => {
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  let audioChunks = [];

  const startRecording = () => {
    let mediaRecorderTemp;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderTemp = new MediaRecorder(stream);

        mediaRecorderTemp.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunks.push(e.data);
          }
        };

        mediaRecorderTemp.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
        };

        mediaRecorderTemp.start();
        setRecording(true);
        setMediaRecorder(mediaRecorderTemp);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const handleSave = () => {
    if (loadingButton) return;
    const file = new File([audioBlob], "audiofile.mp3", { type: "audio/mp3" });
    addMediaFile(file);
  };

  return (
    <>
      <label className="itp-label-input__label itp-analytics-label-select">
        To start recording click on play button and to stop click on pause
      </label>
      <div>
        {!recording ? (
          <PlayCircleOutlineIcon className="cursor-pointer" onClick={startRecording} />
        ) : (
          <PauseIcon className="cursor-pointer" onClick={stopRecording} />
        )}

        {audioBlob && (
          <div className="mt-2">
            <audio controls src={audioUrl}></audio>
          </div>
        )}
      </div>
      <button
        onClick={handleSave}
        className="btn-primary itp-lead-detail-button-save loading mb-4"
        disabled={!audioBlob || !audioUrl}
      >
        {loadingButton && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default RecordSound;
