import React from "react";
import { FormGroup, Row, Col, Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faTag } from "@fortawesome/free-solid-svg-icons";
import { TagComponent } from "../../index";

/**
 * Section component for displaying and managing contact tags
 * Enhanced with colorful pill-shaped tags and improved autocomplete
 */
const TagsSection = ({ isEditing, disabled, tags, handleTag, setOpenModalColor }) => {
  // Array of default tag colors for more visual appeal
  const tagColors = [
    "#e02020", // Red
    "#f7e528", // Yellow
    "#ff9838", // Orange
    "#8f5530", // Brown
    "#8de257", // Light Green
    "#008000", // Green
    "#8fe1e7", // Light Blue
    "#0074bd", // Blue
  ];

  // Function to determine the background color of a tag
  const getTagBackgroundColor = (tag, index) => {
    if (!tag.tag_color || tag.tag_color === "") {
      return "rgb(237, 246, 253)"; // Default light blue background when tag_color is falsy or empty
    }
    return tag.tag_color || tagColors[index % tagColors.length];
  };

  // Function to determine the text color based on background color
  const getTagTextColor = (backgroundColor) => {
    if (
      backgroundColor === "#f7e528" || 
      backgroundColor === "#8fe1e7" || 
      backgroundColor === "#8de257" ||
      backgroundColor === "rgb(237, 246, 253)"
    ) {
      return "#333"; // Dark text for light backgrounds
    }
    return "white"; // White text for dark backgrounds
  };

  return (
    <FormGroup>
      <div className="itp-field-label">Tags</div>
      {isEditing ? (
        // Edit mode - show tag component with add button
        <Row>
          <Col xs={8}>
            <TagComponent
              disabled={disabled}
              tags={tags}
              handleTag={handleTag}
              loadingData={false}
              refer={null}
              imageClick={() => {}}
              icon={null}
            />
            
            <small className="text-muted mt-1 d-block">
              Type to search or add new tags
            </small>
          </Col>
          <Col xs={4}>
            <Button
              color="primary"
              size="sm"
              className="mt-1 rounded-pill d-flex align-items-center justify-content-center"
              onClick={() => setOpenModalColor(true)}
            >
              <FontAwesomeIcon icon={faPlus} className="me-1" /> Add tag
            </Button>
          </Col>
        </Row>
      ) : (
        // Read-only mode - show horizontal row of colorful tags
        tags && tags.length > 0 ? (
          <div className="tags-container d-flex flex-wrap">
            {tags.map((tag, index) => {
              const bgColor = getTagBackgroundColor(tag, index);
              return (
                <div 
                  key={tag.pk || index}
                  className="tag-pill d-inline-flex align-items-center justify-content-center me-2 mb-2"
                  style={{ 
                    backgroundColor: bgColor,
                    color: getTagTextColor(bgColor),
                    padding: "5px 10px",
                    borderRadius: "16px",
                    fontSize: "0.85rem",
                    fontWeight: "500"
                  }}
                >
                  <span>{tag.inputValue || tag.title}</span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-muted">No tags</div>
        )
      )}
    </FormGroup>
  );
};

export default TagsSection; 