import axios from "axios";
import json from "../../../../../../config.json";

const unassignUserFromAreaCodeService = async (info) => {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const url = urlBase + `/itpvoice/v2/${id}/automatic-callerid/areas/${info.account_area_id}/assignments/${info.id}`;

  const data = {
    user_id: info.user_id,
    number_id: info.number_id,
  };

  const headers = { Authorization: "Bearer " + token.replace(/['"]+/g, "") };

  await axios.delete(url, { headers, data });  
};

export default unassignUserFromAreaCodeService;

