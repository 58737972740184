import { fetchContactDetails, fetchCustomFields } from "../index"

const getContactModalDataService = async (contactId) => {
  const customFieldsArray = await fetchCustomFields()
  const contactDetails = await fetchContactDetails(contactId)

  return { customFieldsArray, contactDetails };
}

export default getContactModalDataService
