import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CharacterCounter } from "../../../../components";
import PhoneInput from "react-phone-input-2";

const ModalEnterCompanyName = ({ modal, closeModal, modalFunction, modalAction }) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const [form, setForm] = useState({
    companyName: "",
    websiteForSupport: "",
    phoneNumberForSupport: "",
  });

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    const onlyCompanyName = ["optinMessage", "optoutMessage"];

    if (onlyCompanyName.includes(modalAction)) {
      setDisabledButton(!form.companyName || form.companyName.length > 15);
    } else if (modalAction === "helpMessage") {
      setDisabledButton(!form.companyName || form.companyName.length > 15 || !form.websiteForSupport || form.phoneNumberForSupport?.length < 11);
    }
  }, [form, modalAction]);

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader className="bg-soft-primary">Variables</ModalHeader>
      <ModalBody>
        <label className="itp-label-input__label">Enter a company Name</label>
        <input
          type="text"
          className="form-control-itp mb-2"
          placeholder="Company Name"
          name="companyName"
          onChange={handleChangeForm}
          value={form.companyName}
        />
        <CharacterCounter
          text={form.companyName}
          charMin={1}
          charMax={15}
          className="mb-3"
        />

        {modalAction === "helpMessage" && (
          <>
            <label className="itp-label-input__label">
              Enter a website for support
            </label>
            <input
              type="text"
              className="form-control-itp mb-2"
              placeholder="Website for support"
              name="websiteForSupport"
              onChange={handleChangeForm}
              value={form.websiteForSupport}
            />

            <label className="itp-label-input__label">
              Enter a phone number for support
            </label>
            <PhoneInput
              enableSearch={true}
              onlyCountries={["us"]}
              preferredCountries={["us"]}
              country={"us"}
              value={form.phoneNumberForSupport}
              onChange={(e) => handleChangeForm({ target: { name: "phoneNumberForSupport", value: e } })}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={closeModal}>
          Cancel
        </button>

        <button
          className="btn-primary"
          onClick={() => modalFunction(form)}
          disabled={disabledButton}
        >
          Add
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEnterCompanyName;
