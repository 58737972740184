import petitionGet from "../../../../../services/petitionGet";

const fetchContactDetails = async (e, setLoadingContactDetails, setLeadDetails) => {
  setLoadingContactDetails(true)
  const contactId = e?.contact?.pk;

  if (!contactId) {
    setLoadingContactDetails(false);
    return;
  }

  try {
    const parameter = `/${contactId}`;
    const { data: result } = await petitionGet("contacts", { parameter })

    result.result.tags = result.result.tags.map((tag) => ({
      ...tag,
      title: tag.tag.name,
      tag_color: tag.tag.tag_color,
      pk: tag.tag_id,
    }));

    setLeadDetails((prev) => ({ ...prev, contact: result.result }))
    setLoadingContactDetails(false);
  } catch (error) {
    console.log(error);
    setLoadingContactDetails(false);
  }
};

export default fetchContactDetails;
