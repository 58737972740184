import { getTimeStaoFromDate, formatTimeForReport } from '../index';

const selectTimeStartAndEnd = (selectedReport, timeframe) => {
  if (selectedReport === 'Users' || selectedReport === 'Numbers') {
    const { timeStart, timeEnd } = getTimeStaoFromDate(timeframe);
    return { timeStart, timeEnd };
  }

  const timeStart = timeframe.timeStart;
  const timeEnd = timeframe.timeEnd;
  const hourStart = formatTimeForReport(timeframe.hourStart, false);
  const hourEnd = formatTimeForReport(timeframe.hourEnd, true);

  // Formatear las fechas en el formato requerido: YYYY-MM-DD HH:MM:SS
  const formattedStartDate = `${timeStart} ${hourStart}`;
  const formattedEndDate = `${timeEnd} ${hourEnd}`;

  return { timeStart: formattedStartDate, timeEnd: formattedEndDate };
};

export default selectTimeStartAndEnd;
