import React from "react";
import { Button, Spinner, Alert } from "reactstrap";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useContactSidebar from "../hooks/useContactSidebar";
import LeadInfoSection from "./LeadInfoSection";
import ContactDetailsSection from "./ContactDetailsSection";
import CustomFieldsSection from "./CustomFieldsSection";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

/**
 * Sidebar component for the contact details modal
 * Contains information about the contact and controls for editing
 */
export const ContactSidebar = (props) => {
  const {
    isEditing,
    isSaving,
    toggleEdit,
    handleSave,
    error,
    loadData
  } = useContactSidebar(props);

  const { loading = false } = props;

  return (
    <div className="itp-contact-sidebar">
      <div className="d-flex justify-content-between align-items-center mb-2 pt-0 mt-0">
        <h6 className="mb-0 itp-about-contact-title">About this contact</h6>
        {loading ? (
          <Skeleton width={70} height={30} />
        ) : isEditing ? (
          <div>
            <Button
              color="light"
              size="sm"
              className="me-2"
              onClick={() => toggleEdit(true)}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="sm"
              className="itp-save-button"
              onClick={handleSave}
              disabled={isSaving || (props.leadDetails && !props.valueStage)}
            >
              {isSaving ? (
                <>
                  <Spinner size="sm" className="me-1" /> Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        ) : (
          <Button
            color="link"
            size="sm"
            className="itp-edit-button p-0"
            onClick={() => toggleEdit()}
          >
            <FontAwesomeIcon icon={faPen} className="me-1" /> Edit
          </Button>
        )}
      </div>

      {/* Error message */}
      {error && !loading && (
        <Alert color="danger" className="mb-3 py-2">
          {error}
        </Alert>
      )}

      {/* Lead Information Section */}
      {loading && props.leadDetails ? (
        <div className="itp-lead-section mt-4 mb-4">
          <h6 className="mb-3">Lead Information</h6>
          <Skeleton count={4} height={40} className="mb-3" />
        </div>
      ) : props.leadDetails && (
        <LeadInfoSection 
          isEditing={isEditing}
          leadForm={props.leadForm}
          leadData={props.leadData}
          handleLeadInputChange={props.handleLeadInputChange}
          valueLeadSource={props.valueLeadSource}
          handleLeadSourceChange={props.handleLeadSourceChange}
          valueFunnel={props.valueFunnel}
          loadOptionFunnel={props.loadOptionFunnel}
          handleChangeFunnel={props.handleChangeFunnel}
          loadingFunnels={props.loadingFunnels}
          loadingStages={props.loadingStages}
          valueStage={props.valueStage}
          stageOptions={props.stageOptions}
          handleChangeStage={props.handleChangeStage}
        />
      )}

      {/* New organized contact sections */}
      {loading ? (
        <div className="mb-4">
          <Skeleton count={12} height={40} className="mb-3" />
        </div>
      ) : (
        <ContactDetailsSection
          isEditing={isEditing}
          form={props.form}
          contactData={props.contactData}
          handleInputChange={props.handleInputChange}
          countryValue={props.countryValue}
          setCountryValue={props.setCountryValue}
          disabled={props.disabled}
          tags={props.tags}
          handleTag={props.handleTag}
          setOpenModalColor={props.setOpenModalColor}
          valueContactList={props.valueContactList}
          setValueContactList={props.setValueContactList}
          contactLists={props.contactLists}
          isLoadingContactLists={props.isLoadingContactLists}
          loadContactListOptions={loadData.loadContactListOptions}
          asignedUser={props.asignedUser}
          setAsignedUser={props.setAsignedUser}
          users={props.users}
          isLoadingUsers={props.isLoadingUsers}
          loadUsers={loadData.loadUsers}
        />
      )}

      {/* Custom Fields Section */}
      {!loading && props.customFieldsRef.current && props.customFieldsRef.current.length > 0 && (
        <>
          <CustomFieldsSection
            isEditing={isEditing}
            customFieldsRef={props.customFieldsRef}
            form={props.form}
            setForm={props.setForm}
            contactData={props.contactData}
            handleInputChange={props.handleInputChange}
            myExtension={props.myExtension}
            handleAddedCustomField={props.handleAddedCustomField}
            handleEditedCustomField={props.handleEditedCustomField}
            handleDeletedCustomField={props.handleDeletedCustomField}
          />
        </>
      )}
    </div>
  );
};
