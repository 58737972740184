import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../../components";
import { useRef, useState } from "react";
import "./UploadFile.css"

const UploadFile = ({ addMediaFile, loadingButton }) => {
  const inputRef = useRef(null);

  const [reload, setReload] = useState(1);

  const handleChangeInputFile = () => {
    setTimeout(() => {
      setReload(reload + 1);
    }, [100]);
  };

  const handleSave = () => {
    if (loadingButton) return;
    const file = inputRef.current.files[0]
    addMediaFile(file);
  };

  return (
    <>
      <Row>
        <Col className="d-flex col-12 pt-4 d-flex flex-column">
          <div className="d-flex align-items-center">
            <label
              className="btn-primary itp-upload-file__button"
              htmlFor="input_file"
            >
              Browse Files
            </label>

            <input
              onChange={handleChangeInputFile}
              id="input_file"
              className="d-none form-control"
              type="file"
              accept="audio/mp3,audio/wav"
              ref={inputRef}
            />
            <span>Only MP3 or WAV Files Are Allowed</span>
          </div>
          <label className="mt-3 itp-label-input__label itp-analytics-label-select">
            {inputRef.current && inputRef.current.files.length !== 0 && inputRef.current.files[0].name}
          </label>
        </Col>
      </Row>

      <button
        onClick={handleSave}
        className="btn-primary itp-lead-detail-button-save loading mb-4"
        disabled={!inputRef.current || inputRef.current.files.length === 0 || !inputRef.current.files[0]}
      >
        {loadingButton && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default UploadFile;
