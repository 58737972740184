import petitionPatch from "../../../../../../services/petitionPatch";

const updateCallerIdService = async (e, callerIdNumber, setCallerIdNumber) => {
  try {
    setCallerIdNumber(e)

    const data = { default_outbound_callerid_number: e.value };
    await petitionPatch("userDetails", { data })
  } catch (error) {
    console.log(error)
    setCallerIdNumber(callerIdNumber)
  }
};

export default updateCallerIdService;