import petitionGet from "../../../../services/petitionGet";

const fetchNotes = async (contact_id, setNotes, setEditNote) => {
  const parameter = `?contact_id=${contact_id}`;
  const { data: result } = await petitionGet("contactNotes", { parameter });

  let showsMenus = {};
  let editNote = {};

  result.result.forEach((note) => {
    showsMenus[note.pk] = false;
    editNote[note.pk] = {
      pk: note.pk,
      edit: false,
      note_message: note.note_message,
    };
  });

  setNotes(result.result);
  setEditNote(editNote);

  return result;
};

export default fetchNotes;
