import petitionGet from "../../../../../services/petitionGet";

const fetchLeadDetails = async (e, setLeadDetails, setLoadingMenu) => {
  try {
    setLoadingMenu(true)

    const { data: result } = await petitionGet("leads", { parameter: `/${e.pk}` })

    result.result.contact.tags = e?.contact?.tags?.map((tag) => ({
      ...tag,
      title: tag.tag.name,
      tag_color: tag.tag.tag_color,
      pk: tag.tag_id,
    }));

    setLeadDetails(result.result)
    setLoadingMenu(false)
  } catch (error) {
    console.log(error)
  }
}

export default fetchLeadDetails;