import { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import LeadSource from "../../LeadSource/LeadSource";
import petitionGet from "../../../services/petitionGet";
import LoadingButton from "../../LoadingButton/LoadingButton";

/**
 * Component for adding or editing lead
 * @param {Object} props - Component props
 * @param {Function} props.onBack - Function to handle back button click
 * @param {Function} props.onSave - Function to handle save button click
 */

const AddEditLead = ({ onBack, onSave, loadingButton, contactId, error, leadDetails }) => {
  const [loadingFunnels, setLoadingFunnels] = useState(false);
  const [stageOptions, setStageOptions] = useState([]);
  const [loadingStages, setLoadingStages] = useState(false);
  const [form, setForm] = useState({
    name: '',
    amount: '0',
    leadSource: null,
    funnel: null,
    stage: null,
  });

  const handleLeadSourceChange = (e) => {
    setForm((prev) => ({ ...prev, leadSource: e }));
  };

  const handleChangeFunnel = (selected) => {
    if (!selected) return;

    setForm({ ...form, funnel: selected, stage: null });
    loadStages(selected.value);
  };

  const handleChangeStage = (selected) => {
    if (!selected) return;

    setForm({ ...form, stage: selected });
  };

  const handleChangeTextInput = (e) => {
    const { name, value } = e.target;

    if (name === 'amount') {
      // Only update if value is a number or empty string
      if (value === '' || /^[0-9]+(\.[0-9]*)?$/.test(value)) {
        setForm({ ...form, [name]: value });
      }
    } else {
      // Update normally for other fields
      setForm({ ...form, [name]: value });
    }
  };

  const loadOptionFunnel = (inputValue, callback) => {
    setLoadingFunnels(true);

    petitionGet('funnels', { parameter: inputValue ? `?name=${inputValue}` : '?offset=0&limit=10' })
      .then(({ data: result }) => {
        if (result && result.result) {
          const options = result.result.map((funnel) => ({
            value: funnel.pk,
            label: funnel.name || '',
            pk: funnel.pk,
            ...funnel,
          }));

          callback(options);
        } else {
          callback([]);
        }
        setLoadingFunnels(false);
      })
      .catch((error) => {
        console.error('Error loading funnels:', error);
        callback([]);
        setLoadingFunnels(false);
      });
  };

  const loadStages = async (funnelId) => {
    if (!funnelId) return;

    try {
      setLoadingStages(true);
      const response = await petitionGet('stages', { parameter: `?funnel_id=${funnelId}` });

      if (response && response.data && response.data.result) {
        const options = response.data.result.map((stage) => ({
          value: stage.pk,
          label: stage.name,
          ...stage,
        }));

        setStageOptions(options);
      }
    } catch (error) {
      console.error('Error loading stages:', error);
    } finally {
      setLoadingStages(false);
    }
  };

  const handleSave = () => {
    if (loadingButton) return;

    const data = {
      lead_source_id: parseInt(form.leadSource.value),
      funnel_id: parseInt(form.funnel.value),
      funnel_pipeline_stage_id: parseInt(form.stage.value),
      contact_id: contactId,
    };

    if (leadDetails) {
      data.name = form.name;
      data.amount = form.amount;
    }

    onSave(data);
  };

  useEffect(() => {
    if (leadDetails) {
      const leadInfo = leadDetails._originalData;

      setForm({
        name: leadInfo.name || '',
        amount: leadInfo.amount || '0',
        leadSource: {
          label: leadInfo.lead_source.name || '',
          value: leadInfo.lead_source.pk || '',
        },
        funnel: {
          label: leadInfo?.funnel_pipeline_stage?.funnel?.name || '',
          value: leadInfo?.funnel_pipeline_stage?.funnel?.pk || '',
        },
        stage: {
          label: leadInfo?.funnel_pipeline_stage?.name || '',
          value: leadInfo?.funnel_pipeline_stage?.pk || '',
        },
      });

      loadStages(leadInfo?.funnel_pipeline_stage?.funnel?.pk);
    }
  }, [leadDetails]);

  return (
    <div className="itp-add-lead-form">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>{leadDetails ? 'Edit' : 'Add New'} Lead</h5>
        <button className="btn btn-light" onClick={onBack}>
          Back
        </button>
      </div>

      {leadDetails && (
        <>
          <div className="form-group mb-3">
            <label className="form-label">Lead Name</label>
            <input
              name="name"
              type="text"
              className="form-control-itp"
              value={form.name}
              onChange={handleChangeTextInput}
            />
          </div>

          <div className="form-group mb-3">
            <label className="form-label">Amount</label>
            <input
              name="amount"
              type="text"
              className="form-control-itp"
              value={form.amount}
              onChange={handleChangeTextInput}
            />
          </div>
        </>
      )}

      <div className="form-group mb-3">
        <LeadSource
          defaultLeadSource={form.leadSource}
          onChange={handleLeadSourceChange}
          isEditing={true}
        />
      </div>

      <div className="form-group mb-3">
        <label className="form-label">Funnel</label>
        <AsyncSelect
          onChange={handleChangeFunnel}
          className="basic-single"
          classNamePrefix="select"
          value={form.funnel}
          isSearchable={true}
          name="funnel_id"
          loadOptions={loadOptionFunnel}
          defaultOptions
          cacheOptions
          isLoading={loadingFunnels}
          placeholder="Choose funnel"
        />
      </div>

      <div className="form-group mb-3">
        {loadingStages ? (
          <>
            <div className="itp-field-label">Stage</div>
            <div className="d-flex align-items-center">
              <LoadingButton />
            </div>
          </>
        ) : (
          form.funnel && (
            <>
              <div className="itp-field-label">Stage</div>
              {stageOptions.length > 0 ? (
                <Select
                  onChange={handleChangeStage}
                  className="basic-single"
                  classNamePrefix="select"
                  value={form.stage}
                  isSearchable={true}
                  name="funnel_pipeline_stage_id"
                  options={stageOptions}
                  placeholder="Choose stage"
                />
              ) : (
                <div className="text-muted">No stages available for this funnel</div>
              )}
            </>
          )
        )}
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      <div className="d-flex justify-content-end">
        <button
          disabled={!form.leadSource || !form.funnel || !form.stage}
          className="btn-primary loading"
          onClick={handleSave}
        >
          {loadingButton && <LoadingButton />}
          {leadDetails ? 'Edit Lead' : 'Save Lead'}
        </button>
      </div>
    </div>
  );
};

export default AddEditLead;
