import { useState, useEffect } from "react";
import petitionPost from "../../../services/petitionPost";
import petitionPatch from "../../../services/petitionPatch";
import axios from "axios";

export const useContactDetailsState = ({
  contact,
  customFields,
  formatTags,
  getLastColor,
  onSave,
}) => {
  const [activeTab, setActiveTab] = useState("activity");
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const [tags, setTags] = useState([]);
  const [openModalColor, setOpenModalColor] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [avatarColor, setAvatarColor] = useState('rgb(109, 40, 217)');
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [contactLists, setContactLists] = useState([]);
  const [isLoadingContactLists, setIsLoadingContactLists] = useState(false);
  const [valueContactList, setValueContactList] = useState(null);
  const [asignedUser, setAsignedUser] = useState(null);
  const [countryValue, setCountryValue] = useState(null);

  const [contactData, setContactData] = useState({
    pk: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    dnd_sms: false,
    tags: [],
    contact_list_id: '',
    owner_id: '',
  });

  const [form, setForm] = useState({
    pk: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    dnd_sms: false,
    tags: [],
    contact_list_id: '',
    owner_id: '',
  });

  // Toggle function for tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // Update contact data when contact prop changes
  useEffect(() => {
    if (contact) {
      const contactInfo = {
        pk: contact.pk || contact.id || "",
        firstname: contact.firstname || "",
        lastname: contact.lastname || "",
        email: contact.email || "",
        phone: contact.phone || "",
        address: contact.address || "",
        city: contact.city || "",
        state: contact.state || "",
        country: contact.country || "",
        zipcode: contact.zipcode || "",
        dnd_sms: contact.dnd_sms || false,
        tags: contact.tags || [],
        contact_list_id: contact.contact_list_id || "",
        owner_id: contact.owner_id || "",
      };

      // Add custom fields directly from contact object
      if (customFields && customFields.length > 0) {
        customFields.forEach((field) => {
          if (field.name && contact[field.name] !== undefined) {
            contactInfo[field.name] = contact[field.name];
          }
        });
      }

      setContactData(contactInfo);
      setForm(contactInfo);
      setTags(contact.tags || []);
      
      // Calculate initial avatar color based on contact's tags when contact changes
      updateAvatarColor(contact.tags || []);
    }
  }, [contact, customFields]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle tag changes
  const handleTag = async (newValue) => {
    if (newValue[newValue.length - 1]?.inputValue) {
      const tag = newValue[newValue.length - 1];
      setDisabled(true);
      await addTag(tag);
      setTags(newValue);
    } else {
      setTags(newValue);
    }
    setForm({
      ...form,
      tags: newValue,
    });
    
    // Update avatar color whenever tags change
    updateAvatarColor(newValue);
  };

  // Add new tag
  const addTag = async (tag) => {
    try {
      const response = await petitionPost("addTags", {
        data: {
          name: tag.inputValue,
          tag_color: tag.tag_color || "",
        },
      });

      // Si response es un objeto con propiedad 'data'
      if (response && typeof response === 'object' && 'data' in response) {
        const result = response.data;
        let data = [...newTags];
        data.push(result.result);
        setNewTags(data);
      }
      
      setDisabled(false);
    } catch (error) {
      console.error("Error adding tag:", error);
      setDisabled(false);
    }
  };

  // Toggle edit mode
  const toggleEdit = async (reset = false) => {
    if (reset) {
      setError('');
      setIsSaving(false);
      setIsEditing(false);
      setForm({ ...contactData });
      setTags(contactData.tags || []);
      updateAvatarColor(contactData.tags || []);
      return;
    }

    if (isEditing) {
      // Save changes
      setIsSaving(true);
      setError("");
      try {
        // Format data for API
        const data = {
          ...form,
          phone: form.phone && form.phone.length > 0 ? `+${form.phone}` : null,
          tags: formatTags(tags, newTags),
          contact_list_id: valueContactList?.value,
          owner_id: asignedUser?.value,
        };

        // Call API to save changes
        if (typeof onSave === "function") {
          await onSave(data);
        } else {
          await petitionPatch("contact", { data, contact_id: form.pk });
        }

        // Update local state with new data
        setContactData(form);
        
        // Update avatar color with the new tags after saving
        updateAvatarColor(tags);
        
        setIsSaving(false);
        setIsEditing(false);
      } catch (error) {
        console.error("Error saving contact:", error);
        setIsSaving(false);

        // Handle error
        if (axios.isAxiosError(error)) {
          const axiosError = error;
          if (axiosError.response?.status === 400) {
            const errorData = axiosError.response.data;
            const messageError = errorData?.error?.error_message;
            setError(messageError || "Error saving contact");
          } else {
            setError("An unexpected error occurred");
          }
        } else {
          setError("An unexpected error occurred");
        }

        // Revert form to original data
        setForm(contactData);
        setTags(contactData.tags || []);
      }
    } else {
      setIsEditing(true);
      setError("");
    }
  };

  // Get avatar color from tags
  const getAvatarColor = () => {
    // Get color from last tag or use default purple if no valid color found
    const tagColor = getLastColor(tags);
    // Return tag color (which will be either a valid color or the default purple)
    return tagColor;
  };

  // Update avatar color
  const updateAvatarColor = (tagList) => {
    const tagColor = getLastColor(tagList);
    setAvatarColor(tagColor);
  };

  // Close tag modal
  const closeTagModal = () => {
    setOpenModalColor(false);
  };

  return {
    activeTab,
    contactData,
    form,
    isEditing,
    isSaving,
    error,
    tags,
    disabled,
    avatarColor,
    toggleTab,
    handleInputChange,
    handleTag,
    toggleEdit,
    getAvatarColor,
    setOpenModalColor,
    openModalColor,
    closeTagModal,
    setForm,
    setContactData,
    users,
    setUsers,
    isLoadingUsers,
    setIsLoadingUsers,
    contactLists,
    setContactLists,
    isLoadingContactLists,
    setIsLoadingContactLists,
    valueContactList,
    setValueContactList,
    asignedUser,
    setAsignedUser,
    countryValue,
    setCountryValue,
  };
}; 