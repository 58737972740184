import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { assignUserToAreaCodeService, editCallerIdService, fetchAreaCodeAssignments, fetchUsers, unassignUserFromAreaCodeService } from "../../services";
import { AddButton, CustomTableMain, LoadingButton } from "../../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const ModalEditAreaCode = ({ modal, closeModal, areaCodeInfo, numbers }) => {
  const [loading, setLoading] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [action, setAction] = useState("")
  const [formattedNumbers, setFormattedNumbers] = useState([])
  const [formAssignUser, setFormAssignUser] = useState({ user: null, number: null })
  const [isLoadingFetchUsers, setIsLoadingFetchUsers] = useState(false)
  const [users, setUsers] = useState(null)
  const [info, setInfo] = useState(null)
  const [newCallerId, setNewCallerId] = useState(null)
  const [dataTableAssignments, setDataTableAssignments] = useState({
    columns: [
      { name: "User", key: "userName" },
      { name: "Assigned Number", key: "assignedNumber" },
    ],
    content: [],
    actions: {
      title: "Actions",
      content: [],
    },
  });

  const handleClickAction = (action, data) => {
    setAction(action);

    if (data) {
      const callerId = formattedNumbers.find((number) => number.label === formatPhoneNumber(data?.user?.default_outbound_callerid_number))
      setNewCallerId(callerId);
      setInfo(data);
    }
  }

  const handleChangeSelect = (name, value) => {
    setFormAssignUser({ ...formAssignUser, [name]: value })
  }

  const handleChangeCallerId = (value) => {
    setNewCallerId(value)
  }

  const handleClickBackButton = () => {
    if (action) {
      setAction("")
      setFormAssignUser({ user: null, number: null })
      setLoadingButton(false)
      setInfo(null);
      setNewCallerId(null);
    } else {
      closeModal()
    }
  }

  const handleClickSaveButton = () => {
    if (action === "add") {
      assignUser()
    }

    if (action === "unassign") {
      unassignUser()
    }

    if (action === "edit") {
      editCallerId()
    }
  }

  const assignUser = async () => {
    if (loadingButton) return

    try {
      setLoadingButton(true)

      await assignUserToAreaCodeService(formAssignUser, areaCodeInfo.pk)

      await petition()

      handleClickBackButton()
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
    }
  }

  const unassignUser = async () => {
    if (loadingButton) return

    try {
      setLoadingButton(true)

      await unassignUserFromAreaCodeService(info)

      await petition()

      handleClickBackButton()
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
    }
  }

  const editCallerId = async () => {
    if (loadingButton) return

    try {
      setLoadingButton(true)

      await editCallerIdService(info, newCallerId)

      await petition()

      handleClickBackButton()
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
    }
  }

  const petitionUsers = async () => {
    try {
      const users = await fetchUsers()
      setUsers(users)
      setIsLoadingFetchUsers(false)
    } catch (error) {
      console.log(error)
      setIsLoadingFetchUsers(false)
    }
  }

  const petition = async () => {
    try {
      setLoading(true)
      const areaCodeAssignments = await fetchAreaCodeAssignments(areaCodeInfo.pk)

      setDataTableAssignments({
        columns: [
          { name: "User", key: "userName" },
          { name: "Assigned Number", key: "assignedNumber" },
        ],
        content: [...areaCodeAssignments],
        actions: {
          title: "Actions",
          content: [
            {
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              name: "Edit",
              handleClick: (e) => handleClickAction("edit", e),
            },
            {
              icon: <FontAwesomeIcon icon={faTrash} />,
              name: "Delete",
              handleClick: (e) => handleClickAction("unassign", e),
            },
          ],
        },
      });

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isLoadingFetchUsers && !users) {
      petitionUsers();
    }
  }, [isLoadingFetchUsers]);

  useEffect(() => {
    const formattedNumbers = numbers.map((number) => ({
      label: formatPhoneNumber(number.number),
      value: number.pk,
    }));

    setFormattedNumbers(formattedNumbers);
  }, [numbers]);

  useEffect(() => {
    petition()
  }, [])

  return (
    <Modal size={!action ? "lg" : "md"} isOpen={modal}>
      <ModalHeader>{action === "edit" ? `Edit Caller ID for ${info.userName}` : `Manage Caller ID for ${areaCodeInfo.area_id}`}</ModalHeader>
      <ModalBody>
        {action === "add" && (
          <>
            <label className="itp-label-input__label">User</label>
            <Select
              options={users || []}
              onChange={(e) => handleChangeSelect("user", e)}
              className="basic-single"
              classNamePrefix="select"
              value={formAssignUser.user}
              isSearchable={true}
              name="user"
              placeholder="Select User"
              isLoading={isLoadingFetchUsers}
              onMenuOpen={() => {
                if (!users) setIsLoadingFetchUsers(true);
              }}
            />

            <label className="itp-label-input__label mt-4">Number</label>
            <Select
              options={formattedNumbers}
              onChange={(e) => handleChangeSelect("number", e)}
              className="basic-single"
              classNamePrefix="select"
              value={formAssignUser.number}
              isSearchable={true}
              name="number"
              placeholder="Select Number"
            />
          </>
        )}

        {action === "edit" && (
          <>
            <label className="itp-label-input__label">Caller ID</label>
            <Select
              options={formattedNumbers}
              onChange={handleChangeCallerId}
              className="basic-single"
              classNamePrefix="select"
              value={newCallerId}
              isSearchable={true}
              name="newCallerId"
              placeholder="Select Caller ID"
            />
          </>
        )}

        {action === "unassign" && (
          <>
            <p style={{ fontSize: "1rem" }}>
              Are you sure you want to unassign {info.userName} from this
              number?
            </p>
          </>
        )}

        {action === "" && (
          <>
            <CustomTableMain
              data={dataTableAssignments}
              loading={loading}
              module="area code assignments"
              actionsRight={[
                {
                  component: (
                    <AddButton
                      handleClick={() => handleClickAction("add")}
                      name="Assign User"
                    />
                  ),
                },
              ]}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          disabled={loadingButton}
          className="btn-light"
          onClick={handleClickBackButton}
        >
          {action ? "Back" : "Close"}
        </button>

        {action && (
          <button
            className="btn-primary loading"
            onClick={handleClickSaveButton}
            disabled={action === "add" && !formAssignUser.user && !formAssignUser.number}
          >
            {loadingButton && <LoadingButton />}
            {action === "add" ? "Assign User" : action === "unassign" ? "Unassign User" : "Edit Caller ID"}
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalEditAreaCode;
