import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col, Dropdown, Card } from "react-bootstrap";
import { ToggleImage } from "../index";
import { formatDate } from "../../utils/getDate";
import useScreenSize from "../../hooks/useScreenSize";
import Skeleton from "react-loading-skeleton";
import "./ColumnsOfDraggableCards.css";

const ColumnsOfDraggableCards = ({
  activeFunctionDetails,
  loading,
  dataColumns,
  setDataColumns,
  textNoData,
  dragEndFunction,
  actions,
  dragDisabled,
}) => {
  const { width } = useScreenSize();
  const [dropdownIndex, setDropdownIndex] = useState(null);

  const handleCardHover = (item) => {
    setDropdownIndex(item.id);
  };

  const handleCardLeave = () => {
    setDropdownIndex(null);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    const sourceColumn = dataColumns.columns[source.droppableId];
    const destColumn = dataColumns.columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    if (sourceColumn.id !== destColumn.id) {
      const [removed] = sourceItems.splice(source.index, 1);
      removed.newItem = true;
      destItems.splice(destination.index, 0, removed);

      const newData = {
        ...dataColumns,
        columns: {
          ...dataColumns.columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        },
      };

      const column_id = dataColumns.columns[destColumn.id].info.pk;
      destItems.map((item, i) => {
        item.newPosition = i;
      });

      setDataColumns(newData);
      dragEndFunction(column_id, destItems);
    } else {
      const [removed] = sourceItems.splice(source.index, 1);
      sourceItems.splice(destination.index, 0, removed);
      const newData = {
        ...dataColumns,
        columns: {
          ...dataColumns.columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
        },
      };

      const column_id = dataColumns.columns[destColumn.id].info.pk;

      sourceItems.map((item, i) => {
        item.newPosition = i;
      });
      setDataColumns(newData);
      dragEndFunction(column_id, sourceItems);
    }
  };

  const object = loading ? { 1: {}, 2: {}, 3: {} } : dataColumns.columns 

  return (
    <>
      {!Object.keys(object).length ? (
        <Card>
          <Card.Body className="d-flex justify-content-center align-items-center">
            <h1>{textNoData}</h1>
          </Card.Body>
        </Card>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Row className="itp-dragdrop-container">
            {Object.keys(object).map((columnId, i) => {
              const column = 
                loading 
                ? { items: [{ title: "1", image: true, subtitle: true, description: true, labelFooter: true, tags: [{}, {}, {}, {}], date: true, actions: false }] } 
                : dataColumns.columns[columnId];

              const numberColumn = loading ? [] : Object.keys(dataColumns.columns);

              return (
                <Droppable key={loading ? i : columnId} droppableId={loading ? i : columnId}>
                  {(provided) => (
                    <Col
                      {...provided.droppableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className={`itp-dragdrop-column-container${
                        i !== numberColumn.length - 1
                          ? " itp-dragdrop-space-columns"
                          : ""
                      }`}
                      xs={width < 462 ? 9 : 7}
                      sm={6}
                      md={width < 823 ? 5 : 4}
                      lg={width < 1077 ? 5 : 4}
                      xl={3}
                      xxl={3}
                    >
                      <Row className="d-flex justify-content-between">
                        <Col xs={12}>
                          <h4 className="itp-dragdrop-column-title">
                            {loading ? (
                              <Skeleton height={20} width="40px" />
                            ) : (
                              <>{column.title} </>
                            )}
                          </h4>
                          <div className="d-flex align-items-center flex-wrap">
                            <span className="itp-drapdrop-subtitle mr-subtitle">
                              {loading ? (
                              <Skeleton height={20} width="60px" />
                            ) : (
                              <>{column.subtitle.split("&")[0]}</>
                            )}
                            </span>
                            <span className="itp-drapdrop-subtitle">
                              {loading ? (
                              <Skeleton height={20} width="60px" />
                            ) : (
                              <>{column.subtitle.split("&")[1] || ""}</>
                            )}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      {column.items.map((item, index) => (
                        <Draggable
                          key={loading ? index : item.id}
                          draggableId={loading ? index : item.id}
                          index={index}
                          isDragDisabled={dragDisabled}
                        >
                          {(provided, snapshot) => (
                            <Row
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              style={{
                                marginBottom: "0.2rem",
                                borderRadius: snapshot.isDragging ? "20px" : "",
                                backgroundColor: snapshot.isDragging
                                  ? "rgba(20, 98, 181, 0.2)"
                                  : "transparent",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <Col>
                                <Row
                                  className={`itp-dragdrop-row-card${
                                    index === column.items.length - 1
                                      ? " mb-dragdrop"
                                      : ""
                                  }`}
                                >
                                  <Card
                                    onMouseLeave={handleCardLeave}
                                    onMouseEnter={() => handleCardHover(item)}
                                    onClick={() => {
                                      if (activeFunctionDetails) {
                                        activeFunctionDetails(item);
                                      }
                                    }}
                                    className={`itp-dragdrop-card-container ${
                                      !item.smallLabel1 || !item.smallLabel2
                                        ? "h-card-drag"
                                        : ""
                                    }`}
                                  >
                                    <Card.Body className="itp-dragdrop-cardbody-container">
                                      <div style={{ marginTop: "8px" }}>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">
                                            {item.image && (
                                              <>
                                                {loading ? (
                                                  <Skeleton borderRadius="14px" height={32} width="32px" />
                                                ) : (
                                                  <>{item.image}</>
                                                )}
                                              </>
                                            )}

                                            {item.title && (
                                              <p 
                                                onClick={typeof item?.titleClick === "function" ? () => item?.titleClick(item) : () => {}} 
                                                className={`itp-dragdrop-cardbody-title ${item?.titleClick ? "cursor-pointer" : ""}`}
                                              >
                                                {loading ? (
                                                  <div className="ms-2">
                                                    <Skeleton height={20} width="100px" />
                                                  </div>
                                                ) : (
                                                  <>{item.title}</>
                                                )}
                                              </p>
                                            )}
                                          </div>
                                          <Dropdown
                                            style={{
                                              visibility:
                                                item?.actions &&
                                                dropdownIndex === item.id
                                                  ? "visible"
                                                  : "hidden",
                                            }}
                                          >
                                            <Dropdown.Toggle className="nav-link btn-secondary">
                                              <ToggleImage />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {item?.actions && item?.actions.map(
                                                (element, i) => (
                                                  <Dropdown.Item
                                                    key={i}
                                                    onClick={() =>
                                                      element.handleClick(item)
                                                    }
                                                  >
                                                    {element.icon}{" "}
                                                    {element.name}
                                                  </Dropdown.Item>
                                                )
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                        <div style={{ marginLeft: "2.15rem" }}>
                                          {item.subtitle && (
                                            <p className="itp-dragdrop-cardbody-subtitle">
                                              {loading ? (
                                                <Skeleton height={50} width="150px" />
                                              ) : (
                                                <>{item.subtitle}</>
                                              )}
                                            </p>
                                          )}

                                          {item.description && (
                                            <p className="itp-dragdrop-cardbody-subtitle mt-1">
                                              {loading ? (
                                                <Skeleton height={20} width="90px" />
                                              ) : (
                                                <>{item.description}</>
                                              )}
                                            </p>
                                          )}

                                          {item.labelFooter && (
                                            <p className="itp-dragdrop-cardbody-labelFooter mt-1">
                                              {loading ? (
                                                <Skeleton height={20} width="50px" />
                                              ) : (
                                                <>{item.labelFooter}</>
                                              )}
                                            </p>
                                          )}

                                          {item.tags && (
                                            <div className="d-flex flex-wrap">
                                              {item.tags.map(
                                                (element, indexTag) => (
                                                  <>
                                                    {loading ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <span
                                                          className="itp-dragdrop-tag"
                                                          key={indexTag}
                                                        >
                                                          {element}
                                                        </span>
                                                      </>
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </div>
                                          )}

                                          {item.date && (
                                            <span className="itp-dragdrop-date">
                                              {loading ? (
                                                <Skeleton height={20} width="100px" />
                                              ) : (
                                                <>{formatDate(item.date)}</>
                                              )}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Col>
                  )}
                </Droppable>
              );
            })}
          </Row>
        </DragDropContext>
      )}
    </>
  );
};

export default ColumnsOfDraggableCards;
