import React, { useState, Fragment } from 'react';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import { CharacterCounter, ResizingTextarea } from '../../../../../components/index';
import { returnInputs } from './const';
import ModalEnterCompanyName from '../../ModalUtility/ModalEnterCompanyName';
import formatPhoneNumber from '../../../../../utils/formatPhoneNumber';

const Step4 = ({ form, setForm }) => {
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState('');

  const closeModal = () => {
    setModal(false);
  };

  const addCompanyValueToExample = (modalForm) => {
    let newExampleText = '';

    if (modalAction === 'optinMessage') {
      let exampleText = '{{COMPANY_NAME}} - Thank you for signing up! . You may receive up to 1 message per month. Msg & data rates may apply. Reply HELP for assistance, STOP to opt-out';
      newExampleText = exampleText.replaceAll('{{COMPANY_NAME}}', modalForm.companyName);
    }

    if (modalAction === 'helpMessage') {
      let exampleText = '{{Company Name}}: For support, visit {{website}} or call {{number}}. Msg & data rates may apply';
      newExampleText = exampleText.replaceAll('{{Company Name}}', modalForm.companyName);
      newExampleText = newExampleText.replaceAll('{{website}}', modalForm.websiteForSupport);
      newExampleText = newExampleText.replaceAll('{{number}}', formatPhoneNumber('+' + modalForm.phoneNumberForSupport));
    }

    if (modalAction === 'optoutMessage') {
      let exampleText = '{{COMPANY_NAME}} - You have been opted out, no further messages will be sent. Reply HELP for assistance. Msg & Data rates may apply.';
      newExampleText = exampleText.replaceAll('{{COMPANY_NAME}}', modalForm.companyName);
    }

    setForm({ ...form, [modalAction]: newExampleText });
    closeModal();
  };

  const handleChangeFields = (e) => {
    const { name, value } = e.target;

    const fieldReplacedWhiteSpaces = ['optinKeywords', 'optoutKeywords', 'helpKeywords'];
    const normalFields = ['helpMessage', 'optoutMessage', 'optinMessage'];
    const selectFields = ['embeddedPhone', 'ageGated', 'embeddedLink', 'directLending'];

    if (fieldReplacedWhiteSpaces.includes(name)) {
      setForm({ ...form, [name]: value.replaceAll(' ', ',') });
    }

    if (normalFields.includes(name)) {
      setForm({ ...form, [name]: value });
    }

    if (selectFields.includes(name)) {
      setForm({ ...form, [name]: value === 'true' ? true : false });
    }

    if (name === 'affiliateMarketing') {
      setForm({ ...form, [name]: !form[name] });
    }
  };

  const handleClickToolipIcon = (key) => {
    const modalKeys = ['optinMessage', 'helpMessage', 'optoutMessage'];

    if (modalKeys.includes(key)) {
      setModal(true);
      setModalAction(key);
    } else {
      const objExampleText = {
        optinKeywords: 'START,YES',
        optoutKeywords: 'STOP',
        helpKeywords: 'HELP',
        helpMessage: "Thanks for texting us. If you're having trouble you may give us a call or send us an email",
        optoutMessage: 'To stop receiving messages please respond with STOP',
      };

      setForm({ ...form, [key]: objExampleText[key] });
    }
  };

  const inputs = returnInputs(handleClickToolipIcon);

  return (
    <>
      <h3>Final Questions</h3>

      {modal && <ModalEnterCompanyName modal={modal} closeModal={closeModal} modalFunction={addCompanyValueToExample} modalAction={modalAction} />}

      {inputs.map((input) => (
        <Fragment key={input.key}>
          {input.input !== 'checkbox' && (
            <>
              <Row className={`itp-container-label-${input.input === 'select' ? 'select' : 'input'}`}>
                <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} className="itp-label-input-col">
                  <label className="itp-label-input__label">{input.label}</label>
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} className={`itp-label-input-col ${input.toolip ? 'position-relative' : ''}`}>
                  {input.input === 'select' && (
                    <select className="form-select-itp" onChange={handleChangeFields} value={form[input.key]} name={input.key}>
                      {input.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  )}

                  {input.input === 'text' && (
                    <>
                      <input className="form-control-itp itp-label-input__input" placeholder={input.placeholder} handleChange={handleChangeFields} name={input.key} value={form[input.key]} />

                      {input.toolip && (
                        <>
                          <div onClick={() => input.handleClick()} data-tip data-for={input.key} className="campaign-toolip-icon">
                            <FontAwesomeIcon icon={faQuestion} />
                          </div>
                          <div className="campaigns-toolip">{input.toolipText}</div>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>

              {input.counter && (
                <div className="mb-4 -mt-3">
                  <CharacterCounter text={form[input.key]} charMin={input.charMin} charMax={input.charMax} />
                </div>
              )}
            </>
          )}

          {input.input === 'checkbox' && (
            <div className="d-flex gap-1 align-items-center">
              <input type="checkbox" className="form-check-input itp-custom-checkbox-contact-details mt-4" checked={input?.inverse ? !form[input.key] : form[input.key]} onChange={handleChangeFields} />
              <label className="itp-label-input__label mt-4">{input.label}</label>
            </div>
          )}
        </Fragment>
      ))}
    </>
  );
};

export default Step4;
