import { CallSummary, CallDescription, CallAudioTranscription } from "./components";
import "./CallTranscription.css";

const CallTranscription = ({ audioType, customDownload, transcriptions, audioUrl, callSummaryData }) => (
  <>
    <CallSummary callSummaryData={callSummaryData} />

    <CallDescription callSummaryData={callSummaryData} />

    <CallAudioTranscription 
      audioType={audioType} 
      customDownload={customDownload}
      transcriptions={transcriptions}
      audioUrl={audioUrl}
    />
  </>
);

export default CallTranscription;
