import { Dropdown } from "react-bootstrap";

const DropdownButton = ({ name, actions, className }) => (
  <Dropdown className={className || ""}>
    <Dropdown.Toggle variant="primary btn-primary">{name}</Dropdown.Toggle>
    <Dropdown.Menu>
      {actions.map((action) => (
        <Dropdown.Item key={action.name} onClick={() => action.handleClick(action.name)}>
          {action.name}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default DropdownButton;
