const getExportReportKey = (selectedReport) => {
  switch (selectedReport) {
    case 'Users':
      return 'usersReportExport';   
    case 'Numbers':
      return 'numbersReportExport';
    case 'Queues':
      return 'queueReportExport';
    case 'Queue Members':
      return 'queueMemberReportExport';
    case 'SMS':
      return 'smsReportExport';
    default:
      break;
  }
};

export default getExportReportKey;
