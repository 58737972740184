import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LoadingButton } from '../../../../../components';

const ModalConfirm = ({ modal, closeModal, text, modalFunction, loading }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>Confirm</ModalHeader>
      <ModalBody>Are you sure to {text}</ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={closeModal}>
          Close
        </button>
        <button className="btn-primary loading" onClick={modalFunction}>
          {loading && <LoadingButton />}
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirm;
