import { useState, useEffect } from "react";
import { fetchLeadsAssignedToContact } from "../services/leadsService";

/**
 * Custom hook to manage leads data for a contact
 * @param {Object} props - Hook props
 * @param {Object} props.contactData - Contact data object
 * @param {string} props.activeTab - Currently active tab
 * @param {boolean} props.leads - Whether leads tab is enabled
 * @returns {Object} - Leads state and handlers
 */
export const useLeadsData = ({ contactData, activeTab, leads }) => {
  const [leadsCount, setLeadsCount] = useState(0);
  const [isLoadingLeads, setIsLoadingLeads] = useState(true);
  const [leadsLoaded, setLeadsLoaded] = useState(false);

  // Determine if we should load the leads component
  const shouldLoadLeads = leads && activeTab === "leads";

  /**
   * Loads leads data for the contact
   */
  const loadLeadsData = async () => {
    if (!contactData?.pk) return;
    
    try {
      setIsLoadingLeads(true);

      const parameter = `?contact_id=${contactData.pk}`;
      const result = await fetchLeadsAssignedToContact(parameter);
      
      setLeadsCount(result.item_count > 0 ? result.item_count + 1 : 0);
      setLeadsLoaded(true);
    } catch (err) {
      console.error("Error loading leads data:", err);
    } finally {
      setIsLoadingLeads(false);
    }
  };

  // Load data when contact changes or leads tab is enabled
  useEffect(() => {
    if (contactData?.pk && leads && !leadsLoaded) {
      loadLeadsData();
    }
  }, [contactData?.pk, leads]);

  // Effect to handle tab changes and load data accordingly
  useEffect(() => {
    if (shouldLoadLeads && !leadsLoaded) {
      loadLeadsData();
    }
  }, [activeTab, leadsLoaded, shouldLoadLeads]);

  return { leadsCount, isLoadingLeads, setLeadsCount };
}; 