import petitionGet from "../../../../../../services/petitionGet";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const fetchAreaCodeAssignments = async (areaCodeId) => {
  const { data: result } = await petitionGet("areaCodeAssignments", { areaCodeId });
  const areaCodeAssignments = result.result.reduce((acc, element) => {
    if (element.user && typeof element.user === "object" && element.user.pk) {
      acc.push({
        ...element,
        userName: (element?.user?.firstname || "") + " " + (element?.user?.lastname || ""),
        assignedNumber: formatPhoneNumber(element?.number?.number || ""),
      });
    }
    return acc;
  }, []);

  return areaCodeAssignments;
}

export default fetchAreaCodeAssignments;
