import petitionPatch from "../../../../../services/petitionPatch";

const editFunnelService = async (field, funnel_id, currentForm, form, setForm, setAlertType, setAlertTitle, setAlertMessage, setShowErrorTime) => {
  try {
    const data = { [field]: form[field] };
    
    await petitionPatch("funnel", { funnel_id, data })

    currentForm.current = form
  } catch (error) {
    console.log(error)
    setForm(currentForm.current)
    setAlertType("error")
    setAlertTitle("Error")
    setAlertMessage("An error has ocurred")
    setShowErrorTime(3000)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  }
}

export default editFunnelService;