import React from "react";
import { FormGroup, Input } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

/**
 * Component for displaying and editing the assigned user for a contact
 * Enhanced with user avatars/profile pictures
 */
const AssignedUserSection = ({
  isEditing,
  asignedUser,
  setAsignedUser,
  users,
  isLoadingUsers,
  loadUsers,
}) => {
  // Format option with user avatar for react-select
  const formatOptionLabel = ({ value, label }) => (
    <div className="d-flex align-items-center">
      <div 
        className="rounded-circle bg-primary me-2 d-flex align-items-center justify-content-center"
        style={{ width: "30px", height: "30px", minWidth: "30px" }}
      >
        <FontAwesomeIcon icon={faUser} className="text-white" size="sm" />
      </div>
      <div>{label}</div>
    </div>
  );

  return (
    <FormGroup className="mt-3">
      <div className="itp-field-label">Assigned User</div>
      {isEditing ? (
        <Select
          onChange={setAsignedUser}
          className="basic-single"
          classNamePrefix="select"
          value={asignedUser}
          isSearchable={true}
          name="owner_id"
          options={users}
          isLoading={isLoadingUsers}
          placeholder="Select a User"
          formatOptionLabel={formatOptionLabel}
          onFocus={() => {
            if (users.length === 0) {
              loadUsers();
            }
          }}
        />
      ) : (
        <div className="d-flex align-items-center">
          <div 
            className="rounded-circle bg-primary me-2 d-flex align-items-center justify-content-center"
            style={{ width: "30px", height: "30px", minWidth: "30px" }}
          >
            <FontAwesomeIcon icon={faUser} className="text-white" size="sm" />
          </div>
          <Input 
            type="text" 
            disabled 
            value={asignedUser?.label || ""} 
            className="border-0 bg-transparent"
          />
        </div>
      )}
    </FormGroup>
  );
};

export default AssignedUserSection; 