const handleTextCopy = (text, event) => {
  if (event) event.preventDefault(); event.stopPropagation()

  navigator.clipboard
    .writeText(text)
    .then(() => {})
    .catch((error) => {
      console.error(error);
    });
};

export default handleTextCopy;
