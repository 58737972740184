import React, { useState } from "react";
import TagsSection from "./TagsSection";
import ContactListSection from "./ContactListSection";
import BasicInfoSection from "./BasicInfoSection";
import LocationSection from "./LocationSection";
import AssignedUserSection from "./AssignedUserSection";
import { FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEnvelope, faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

/**
 * Component to organize contact details into logical sections with dividers
 * Sections include: Basic Info, Contact Info, Tags & Opt-Out, Assignment
 * Enhanced with UI improvements for better user interaction
 */
const ContactDetailsSection = ({
  isEditing,
  form,
  contactData,
  handleInputChange,
  countryValue,
  setCountryValue,
  disabled,
  tags,
  handleTag,
  setOpenModalColor,
  valueContactList,
  setValueContactList,
  contactLists,
  isLoadingContactLists,
  loadContactListOptions,
  asignedUser,
  setAsignedUser,
  users,
  isLoadingUsers,
  loadUsers,
}) => {
  // State for tooltips
  const [tooltipOpen, setTooltipOpen] = useState({
    email: false,
    phone: false,
    location: false,
    optOut: false,
  });

  // Toggle tooltip function
  const toggle = (tooltip) => {
    setTooltipOpen({
      ...tooltipOpen,
      [tooltip]: !tooltipOpen[tooltip],
    });
  };

  // Handle click on email icon
  const handleEmailClick = () => {
    if (!isEditing && contactData.email) {
      window.location.href = `mailto:${contactData.email}`;
    }
  };

  // Handle click on phone icon
  const handlePhoneClick = () => {
    if (!isEditing && contactData.phone) {
      window.location.href = `tel:${contactData.phone}`;
    }
  };

  // Handle click on map icon
  const handleMapClick = () => {
    if (!isEditing && contactData.address && countryValue?.label) {
      const searchQuery = `${contactData.address}, ${contactData.city || ""}, ${contactData.state || ""}, ${countryValue?.label || ""}`;
      window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(searchQuery)}`, '_blank');
    }
  };

  return (
    <>
      {/* Section 1: Basic Info */}
      <div>
        <h6 className="itp-section-title">Basic Info</h6>

        {/* First Name with inline edit icon */}
        <FormGroup className="position-relative">
          <div className="itp-field-label">First Name</div>
          <div className="d-flex align-items-center">
            <Input
              type="text"
              name="firstname"
              value={isEditing ? form.firstname || '' : contactData.firstname || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="pe-4"
            />
            {!isEditing && (
              <div
                className="position-absolute end-0 me-3 text-muted"
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faPen} size="xs" />
              </div>
            )}
          </div>
        </FormGroup>

        {/* Last Name with inline edit icon */}
        <FormGroup className="position-relative">
          <div className="itp-field-label">Last Name</div>
          <div className="d-flex align-items-center">
            <Input
              type="text"
              name="lastname"
              value={isEditing ? form.lastname || '' : contactData.lastname || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="pe-4"
            />
            {!isEditing && (
              <div
                className="position-absolute end-0 me-3 text-muted"
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faPen} size="xs" />
              </div>
            )}
          </div>
        </FormGroup>
      </div>

      {/* Section 2: Contact Info */}
      <div className="itp-section-divider">
        <h6 className="itp-section-title">Contact Info</h6>

        {/* Email with mailto icon */}
        <FormGroup>
          <div className="itp-field-label">Email</div>
          <div className="position-relative">
            <Input
              type={isEditing ? 'email' : 'text'}
              name="email"
              value={isEditing ? form.email || '' : contactData.email || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={!isEditing && contactData.email ? 'pe-4 cursor-pointer' : ''}
            />
            {!isEditing && contactData.email && (
              <div
                className="position-absolute end-0 top-50 translate-middle-y me-3 text-primary"
                style={{ cursor: 'pointer' }}
                onClick={handleEmailClick}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            )}
          </div>
        </FormGroup>

        {/* Phone with tel icon */}
        <FormGroup>
          <div className="itp-field-label">Phone</div>
          <div className="position-relative">
            <div className="position-relative">
              <BasicInfoSection
                isEditing={isEditing}
                form={form}
                contactData={contactData}
                handleInputChange={handleInputChange}
                phoneOnly={true}
              />
              {!isEditing && contactData.phone && (
                <div
                  className="position-absolute end-0 top-50 translate-middle-y me-3 text-primary"
                  style={{ cursor: 'pointer', zIndex: 5 }}
                  onClick={handlePhoneClick}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </div>
              )}
            </div>
          </div>
        </FormGroup>

        {/* Location fields with map icon */}
        <FormGroup>
          <div className="itp-field-label">Location</div>
          <div className="position-relative">
            <LocationSection
              isEditing={isEditing}
              form={form}
              contactData={contactData}
              handleInputChange={handleInputChange}
              countryValue={countryValue}
              setCountryValue={setCountryValue}
              showLabel={false}
            />
            {!isEditing && contactData.address && (
              <div
                className="position-absolute end-0 top-50 translate-middle-y me-3 text-primary"
                style={{ cursor: 'pointer', zIndex: 5 }}
                onClick={handleMapClick}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
            )}
          </div>
        </FormGroup>
      </div>

      {/* Section 3: Tags & Opt-Out */}
      <div className="itp-section-divider">
        <h6 className="itp-section-title">Tags & Opt-Out</h6>

        {/* Tags Section - enhanced with colorful pills */}
        <TagsSection
          isEditing={isEditing}
          disabled={disabled}
          tags={tags}
          handleTag={handleTag}
          setOpenModalColor={setOpenModalColor}
        />

        {/* Opt-Out checkbox with tooltip */}
        <FormGroup check className={isEditing ? "mt-3 position-relative" : "mt-4 opt-out-container"}>
          <Input
            type="checkbox"
            name="dnd_sms"
            checked={isEditing ? form.dnd_sms : contactData.dnd_sms}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
          <div className="itp-field-label">Opted Out (Do not receive messages)</div>
        </FormGroup>
      </div>

      {/* Section 4: Assignment */}
      <div className="itp-section-divider">
        <h6 className="itp-section-title">Assignment</h6>

        {/* Assigned User - will be enhanced with avatar in AssignedUserSection component */}
        <AssignedUserSection
          isEditing={isEditing}
          asignedUser={asignedUser}
          setAsignedUser={setAsignedUser}
          users={users}
          isLoadingUsers={isLoadingUsers}
          loadUsers={loadUsers}
        />

        {/* Contact List Section */}
        <ContactListSection
          isEditing={isEditing}
          valueContactList={valueContactList}
          setValueContactList={setValueContactList}
          contactLists={contactLists}
          isLoadingContactLists={isLoadingContactLists}
          loadContactListOptions={loadContactListOptions}
        />
      </div>
    </>
  );
};

export default ContactDetailsSection; 