import petitionGet from "../../../../services/petitionGet";

const fetchCustomFields = async () => {
  const { data: result } = await petitionGet('customFields');

  result.result.forEach((element) => {
    element.label = element.name;
    element.value = element.pk;
  });

  return result.result;
};

export default fetchCustomFields;
