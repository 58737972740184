import React from "react";
import { Button, Spinner } from "reactstrap";
import { faFileExport, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ContactModalHeader = ({
  contactData,
  avatarColor,
  getInitials,
  closeModal,
  onExport,
  isExporting,
  onDelete,
  isDeleting,
  noDeleteButton,
  form,
}) => {
  return (
    <div className="itp-contact-details-modal-header mb-0">
      <div className="itp-contact-name d-flex gap-2 align-items-center">
        <div className="itp-contact-avatar" style={{ backgroundColor: avatarColor }}>
          {getInitials()}
        </div>
        <span>
          {contactData.firstname} {contactData.lastname}
        </span>
      </div>

      <div className="d-flex gap-2 mb-1">
        <Button
          color="light"
          size="sm"
          className="itp-header-button"
          onClick={() => onExport && onExport(contactData.pk)}
          disabled={isExporting}
        >
          {isExporting ? (
            <>
              <Spinner size="sm" className="me-1" /> Exporting...
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faFileExport} className="me-1" /> Export
            </>
          )}
        </Button>

        {!noDeleteButton && (
          <Button
            color="light"
            size="sm"
            className="itp-header-button delete-button"
            onClick={() => onDelete && onDelete(contactData.pk)}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <>
                <Spinner size="sm" className="me-1" /> Deleting...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faTrash} className="me-1" /> Delete
              </>
            )}
          </Button>
        )}

        <Button
          color="close"
          size="sm"
          className="itp-header-button mt-2"
          onClick={() => closeModal(form)}
        ></Button>
      </div>
    </div>
  );
}; 