import React, { useState, useEffect, useRef } from "react";
import { fetchLeadsAssignedToContact } from "../services/leadsService";
import { CustomTableMain, AddButton, ModalDelete } from "../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddEditLead from "./AddEditLead";
import getQueryString from "../../../utils/getQueryString";
import petitionPost from "../../../services/petitionPost";
import petitionPatch from "../../../services/petitionPatch";
import petitionDelete from "../../../services/petitionDelete";

/**
 * Component for displaying leads associated with a contact
 * @param {Object} props - Component props
 * @param {number} props.contactId - ID of the current contact
 * @param {Object} props.myExtension - Current user's extension information
 * @param {boolean} props.loading - Initial loading state indicator
 * @param {Function} props.setLeadsCount - Function to handle leads data
 */
export const ContactLeads = ({ contactId, loading, setLeadsCount }) => {
  const itemsPerPage = useRef('10');
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [isLoading, setIsLoading] = useState(loading || true);
  const [error, setError] = useState(null);
  const [loadingPagItems, setLoadingPagItems] = useState(false);
  const [showAddEditLeadForm, setShowAddEditLeadForm] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [errorLead, setErrorLead] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [leadDetails, setLeadDetails] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: 'Lead Name', key: 'Lead Name' },
      { name: 'Lead Source', key: 'Lead Source' },
      { name: 'Funnel', key: 'Funnel' },
      { name: 'Funnel Stage', key: 'Funnel Stage' },
      { name: 'Created Date', key: 'Created Date' },
    ],
    content: [],
    actions: {
      title: 'Actions',
      content: [],
    },
  });

  const processLeads = (leadsArray) => {
    if (!leadsArray || !Array.isArray(leadsArray)) return [];

    return leadsArray.map((lead) => ({
      pk: lead.pk,
      'Lead Name': lead.name || '',
      'Lead Source': lead.lead_source?.name || '',
      Funnel: lead.funnel_pipeline_stage?.funnel?.name || '',
      'Funnel Stage': lead.funnel_pipeline_stage?.name || '',
      'Created Date': lead.date_added,
      // Store original data for actions
      _originalData: lead,
    }));
  };

  const updateDataTable = (leads) => {
    const processedLeads = processLeads(leads);

    const columns = [
      { name: 'Lead Name', key: 'Lead Name' },
      { name: 'Lead Source', key: 'Lead Source' },
      { name: 'Funnel', key: 'Funnel' },
      { name: 'Funnel Stage', key: 'Funnel Stage' },
      { name: 'Created Date', key: 'Created Date' },
    ];

    setDataTable({
      columns: columns,
      content: processedLeads,
      actions: {
        title: 'Actions',
        content: [
          {
            name: 'Edit Lead',
            handleClick: handleAddEditLead,
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
          },
          {
            name: 'Delete Lead',
            handleClick: handleModalDelete,
            icon: <FontAwesomeIcon icon={faTrash} />,
          },
        ],
      },
    });
  };

  const handleAddEditLead = (lead) => {
    setShowAddEditLeadForm(true);

    if (lead) {
      setLeadDetails(lead);
    }
  };

  const handleModalDelete = (lead) => {
    setModalDelete(true);
    setInfo(lead);
  };

  const handleBackClick = () => {
    setShowAddEditLeadForm(false);
    setLeadDetails(null);
    setInfo(null);
  };

  const activeItemsPerPage = async (data) => {
    setLoadingPagItems(true);
    const paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    const parameter = getQueryString(paginationTemp) + `&contact_id=${contactId}`;
    await petition(parameter, true);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const changePage = async (offset) => {
    setLoadingPagItems(true);
    const paginationTemp = {
      limit: pagination.current.limit,
      offset: offset,
    };

    const parameter = getQueryString(paginationTemp) + `&contact_id=${contactId}`;

    await petition(parameter, true);
    pagination.current = paginationTemp;
  };

  const closeModal = () => {
    setModalDelete(false);
    setInfo(null);
    setLoadingButton(false);
  };

  const deleteLead = async () => {
    if (loadingButton) return;

    try {
      setLoadingButton(true);
      setErrorLead(null);

      await petitionDelete('lead', { lead_id: info.pk });

      let parameter = getQueryString(pagination.current) + `&contact_id=${contactId}`;

      if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
        const paginationTemp = {
          limit: pagination.current.limit,
          offset: pagination.current.offset - pagination.current.limit,
        };
        parameter = getQueryString(paginationTemp) + `&contact_id=${contactId}`;
        pagination.current = paginationTemp;
      }

      setLeadsCount((prevCount) => prevCount - 1);
      await petition(parameter, true);

      closeModal();
    } catch (error) {
      console.error('Error deleting lead:', error);
      setErrorLead(error?.response?.data?.error?.error_message || 'An error occurred while deleting the lead');
    }
  };

  const addLead = async (formData) => {
    try {
      setLoadingButton(true);
      setErrorLead(null);

      if (leadDetails) {
        await petitionPatch('leads', { data: formData, lead_id: leadDetails.pk });
      } else {
        await petitionPost('leads', { data: formData });
      }

      if (!leadDetails) {
        setLeadsCount((prevCount) => prevCount + 1);
      }

      setLoadingButton(false);
      setShowAddEditLeadForm(false);
      setLeadDetails(null);

      const parameter = getQueryString(pagination.current) + `&contact_id=${contactId}`;
      petition(parameter);
    } catch (error) {
      const errorMessage = error?.response?.data?.error?.error_message || 'An error occurred while saving the lead';
      setErrorLead(errorMessage);
      setLoadingButton(false);
    }
  };

  const petition = async (parameter, noLoading) => {
    if (!contactId) return;

    try {
      if (!noLoading) setIsLoading(true);
      setError(null);

      const result = await fetchLeadsAssignedToContact(parameter);
      setAllResult(result);

      const data = result.result;
      updateDataTable(data);
      setLoadingPagItems(false);
    } catch (err) {
      console.error('Error fetching leads:', err);
      setError('Failed to load leads data');
    } finally {
      setIsLoading(false);
    }
  }

  // Fetch leads data when component mounts or contactId changes
  useEffect(() => {
    const parameter = getQueryString(pagination.current) + `&contact_id=${contactId}`;
    petition(parameter);
  }, [contactId]);

  return (
    <div className={`itp-contact-leads-tab ${error ? 'p-4' : ''}`}>
      {error && <div className="alert alert-danger">{error}</div>}

      {!error && !showAddEditLeadForm && (
        <CustomTableMain
          data={dataTable}
          loading={isLoading}
          loadingPagItems={loadingPagItems}
          actionsLeft={[
            {
              component: <label>Leads assigned to this contact</label>,
            },
          ]}
          actionsRight={[
            {
              component: (
                <AddButton
                  handleClick={() => handleAddEditLead()}
                  name="Add Lead"
                  loading={isLoading}
                />
              ),
            },
          ]}
          clickRow={handleAddEditLead}
          noDataMessage="No leads found for this contact"
          module="leads"
          m0={true}
          isBackend={true}
          paginationData={parseInt(itemsPerPage.current)}
          totalPages={allResult?.total_pages}
          onPageChange={changePage}
          showSelectSingleCheckbox={true}
          onChangeItemsPerPage={activeItemsPerPage}
          offset={pagination.current.offset}
          columnConfig={{
            'Created Date': {
              type: 'date',
            },
          }}
        />
      )}

      {!error && showAddEditLeadForm && (
        <AddEditLead
          onBack={handleBackClick}
          onSave={addLead}
          loadingButton={loadingButton}
          contactId={contactId}
          error={errorLead}
          leadDetails={leadDetails}
        />
      )}

      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingButton}
          modalFunction={deleteLead}
          title="Delete Lead"
          error={errorLead}
        />
      )}
    </div>
  );
}; 