import petitionGet from "../../../../../../../services/petitionGet";

const fetchVoicemailSettings = async (setSendvoicemail, setDeleteVoicemail, setCompletedRequests) => {
  try {
    const { data: result } = await petitionGet("voicemailUserSetings")
    const sendVM = result?.result?.sendvoicemail === "yes" ? "yes" : "no";
    const deleteVM = result?.result?.deletevoicemail === "yes" ? "yes" : "no";

    setSendvoicemail({ sendvoicemail: sendVM });
    setDeleteVoicemail({ deletevoicemail: deleteVM });
    setCompletedRequests((prevStata) => prevStata + 1);
  } catch (error) {
    console.log(error)
  }
};

export default fetchVoicemailSettings;