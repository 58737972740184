import petitionGet from "../../../../../../services/petitionGet";

const fetchAreaCodes = async (parameter, cancelToken) => {
  const { data: result } = await petitionGet("areaCodes", { parameter }, cancelToken);
  result.result.forEach((element) => {
    element.npa_id = element?.area?.npa_id;
    element.additionalNpaIds = element?.area?.overlays.join(", ");
  });
  
  return result;
};

export default fetchAreaCodes;

