import { convertTimeStapToTimeZoneOnFormated } from "../../../../../utils/getDate";
import { capitalizeWords } from "../../../../../utils/capitalizeWords";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import contacts from "../../../../../assets/icons/contacts.svg";
import formatCallTranscription from "../../../../../utils/formatCallTranscription";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const findUser = (call, key, keyUserObject, columnName) => {
  const id = call[key]
  const { forwarded_original_caller_id, forwarded_to_number } = call
      
  if (id) {
    const img = key === "to_user_id" && forwarded_to_number ? <ArrowOutwardIcon style={{ marginLeft: "-1.8rem" }} /> : ""
    call.downTextKey = key === "to_user_id" && forwarded_to_number ? `Call was forwarded to ${forwarded_to_number}` : false;
    const name = <>{img} {`${call[keyUserObject]?.firstname || ""} ${call[keyUserObject]?.lastname || ""}`}</>
    return name
  } else if (Object.keys(call?.contact).length) {
    call[columnName] = true
    return <><img style={{ marginLeft: "-1.8rem", marginTop: "-3px" }} className="me-1" src={contacts} alt="contact" />{" "}{call?.contact?.firstname || ""} {call?.contact?.lastname || ""}</>
  } else {

    const forwardedFromCall = key === "from_user_id" && forwarded_original_caller_id ? <>{formatPhoneNumber(forwarded_original_caller_id)}</> : ""
    const forwardedToCall = key === "to_user_id" && forwarded_to_number ? <><ArrowOutwardIcon style={{ marginLeft: "-1.8rem" }} />{" "} {formatPhoneNumber(forwarded_to_number)}</> : ""

    const existForwardedCall = forwardedFromCall ? forwardedFromCall : forwardedToCall ? forwardedToCall : ""

    const number = existForwardedCall ? existForwardedCall : call.direction === "inbound" ? formatPhoneNumber(call.src) : call.direction	=== "outbound" ? formatPhoneNumber(call.dst) : ""
    return number
  }
}

const findExtensionNumber = (call, key, keyUserObject) => {
  const { forwarded_original_caller_id, forwarded_to_number } = call
  const forwardedFromCall = key === "from_user_id" && forwarded_original_caller_id
  const forwardedToCall = key === "to_user_id" && forwarded_to_number

  const id = call[key]
  
if (id) {
    const extensionNumber = call[keyUserObject]?.presence_id || ""
    return extensionNumber
  } else if (Object.keys(call?.contact).length) {
    return formatPhoneNumber(call?.contact?.phone)
  } else if (forwardedFromCall || forwardedToCall) {
    return "Call was forwarded";
  } else {
    return null
  }
}

const processListCallHistory = (call, myExtension) => {
  const timeZone = myExtension?.time_zone

  call.datetime = convertTimeStapToTimeZoneOnFormated(call.calldate, timeZone);
  call.formatedDirection = capitalizeWords(call?.direction)

  call.fromNumber = findUser(call, "from_user_id", "from_user", "From");
  call.fromNumberHover = findExtensionNumber(call, "from_user_id", "from_user", "To")

  call.toNumber = findUser(call, "to_user_id", "to_user", "To");
  call.toNumberHover = findExtensionNumber(call, "to_user_id", "to_user")

  call.call_transcription = formatCallTranscription(call)

  call.isForwardedFrom = !call.from_user_id && !Object.keys(call?.contact).length && call.forwarded_original_caller_id
  call.isForwardedTo = !call.to_user_id && !Object.keys(call?.contact).length && call.forwarded_to_number

  if (call.disposition === "ANSWERED") {
    call.style = {
      background: "#F6F6F9 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      font: "normal normal normal 12px/16px Instrument Sans",
      letterSpacing: "0px",
      color: "#199F28",
      opacity: "1",
    }
  } else if (call.disposition === "FAILED") {
      call.style = {
        background: "rgba(255, 0, 0, 0.2) 0% 0% no-repeat padding-box",
        borderRadius: "12px",
        font: "normal normal normal 12px/16px Instrument Sans",
        letterSpacing: "0px",
        color: "#FF0000",
        opacity: "1",
      }
  } else {
    call.style = {
      background: "#1F3D571E 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      font: "normal normal normal 12px/16px Instrument Sans",
      letterSpacing: "0px",
      color: "#3A3A3ACC",
      opacity: "1",
    }
  }
}

export default processListCallHistory;