import React from "react";
import { Notes, ContactHistory } from "../../index";
import { ContactLeads } from "./ContactLeads";

/**
 * Component for displaying the main content area of contact details
 * @param {Object} props - Component props
 * @param {string} props.activeTab - Currently active tab
 * @param {Object} props.contactData - Contact information
 * @param {boolean} props.finishFetchCallHistory - Whether call history fetch is complete
 * @param {boolean} props.activityLoaded - Whether activity data is loaded
 * @param {Object} props.myExtension - Current user's extension information
 * @param {Function} props.handleActivityData - Function to handle activity data
 * @param {boolean} props.shouldLoadActivity - Whether activity should be loaded
 * @param {Function} props.setFinishFetchCallHistory - Function to set call history fetch status
 * @param {boolean} props.finishFetchNotes - Whether notes fetch is complete
 * @param {boolean} props.notesLoaded - Whether notes are loaded
 * @param {Function} props.handleNotesData - Function to handle notes data
 * @param {boolean} props.shouldLoadNotes - Whether notes should be loaded
 * @param {Function} props.setFinishFetchNotes - Function to set notes fetch status
 * @param {boolean} props.leads - Whether leads tab is enabled
 * @param {Array} props.leadsData - Leads data for the contact
 * @param {boolean} props.isLoadingLeads - Whether leads are currently loading
 * @param {boolean} props.leadsLoaded - Whether leads have been loaded
 * @param {boolean} props.shouldLoadLeads - Whether leads should be loaded
 */
export const ContactMainContent = ({
  activeTab,
  contactData,
  finishFetchCallHistory,
  activityLoaded,
  myExtension,
  handleActivityData,
  shouldLoadActivity,
  setFinishFetchCallHistory,
  finishFetchNotes,
  notesLoaded,
  handleNotesData,
  shouldLoadNotes,
  setFinishFetchNotes,
  leads = false,
  isLoadingLeads = false,
  setLeadsCount,
}) => {
  return (
    <div className="itp-contact-main">
      {activeTab === 'activity' && (
        <div className="itp-contact-activity-tab">
          <ContactHistory
            contactId={contactData.pk}
            setFinishFetchCallHistory={setFinishFetchCallHistory}
            loading={!finishFetchCallHistory && !activityLoaded}
            myExtension={myExtension || { firstname: 'User', lastname: 'Name' }}
            onDataReceived={handleActivityData}
            shouldFetch={shouldLoadActivity}
          />
        </div>
      )}

      {activeTab === 'notes' && (
        <div className="itp-contact-notes-tab">
          <Notes
            contactId={contactData.pk}
            setFinishFetchNotes={setFinishFetchNotes}
            loading={!finishFetchNotes && !notesLoaded}
            myExtension={myExtension}
            onDataReceived={handleNotesData}
            shouldFetch={shouldLoadNotes}
          />
        </div>
      )}

      {leads && activeTab === 'leads' && (
        <ContactLeads
          contactId={contactData.pk}
          loading={isLoadingLeads}
          setLeadsCount={setLeadsCount}
        />
      )}
    </div>
  );
}; 