import petitionPost from "../../../../../services/petitionPost";
import { formatData, formatTime, formatTimeForReport } from "../../utils";

const userReportService = async (selectedUsers, timeframe, timeZone, setTotalCalls, setXLabel, setYLabel) => {
  const data = { users: selectedUsers.map((user) => user.pk) };
  const timeStart = timeframe.timeStart;
  const timeEnd = timeframe.timeEnd;
  const hourStart = formatTimeForReport(timeframe.hourStart, false);
  const hourEnd = formatTimeForReport(timeframe.hourEnd, true);

  // Extraer horas, minutos y segundos del hourStart
  const [hoursStart, minutesStart, secondsStart] = hourStart.split(":").map(Number);
  
  // Extraer horas, minutos y segundos del hourEnd
  const [hoursEnd, minutesEnd, secondsEnd] = hourEnd.split(":").map(Number);

  const newTimeStart = new Date(timeStart);
  newTimeStart.setHours(hoursStart, minutesStart, secondsStart);
  
  const newTimeEnd = new Date(
    parseInt(timeEnd.split("-")[0]),
    parseInt(timeEnd.split("-")[1]) - 1,
    parseInt(timeEnd.split("-")[2])
  );
  newTimeEnd.setHours(hoursEnd, minutesEnd, secondsEnd);

  const parameter = `?start_date=${Math.floor(newTimeStart.getTime() / 1000)}&end_date=${Math.floor(newTimeEnd.getTime() / 1000)}`;

  const { data: res } = await petitionPost("reports", { data, parameter })

  const newArrayRecords = res.result.user_reports.map((element) => {
    const findUser = selectedUsers.find((elementTwo) => elementTwo.pk === element.user);

    if (findUser) {
      element.user = `${findUser.firstname} ${findUser?.lastname || ""}`;
      element.duration = formatTime(element.duration);
    }

    return element;
  });

  const formattedReport = formatData(res.result.per_hour_report, timeZone);

  let ObjectCalls = [];
  let objectHours = Object.keys(formattedReport);

  Object.keys(formattedReport).forEach((element) => {
    ObjectCalls.push(formattedReport[element]);
  });

  setTotalCalls(res.result.summary.total_calls);
  setXLabel(objectHours);
  setYLabel(ObjectCalls);

  return newArrayRecords
}

export default userReportService;