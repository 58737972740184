import petitionGet from "../../../services/petitionGet";

/**
 * Fetches leads assigned to a contact
 * @param {number} contactId - The contact ID to fetch leads for
 * @returns {Promise<Object>} - Promise resolving to lead data
 */
export const fetchLeadsAssignedToContact = async (parameter) => {
  try {
    const { data: result } = await petitionGet('leads', { parameter });
    return result;
  } catch (error) {
    console.error('Error fetching leads for contact:', error);
    return [];
  }
}; 