import petitionPatch from "../../../../../services/petitionPatch";

const assignedUserService = async (parameter, setLoading, selectAll, user, arrayContacts, reloadData) => {
  try {
    setLoading(true);

    let data = {};

    if (selectAll) {
      data = {
        payload: {
          owner_id: user.pk,
        },
        use_filters: true,
      };
    } else {
      data = {
        contacts: arrayContacts,
        payload: {
          owner_id: user.pk,
        },
      };
    }

    await petitionPatch('updateMultipleContacts', { data, parameter });

    setLoading(false);
    reloadData(false, false);
  } catch (error) {
    console.log(error);
  }
};   

export default assignedUserService;
