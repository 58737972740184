/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { TextAreaVarSelect } from "../../../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";
import petitionGet from "../../../../../../../services/petitionGet";
import ModalError from "../../../../ModalUtility/ModalError";

const SendEmail = ({ saveModule, edit, data, editModule }) => {
  const [loading, setLoading] = useState(true)
  const [contactVariables, setContactVariables] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [messageError, setMessageError] = useState("")
  const [form, setForm] = useState({ 
    to_emails: "",
    body: "",
    body_contact: "",
    subject: "",
    send_contact_email: false,
  })

  const handleTabClick = (number) => {
    setActiveTab(number)
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const handleChangeSwitch = () => {
    setForm({ ...form, send_contact_email: !form.send_contact_email })
  }

  const handleChangeForm = (name, value) => {
    setForm({ ...form, [name]: value })
  }

  const saveSendEmail = () => {
    const emailList = form.to_emails.split("\n").map((email) => email.trim()).filter((email) => email !== "");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emailList.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length) {
      let error = `This is a invalid emails, please correct or delete them\n`
      invalidEmails.forEach((email) => {
        error += `${email}\n`
      })

      setOpenModal(true)
      setMessageError(error)
      return;
    }

    const formTemp = { ...form, to_emails: emailList }
    if (edit) {
      editModule(formTemp, "send_email");
    } else {
      saveModule(formTemp, "send_email"); 
    }
  }

  const petition = async () => {
    try {  
      if (edit) {
        const toEmailString = data.data.to_emails.join("\n")
        setForm({ ...data.data, to_emails: toEmailString })
      }
  
      const { data: result } = await petitionGet("customFields")
  
      result.result.forEach((element) => {
        element.label = element.name;
        element.value = `{{contact.${element.name}}}`;
      });
  
      setContactVariables([
        {
          title: 'Variables',
          options: [
            {
              title: 'Contact',
              options: [
                {
                  label: 'First Name',
                  value: '{{contact.firstname}}',
                },
                {
                  label: 'Last Name',
                  value: '{{contact.lastname}}',
                },
                { label: 'Phone', value: '{{contact.phone}}' },
                { label: 'Email', value: '{{contact.email}}' },
                ...result.result,
              ],
            },
            {
              title: 'Call',
              options: [
                { label: 'Caller', value: '{{caller.caller_id_number}}' },
                { label: 'Dialed Number', value: '{{caller.dnis}}' },
              ],
            },
          ],
          dropdownOpen: false,
        },
      ]);
  
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    if (data) petition()
  }, [data]);

  return (
    <> 
      {openModal && (
        <ModalError 
          modal={openModal}
          closeModal={() => setOpenModal(false)}
          pre={messageError}
        />
      )}

      <div className="d-flex justify-content-between align-items-center mt-5">
        <div className="d-flex gap-2 align-items-center">
          <label>Send Copy to Contact</label>
          <div className="postion-relative send-email-help">
            <div className="itp-header-click-icon-hover send-email">
              This will send a copy of the email to the contact
              if a contact is found and has an 
              associated email address
            </div>
            <FontAwesomeIcon icon={faQuestion} />
          </div>
        </div>
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={handleChangeSwitch}
          checked={form.send_contact_email}
        />
      </div>

      <div className="itp-tab-menu mt-4">
        <div className="itp-tab-menu__tabs-container">
          <div className="itp-tab-menu__tabs">
            <div className={`itp-tab-menu__tab ${activeTab === 0 ? "active" : ""}`} onClick={() => handleTabClick(0)}>
              With Contact
            </div>
            <div className={`itp-tab-menu__tab ${activeTab === 1 ? "active" : ""}`} onClick={() => handleTabClick(1)}>
              Without Contact
            </div>
           </div>
        </div>
        <div className="itp-tab-menu__content">
          {activeTab === 0 && (
            <>
              <label className="itp-label-input__label mt-4">Message to send</label>
              <TextAreaVarSelect
                onChangeTextArea={(value) => handleChangeForm("body_contact", value)}
                textAreaDefaultValue={form.body_contact}
                loading={loading}
                options={contactVariables}
              />
            </>
          )}
        
          {activeTab === 1 && (
            <>
              <label className="itp-label-input__label mt-4">Message to send</label>
              <TextAreaVarSelect
                onChangeTextArea={(value) => handleChangeForm("body", value)}
                textAreaDefaultValue={form.body}
                loading={loading}
                options={[{ title: "Variables", options: [{ title: "Call", options: [{ label: "Caller", value: "{{caller.caller_id_number}}" }, { label: "Dialed Number", value: "{{caller.dnis}}" }] }] }]}
              />
            </>
          )}
        </div>
      </div>

      <label className="itp-label-input__label mt-4">Email Subject</label>
      <input
        className="form-control-itp"
        value={form.subject}
        name="subject"
        onChange={(e) => handleChangeForm(e.target.name, e.target.value)}
        type="text"
      />

      <label className="itp-label-input__label mt-4">Enter List of Emails separated by a new line:</label>
      <textarea
        onChange={(e) => handleChangeForm(e.target.name, e.target.value)}
        value={form.to_emails}
        name="to_emails"
        className="form-control-itp w-30 bgc-transparent"
        placeholder="Example:&#10;email1@gmail.com&#10;email2@gmail.com&#10;email3@gmail.com"
        id="to_emails"
        cols="30"
        rows="10"
      />

      <button 
        onClick={saveSendEmail} 
        className="btn-primary mt-4"
        disabled={!form.subject || !form.to_emails || !form.body || !form.body_contact}
      >
        Save
      </button>
    </>
  )
}

export default SendEmail;