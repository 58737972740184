import { useEffect, useState, useCallback, useRef, memo } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectComponent } from '../index';
import LoadingButton from '../LoadingButton/LoadingButton';
import petitionPost from '../../services/petitionPost';
import petitionDelete from '../../services/petitionDelete';
import petitionGet from '../../services/petitionGet';
import ModalError from './ModalError';

// Función de utilidad para comparación profunda de objetos
const isEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (!obj1 || !obj2) return false;

  // Comparar valores clave específicos que nos interesan
  const keysToCompare = ['value', 'label', 'pk'];
  for (const key of keysToCompare) {
    if (obj1[key] !== obj2[key]) return false;
  }

  return true;
};

const LeadSource = ({ defaultLeadSource, onChange, isEditing = true }) => {
  // Referencia para evitar actualizaciones de estado con el mismo valor
  const valueLeadSourceRef = useRef(null);
  const [valueLeadSource, setValueLeadSourceState] = useState(null);

  // Wrapper alrededor del setter de estado para evitar duplicados
  const setValueLeadSource = useCallback((newValue) => {
    if (!isEqual(valueLeadSourceRef.current, newValue)) {
      valueLeadSourceRef.current = newValue;
      setValueLeadSourceState(newValue);
    }
  }, []);

  const [showAddLeadSource, setShowAddLeadSource] = useState(false);
  const [showdeleteLeadSource, setShowdeleteLeadSource] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [newLeadSource, setNewLeadSource] = useState('');
  const [infoToDelete, setInfoToDelete] = useState(null);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');

  const closeModal = () => {
    setModal(false);
    setMessage('');
  };

  const addLeadSource = async () => {
    if (loadingButton || newLeadSource === '') return;

    try {
      setLoadingButton(true);
      const { data: result } = await petitionPost('leadSource', { data: { name: newLeadSource } });

      setShowAddLeadSource(false);
      setShowdeleteLeadSource(false);
      setLoadingButton(false);
      onChange({ value: null })
      setValueLeadSource(null);
    } catch (error) {
      console.log(error);

      if (error?.response?.status === 400) {
        const messageError = error?.response?.data?.error?.error_message;
        const message = 'User is trying to create a lead source with the same name';
        if (messageError === message) {
          setModal(true);
          setMessage(message);
        }
      }

      setLoadingButton(false);
    }
  };

  const deleteLeadSource = () => {
    setLoadingButton(true);
    petitionDelete('leadSource', {
      source_lead_id: infoToDelete?.pk,
    })
      .then(() => {
        setLoadingButton(false);
        back();
        setValueLeadSource(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOptionsSource = useCallback((inputValue, callback) => {
    petitionGet('leadSource', {
      parameter: inputValue ? `?name=${inputValue}` : '?offset=0&limit=10',
    })
      .then(({ data: result }) => {
        const leadSource = result.result;

        leadSource.forEach((element) => {
          if (element.value !== 'add') {
            if (element.list_name !== 'Default') {
              element.icon = <FontAwesomeIcon icon={faTrash} />;
            }
          }

          element.label = element.name;
          element.value = element.pk;
        });

        let existAdd = leadSource.some((objeto) => objeto.value === 'add');

        if (!existAdd) {
          leadSource.unshift({
            label: 'Add New Lead Source',
            value: 'add',
          });
        }

        callback(leadSource);
      })
      .catch((error) => console.log(error));
  }, []);

  const stopFunction = useCallback((e, info) => {
    // Detener la propagación del evento
    e.stopPropagation();
    e.preventDefault();

    setShowdeleteLeadSource(true);
    setInfoToDelete(info);
  }, []);

  const back = useCallback(() => {
    setShowAddLeadSource(false);
    setShowdeleteLeadSource(false);
    setInfoToDelete(null);
  }, []);

  const handleChangeLeadSource = useCallback(
    (e) => {
      if (!e) return;

      if (e.value === 'add') {
        setShowAddLeadSource(true);
      } else {
        // Crear una copia estable del objeto para evitar re-renders
        const stableValue = {
          value: e.value,
          label: e.label,
          pk: e.pk || e.value,
        };

        onChange(stableValue);
        setValueLeadSource(stableValue);
      }
    },
    [onChange, setValueLeadSource]
  );

  // Establecer el valor inicial solo una vez cuando cambia defaultLeadSource
  useEffect(() => {
    if (defaultLeadSource && defaultLeadSource.label && defaultLeadSource.value) {
      const stableDefaultValue = {
        value: defaultLeadSource.value,
        label: defaultLeadSource.label,
        pk: defaultLeadSource.pk || defaultLeadSource.value,
      };

      setValueLeadSource(stableDefaultValue);
    }
  }, [defaultLeadSource, setValueLeadSource]);

  // Read-only display when not in editing mode
  if (!isEditing) {
    return (
      <FormGroup>
        <div className="itp-field-label">Lead Source</div>
        <Input type="text" disabled value={defaultLeadSource?.label || ''} />
      </FormGroup>
    );
  }

  return (
    <>
      {modal && <ModalError modal={modal} closeModal={closeModal} message={message} />}

      {!showAddLeadSource && !showdeleteLeadSource && (
        <FormGroup>
          <div className="itp-field-label">Lead Source</div>
          <SelectComponent
            onChange={handleChangeLeadSource}
            defaultValue={valueLeadSource}
            isSearchable={true}
            name="lead_source_id"
            placeholder="Choose lead source"
            includeOption={true}
            functionOption={stopFunction}
            asyncSelectOptions={true}
            loadOptions={loadOptionsSource}
            className="basic-single"
            classNamePrefix="select"
          />
        </FormGroup>
      )}

      {showAddLeadSource && !showdeleteLeadSource && (
        <>
          <FormGroup>
            <div className="itp-field-label">Lead Source Name</div>
            <input
              onChange={(e) => setNewLeadSource(e.target.value)}
              name="lead_source"
              className="form-control"
              type="text"
              placeholder="Enter source name"
            />
          </FormGroup>
          <div className="d-flex gap-2 mb-3">
            <button disabled={loadingButton} className="btn-light" onClick={back}>
              Cancel
            </button>

            <button
              disabled={loadingButton}
              className="btn-primary loading"
              onClick={addLeadSource}
            >
              {loadingButton && <LoadingButton />}
              Add
            </button>
          </div>
        </>
      )}

      {!showAddLeadSource && showdeleteLeadSource && (
        <div className="d-flex flex-column justify-content-center gap-2 mt-3 mb-3">
          <div className="itp-field-label">Are you Sure?</div>
          <div className="d-flex gap-2">
            <button disabled={loadingButton} className="btn-light" onClick={back}>
              Cancel
            </button>
            <button
              disabled={loadingButton}
              className="btn-primary loading"
              onClick={deleteLeadSource}
            >
              {loadingButton && <LoadingButton />}
              Delete
            </button>
          </div>
        </div>
      )}
    </>
  );
};

// Utilizar memo para evitar re-renders innecesarios del componente
export default memo(LeadSource, (prevProps, nextProps) => {
  // Solo re-renderizar si isEditing cambia o defaultLeadSource cambia significativamente
  if (prevProps.isEditing !== nextProps.isEditing) return false;

  // Comparar defaultLeadSource solo por las propiedades que nos importan
  const prevSource = prevProps.defaultLeadSource;
  const nextSource = nextProps.defaultLeadSource;

  // Si ambos son null/undefined o exactamente el mismo objeto
  if (prevSource === nextSource) return true;

  // Si uno es null y el otro no
  if (!prevSource || !nextSource) return false;

  // Comparar propiedades importantes
  return prevSource.value === nextSource.value && prevSource.label === nextSource.label;
});
