import React from "react";
import { CharacterCounter, ResizingTextarea } from "../../../../../components/index";

const Step2 = ({ form, setForm }) => {
  return (
    <>
      <h3>Sample Messages</h3>
      <br />
      <label className="itp-label-input__label">
        Please provide three (3) sample messages that may be sent to a contact 
        (minimum of 20 characters and a maximum of 500 each).
      </label>
      <br />

      <label className="itp-label-input__label">Message #1</label>
      <ResizingTextarea 
        className="form-control-itp"
        value={form?.sample1}
        handleChange={(e) => setForm({ ...form, sample1: e.target.value })}
      />
      <CharacterCounter text={form.sample1} charMin={20} charMax={500} />
      <br />

      <label className="itp-label-input__label">Message #2</label>
      <ResizingTextarea 
        className="form-control-itp"
        value={form?.sample2}
        handleChange={(e) => setForm({ ...form, sample2: e.target.value })}
      />
      <CharacterCounter text={form.sample2} charMin={20} charMax={500} />
      <br />

      <label className="itp-label-input__label">Message #3</label>
      <ResizingTextarea 
        className="form-control-itp"
        value={form?.sample3}
        handleChange={(e) => setForm({ ...form, sample3: e.target.value })}
      />
      <CharacterCounter text={form.sample3} charMin={20} charMax={500} />
      <br />
    </>
  );
};

export default Step2;
