import React from "react";
import { FormGroup, Input } from "reactstrap";
import AsyncSelect from "react-select/async";

const ContactListSection = ({
  isEditing,
  valueContactList,
  setValueContactList,
  contactLists,
  isLoadingContactLists,
  loadContactListOptions,
}) => {
  return (
    <FormGroup>
      <div className="itp-field-label">Contact List</div>
      {isEditing ? (
        <AsyncSelect
          onChange={setValueContactList}
          className="basic-single"
          classNamePrefix="select"
          value={valueContactList}
          isSearchable={true}
          name="contact_list_id"
          loadOptions={loadContactListOptions}
          defaultOptions={contactLists}
          cacheOptions
          isLoading={isLoadingContactLists}
          placeholder="Select a Contact List"
          onFocus={() => {
            if (contactLists.length === 0) {
              loadContactListOptions("", () => {});
            }
          }}
        />
      ) : (
        <Input type="text" disabled value={valueContactList?.label || ""} />
      )}
    </FormGroup>
  );
};

export default ContactListSection; 