import { fetchVmGreeting } from "../index";
import petitionPut from "../../../../../../../services/petitionPut";

const sendVmGreetingService = async (file, setLoadingButton, closeMenu, setvmGreeeting) => {
  try {
    setLoadingButton(true)

    const formData = new FormData();
    formData.append("file", file);

    await petitionPut("vmGreeting", { data: formData })

    const newVoicemailGreeting = await fetchVmGreeting()
    setvmGreeeting(newVoicemailGreeting)

    closeMenu()
  } catch (error) {
    console.log(error)

    setLoadingButton(false)
  }
};

export default sendVmGreetingService;