import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LoadingButton, SearchBoxFilter } from "../../../../../../components";
import petitionGet from "../../../../../../services/petitionGet";

const ModalAddAreaCode = ({ modal, closeModal, modalFunction, loadingButton }) => {
  const [areaCode, setAreaCode] = useState("");
  const [areaCodeResult, setAreaCodeResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeAreaCode = (e) => {
    const newValue = e.target.value;

    if (/^\d{0,3}$/.test(newValue)) {
      setAreaCode(newValue);
    }
  }

  const handleClickSearchAreaCode = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const parameter = `?npa_id=${areaCode}`;
      const { data: result } = await petitionGet("npaList", { parameter });

      if (result.result && result.result[0]) {
        result.result[0].aditionalAreaCodes = result.result[0]?.overlays.join(", ");
        setAreaCodeResult(result.result[0]);
      }

      console.log(result.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={modal} toggle={closeModal}>
      <ModalHeader>Add Area Code</ModalHeader>
      <ModalBody>
        <div className="d-flex gap-2">
          <div className="d-flex flex-column justify-content-center">
            <label className="itp-label-input__label">Enter Area Code</label>
            <SearchBoxFilter
              placeholder="Enter Area Code"
              className="w-100"
              handleChange={handleChangeAreaCode}
              value={areaCode}
              noSearchIcon={true}
            />
          </div>
          <button
            disabled={!areaCode}
            className="btn-primary mt-3 loading"
            onClick={handleClickSearchAreaCode}
          >
            {loading && <LoadingButton />}
            Search
          </button>
        </div>

        {areaCodeResult && (
          <div className="mt-4 d-flex flex-column gap-2">
            <label className="itp-label-input__label">
              Detected Primary Area Code: {areaCodeResult?.npa_id}
            </label>
            <label className="itp-label-input__label">
              Additional Area Codes: {areaCodeResult?.aditionalAreaCodes}
            </label>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          disabled={loadingButton}
          className="btn-light"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          disabled={!areaCodeResult}
          className="btn-primary loading"
          onClick={() => modalFunction(areaCodeResult)}
        >
          {loadingButton && <LoadingButton />}
          Confirm & Add
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAddAreaCode;
