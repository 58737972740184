export const options = [
  { label: "Contact Name", value: "Contact Name" },
  { label: "Contact Email", value: "Contact Email" },
  { label: "Lead Name", value: "Lead Name" },
  { label: "Lead Source", value: "Lead Source" },
  { label: "Funnel", value: "Funnel" },
  { label: "Funnel Stage", value: "Funnel Stage" },
  { label: "Lead Created Date", value: "Lead Created Date" },
  { label: "Assigned User", value: "Assigned User" },
];
