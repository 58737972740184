const CallSummary = ({ callSummaryData }) => (
  <div className="itp-call-transcription__call-summary">
    <h6 className="itp-call-transcription__call-summary-title">Call Summary</h6>
    <p className="itp-call-transcription__call-summary-description">
      {callSummaryData?.callSummary}
    </p>
  </div>
);

export default CallSummary;
