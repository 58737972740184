import { useState, useEffect } from "react";
import { fetchNotes } from "../../Notes/services";
import { fetchCallHistory } from "../../ContactHistory/services";
import getQueryString from "../../../utils/getQueryString";

export const useTabsData = ({
  contactData,
  activeTab,
  myExtension,
}) => {
  const [notesCount, setNotesCount] = useState(0);
  const [activityCount, setActivityCount] = useState(0);
  const [finishFetchNotes, setFinishFetchNotes] = useState(false);
  const [finishFetchCallHistory, setFinishFetchCallHistory] = useState(false);
  const [notesLoaded, setNotesLoaded] = useState(false);
  const [activityLoaded, setActivityLoaded] = useState(false);

  // Determine if we should load the Notes component
  const shouldLoadNotes = activeTab === "notes";

  // Determine if we should load the ContactHistory component
  const shouldLoadActivity = activeTab === "activity";

  // Handler for receiving notes count from Notes component
  const handleNotesData = (result) => {
    if (result && result.result && Array.isArray(result.result)) {
      setNotesCount(result.result.length);
    }
    setNotesLoaded(true);
  };

  // Handler for receiving activity count from ContactHistory component
  const handleActivityData = (result) => {
    if (result && typeof result.item_count !== "undefined") {
      setActivityCount(result.item_count);
    }
    setActivityLoaded(true);
  };

  // Function to load notes data
  const loadNotesData = async () => {
    try {
      if (!contactData.pk) return;

      // Create temporary state setters that don't do anything
      // since we only need the result data
      const tempSetNotes = () => {};
      const tempSetEditNote = () => {};

      const result = await fetchNotes(contactData.pk, tempSetNotes, tempSetEditNote);

      // Use result.result.length to get the count of notes
      if (result && result.result && Array.isArray(result.result)) {
        setNotesCount(result.result.length);
      }

      setNotesLoaded(true);
      setFinishFetchNotes(true);
    } catch (error) {
      console.error("Error loading notes data:", error);
    }
  };

  // Function to load activity data
  const loadActivityData = async () => {
    try {
      if (!contactData.pk) return;

      const query = getQueryString({ offset: 0, limit: 5 });
      const { result } = await fetchCallHistory(
        contactData.pk,
        myExtension || { firstname: "User", lastname: "Name" },
        query
      );

      if (result && typeof result.item_count !== "undefined") {
        setActivityCount(result.item_count);
      }

      setActivityLoaded(true);
      setFinishFetchCallHistory(true);
    } catch (error) {
      console.error("Error loading activity data:", error);
    }
  };

  // Load data when contact changes
  useEffect(() => {
    if (contactData.pk) {
      loadNotesData();
      loadActivityData();
    }
  }, [contactData.pk]);

  // Effect to handle tab changes and load data accordingly
  useEffect(() => {
    if (activeTab === "notes" && !notesLoaded) {
      loadNotesData();
    } 
    if (activeTab === "activity" && !activityLoaded) {
      loadActivityData();
    }
  }, [activeTab, notesLoaded, activityLoaded]);

  return {
    notesCount,
    activityCount,
    notesLoaded,
    activityLoaded,
    finishFetchNotes,
    finishFetchCallHistory,
    handleNotesData,
    handleActivityData,
    shouldLoadNotes,
    shouldLoadActivity,
    setFinishFetchNotes,
    setFinishFetchCallHistory,
  };
}; 