import petitionPost from "../../../../../services/petitionPost";
import { formatTimeForReport } from "../../utils";

const queueMemberReportService = async (selectedQueueMembersReport, selectedQueuesReport, timeframe, setTotalCalls) => {
  const queues = selectedQueuesReport.map((queue) => queue.pk.toString())
  const data = { agents: selectedQueueMembersReport.map((element) => `${element.pk}`), queues};
  
  const timeStart = timeframe.timeStart;
  const timeEnd = timeframe.timeEnd;
  const hourStart = formatTimeForReport(timeframe.hourStart, false);
  const hourEnd = formatTimeForReport(timeframe.hourEnd, true);

  // Formatear las fechas en el formato requerido: YYYY-MM-DD HH:MM:SS
  const formattedStartDate = `${timeStart} ${hourStart}`;
  const formattedEndDate = `${timeEnd} ${hourEnd}`;

  const parameter = `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

  const { data: result } = await petitionPost("queueMemberReport", { data, parameter })

  let countingTotalCalls = 0;
  result.result.forEach((element) => {
    element.userName = `${element?.user?.firstname} ${element?.user?.lastname || ""}`;
    countingTotalCalls = countingTotalCalls + element.total_calls;
  });

  setTotalCalls(countingTotalCalls);

  return result.result
};

export default queueMemberReportService;
