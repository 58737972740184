import React from "react";
import { FormGroup, Input } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";

/**
 * Section component for displaying basic contact information fields
 * @param {boolean} isEditing - Whether the form is in edit mode
 * @param {object} form - Form data object for edited values
 * @param {object} contactData - Original contact data object
 * @param {function} handleInputChange - Function to handle input changes
 * @param {boolean} phoneOnly - When true, renders only the phone field
 */
const BasicInfoSection = ({ 
  isEditing, 
  form, 
  contactData, 
  handleInputChange,
  phoneOnly = false
}) => {
  // Render only the phone field when phoneOnly is true
  if (phoneOnly) {
    return (
      <FormGroup className="mb-0">
        {false && <div className="itp-field-label">Phone</div>}
        {isEditing ? (
          <PhoneInput
            country={"us"}
            value={form.phone || ""}
            onChange={(phone) => {
              handleInputChange({
                target: {
                  name: "phone",
                  value: phone,
                },
              });
            }}
            inputClass="itp-phone-input"
            containerClass="itp-phone-container"
            buttonClass="itp-phone-dropdown-button"
            dropdownClass="itp-phone-dropdown"
            enableSearch={true}
            disableSearchIcon={false}
            searchPlaceholder="Search country..."
            preferredCountries={["us", "ca", "mx"]}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: false,
            }}
          />
        ) : (
          <Input
            type="text"
            disabled
            value={formatPhoneNumber(contactData.phone) || ""}
            onChange={() => {}}
            className="pe-4"
          />
        )}
      </FormGroup>
    );
  }

  // Otherwise render all basic info fields
  return (
    <>
      <FormGroup>
        <div className="itp-field-label">First Name</div>
        <Input
          type="text"
          name="firstname"
          value={isEditing ? form.firstname || "" : contactData.firstname || ""}
          onChange={handleInputChange}
          disabled={!isEditing}
        />
      </FormGroup>
      <FormGroup>
        <div className="itp-field-label">Last Name</div>
        <Input
          type="text"
          name="lastname"
          value={isEditing ? form.lastname || "" : contactData.lastname || ""}
          onChange={handleInputChange}
          disabled={!isEditing}
        />
      </FormGroup>
      <FormGroup>
        <div className="itp-field-label">Email</div>
        <Input
          type={isEditing ? "email" : "text"}
          name="email"
          value={isEditing ? form.email || "" : contactData.email || ""}
          onChange={handleInputChange}
          disabled={!isEditing}
        />
      </FormGroup>
      <FormGroup>
        <div className="itp-field-label">Phone</div>
        {isEditing ? (
          <PhoneInput
            country={"us"}
            value={form.phone || ""}
            onChange={(phone) => {
              handleInputChange({
                target: {
                  name: "phone",
                  value: phone,
                },
              });
            }}
            inputClass="itp-phone-input"
            containerClass="itp-phone-container"
            buttonClass="itp-phone-dropdown-button"
            dropdownClass="itp-phone-dropdown"
            enableSearch={true}
            disableSearchIcon={false}
            searchPlaceholder="Search country..."
            preferredCountries={["us", "ca", "mx"]}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: false,
            }}
          />
        ) : (
          <Input
            type="text"
            disabled
            value={formatPhoneNumber(contactData.phone) || ""}
            onChange={() => {}}
          />
        )}
      </FormGroup>
      <FormGroup check>
        <Input
          type="checkbox"
          name="dnd_sms"
          checked={isEditing ? form.dnd_sms : contactData.dnd_sms}
          onChange={handleInputChange}
          disabled={!isEditing}
        />
        <div className="itp-field-label">Opted Out (Do not receive messages)</div>
      </FormGroup>
    </>
  );
};

export default BasicInfoSection; 