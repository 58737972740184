import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LoadingButton } from '../../../../components';
import { fetchUsers } from '../services';
import Select from 'react-select';

const ModalAssignToUser = ({ modal, closeModal, modalFunction, loadingSave }) => {
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoadingSelectUsers, setIsLoadingSelectUsers] = useState(false);

  const handleChangeUser = (e) => {
    setUser(e);
  };

  const handleMenuOpen = () => {
    if (!users) {
      setIsLoadingSelectUsers(true);
    }
  };

  useEffect(() => {
    if (isLoadingSelectUsers) {
      fetchUsers(setUsers, setIsLoadingSelectUsers);
    }
  }, [isLoadingSelectUsers]);

  return (
    <Modal isOpen={modal}>
      <ModalHeader>Assign to User</ModalHeader>
      <ModalBody>
        <div className="add-lead-container-field">
          <label className="itp-label-input__label">User</label>
          <Select 
            onChange={handleChangeUser} 
            className="basic-single add-lead-select" 
            classNamePrefix="select" 
            value={user} 
            isSearchable={true} 
            name="user" 
            placeholder="Select User" 
            options={users || []} 
            isLoading={isLoadingSelectUsers} 
            onMenuOpen={handleMenuOpen} />
        </div>
      </ModalBody>
      <ModalFooter>
        <button disabled={loadingSave} className="btn-light" onClick={closeModal}>
          Cancel
        </button>
        <button disabled={!user} className="btn-primary loading" onClick={() => modalFunction(user)}>
          {loadingSave && <LoadingButton />}
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAssignToUser;
