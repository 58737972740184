import { useEffect } from "react";
import petitionGet from "../../../services/petitionGet";
import { orderedCountryList } from "../../../const/countryList";

const useContactSidebar = (props) => {
  const {
    isEditing,
    isSaving,
    toggleEdit,
    handleSave,
    error,
    contactData,
    valueContactList,
    setValueContactList,
    setContactLists,
    setIsLoadingContactLists,
    asignedUser,
    setAsignedUser,
    setUsers,
    setIsLoadingUsers,
    setCountryValue
  } = props;

  // Load contact list options
  const loadContactListOptions = async (inputValue, callback) => {
    try {
      setIsLoadingContactLists(true);
      const response = await petitionGet("contactList", { parameter: inputValue || "" });

      if (response && response.data && response.data.result) {
        const options = response.data.result.map((item) => ({
          value: item.pk,
          label: `${item?.list_name || ""} (${item?.contact_count || 0})`,
        }));
        setContactLists(options);
        callback(options);
      } else {
        callback([]);
      }
    } catch (error) {
      console.error("Error loading contact lists:", error);
      callback([]);
    } finally {
      setIsLoadingContactLists(false);
    }
  };

  // Load users for assigned user dropdown
  const loadUsers = async () => {
    try {
      setIsLoadingUsers(true);
      const response = await petitionGet("users");

      if (response && response.data && response.data.result) {
        const userOptions = response.data.result.map((user) => ({
          value: user.pk,
          label: `${user.firstname || ""} ${user.lastname || ""}`,
        }));
        setUsers(userOptions);
      }
    } catch (error) {
      console.error("Error loading users:", error);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  // Load contact list details
  const loadContactListDetails = async () => {
    if (contactData.contact_list_id && !valueContactList) {
      try {
        const response = await petitionGet("contactList", {
          contact_list_id: contactData.contact_list_id,
        });

        if (response && response.data && response.data.result) {
          const lists = response.data.result;
          const defaultList = lists.find((list) => list.pk === contactData.contact_list_id);

          if (defaultList) {
            defaultList.label = `${defaultList?.list_name || ""} (${
              defaultList?.contact_count || 0
            })`;
            setValueContactList(defaultList);
          }
        }
      } catch (error) {
        console.error("Error loading contact list details:", error);
      }
    }
  };

  // Load user details
  const loadUserDetails = async () => {
    if (contactData.owner_id && !asignedUser) {
      try {
        const response = await petitionGet("user", { user_id: contactData.owner_id });

        if (response && response.data && response.data.result) {
          const user = response.data.result;
          user.label = `${user.firstname || ""} ${user.lastname || ""}`;
          user.value = user.pk;

          setAsignedUser(user);
        }
      } catch (error) {
        console.error("Error loading user details:", error);
      }
    }
  };

  // Set country value
  useEffect(() => {
    if (contactData.country) {
      const country = orderedCountryList.find((c) => c.value === contactData.country);
      if (country) {
        setCountryValue(country);
      }
    }
  }, [contactData.country, setCountryValue]);

  // Load initial data
  useEffect(() => {
    loadContactListDetails();
    loadUserDetails();
  }, [contactData]);

  return {
    isEditing,
    isSaving,
    toggleEdit,
    handleSave,
    error,
    loadData: {
      loadContactListOptions,
      loadUsers,
      loadContactListDetails,
      loadUserDetails
    }
  };
};

export default useContactSidebar; 