import { useState, useEffect, useRef } from "react";

export const useCustomFieldsHandling = ({
  customFields,
  setAddedCustomField,
  form,
  contactData,
  setForm,
  setContactData,
}) => {
  // Create a ref for custom fields to match expected format
  const customFieldsRef = useRef(customFields || []);
  // Flag to track if a custom field has been added
  const [hasNewCustomField, setHasNewCustomField] = useState(false);
  // Track if a custom field has been edited and store edit info
  const [hasEditedCustomField, setHasEditedCustomField] = useState(false);
  const [editedFieldInfo, setEditedFieldInfo] = useState(null);
  // Flags to track if a custom field has been deleted and store which field was deleted
  const [hasDeletedCustomField, setHasDeletedCustomField] = useState(false);
  const [deletedFieldName, setDeletedFieldName] = useState('');

  // Update customFieldsRef when customFields prop changes
  useEffect(() => {
    customFieldsRef.current = customFields || [];
  }, [customFields]);

  // Custom function to handle adding a new custom field
  const handleAddedCustomField = () => {
    setHasNewCustomField(true);
    // Also call the parent component's function if provided
    setAddedCustomField(true);
  };

  // Custom function to handle editing a custom field
  const handleEditedCustomField = (fieldInfo) => {
    setEditedFieldInfo(fieldInfo);
    setHasEditedCustomField(true);
  };

  // Custom function to handle deleting a custom field
  const handleDeletedCustomField = (fieldName) => {
    setDeletedFieldName(fieldName);
    setHasDeletedCustomField(true);
  };

  // Function to update form with new custom fields
  const updateFormWithNewCustomFields = () => {
    if (!customFieldsRef.current || !customFieldsRef.current.length) return;

    // Create a new form object that includes the new fields
    const updatedForm = { ...form };

    customFieldsRef.current.forEach((field) => {
      // Only add the field if it doesn't already exist in the form
      if (field.name && updatedForm[field.name] === undefined) {
        // Initialize with default values based on field type
        switch (field.field_type) {
          case 'checkbox':
            updatedForm[field.name] = false;
            break;
          case 'list':
            updatedForm[field.name] = '';
            break;
          default:
            updatedForm[field.name] = '';
        }
      }
    });

    setForm(updatedForm);

    // Also update contactData to reflect these changes in view mode
    // Creamos una función interna para manejar este update ya que setContactData es opcional
    const updateContactData = (prev) => ({
      ...prev,
      ...Object.fromEntries(
        Object.entries(updatedForm).filter(([key]) => !Object.keys(prev).includes(key))
      ),
    });

    if (typeof setContactData === 'function') {
      setContactData(updateContactData(contactData));
    }

    setHasNewCustomField(false);
  };

  // Function to update form after a custom field has been edited
  const updateFormAfterFieldEdit = () => {
    if (!editedFieldInfo || !editedFieldInfo.oldName || !editedFieldInfo.newName) return;

    // Create a new form object with the updated field name
    const updatedForm = { ...form };

    // If the old field name exists in the form, rename it
    if (updatedForm.hasOwnProperty(editedFieldInfo.oldName)) {
      // Copy value from old field name to new field name
      updatedForm[editedFieldInfo.newName] = updatedForm[editedFieldInfo.oldName];
      // Remove old field
      delete updatedForm[editedFieldInfo.oldName];
    }

    setForm(updatedForm);

    // Also update contactData to reflect these changes in view mode
    if (typeof setContactData === 'function') {
      const updatedContactData = { ...contactData };
      if (updatedContactData.hasOwnProperty(editedFieldInfo.oldName)) {
        updatedContactData[editedFieldInfo.newName] = updatedContactData[editedFieldInfo.oldName];
        delete updatedContactData[editedFieldInfo.oldName];
      }
      setContactData(updatedContactData);
    }

    setEditedFieldInfo(null);
    setHasEditedCustomField(false);
  };

  // Function to update form after a custom field has been deleted
  const updateFormAfterFieldDelete = () => {
    if (!deletedFieldName) return;

    // Create a new form object without the deleted field
    const formCopy = { ...form };
    const { [deletedFieldName]: removed, ...updatedForm } = formCopy;

    setForm(updatedForm);

    // Also update contactData to reflect these changes in view mode
    if (typeof setContactData === 'function') {
      const contactDataCopy = { ...contactData };
      const { [deletedFieldName]: removedFromContact, ...updatedContactData } = contactDataCopy;
      setContactData(updatedContactData);
    }

    setDeletedFieldName('');
    setHasDeletedCustomField(false);
  };

  // Effect to update form when custom fields change
  useEffect(() => {
    if (hasNewCustomField) {
      updateFormWithNewCustomFields();
    }
  }, [hasNewCustomField]);

  // Effect to update form when a custom field is edited
  useEffect(() => {
    if (hasEditedCustomField && editedFieldInfo) {
      updateFormAfterFieldEdit();
    }
  }, [hasEditedCustomField, editedFieldInfo]);

  // Effect to update form when a custom field is deleted
  useEffect(() => {
    if (hasDeletedCustomField) {
      updateFormAfterFieldDelete();
    }
  }, [hasDeletedCustomField]);

  return {
    customFieldsRef,
    handleAddedCustomField,
    handleEditedCustomField,
    handleDeletedCustomField,
  };
}; 