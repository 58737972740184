import React, { useEffect, useState } from "react";
import { AlignedRow, LoadingButton } from "../../../../../../components";
import petitionGet from "../../../../../../services/petitionGet";
import petitionPost from "../../../../../../services/petitionPost";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import "./AddNumber.css";

const AddNumber = ({
  step,
  setStep,
  closeModal,
  listOfNumbers,
  setListOfNumbers,
  errorNumbers,
  setErrorNumbers,
  selectedNumbers,
  setSelectedNumbers,
  resetActive,
  setResetActive,
  pricePerDid,
  setPricePerDid,
  filterNumbers,
  setFilterNumbers,
  prefix,
  setPrefix,
  npa,
  setNpa,
  cityState,
  setCityState,
  npaNxx,
  setNpaNxx,
  checkbox,
  setCheckBox,
  errorChecked,
  setErrorChecked,
  resetAll,
  reloadData,
  loadingPetition,
  setLoadingPetition,
}) => {
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

  const [loading, setLoading] = useState(false);

  const onSubmitNumbers = async () => {
    setLoading("submit");
    if (filterNumbers.country !== "selected") {
      if (filterNumbers.category === "local") {
        switch (filterNumbers.type) {
          case "NPA":
            if (npa.code.trim() === "") {
              setLoading(false);
              return setErrorNumbers("Codes Required");
            }

            await petitionGet("orderNumbers", {
              type: filterNumbers.type,
              code: npa.code,
            })
              .then(({ data: result }) => {
                if (!result.result.SearchResult) {
                  setListOfNumbers(null);
                  setErrorNumbers("Numbers not available");
                } else {
                  setListOfNumbers(
                    result.result.SearchResult.TelephoneNumberList.TelephoneNumber.map(
                      (element) => ({ label: element, value: element })
                    )
                  );
                  setResetActive(true);
                  setErrorNumbers(null);
                  setStep(step + 1);
                }
              })
              .catch((error) => {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              });

            break;

          case "City/State":
            if (cityState.city === "" || cityState.state === "") {
              setLoading(false);
              return setErrorNumbers("Options Required");
            }

            await petitionGet("orderNumbers", {
              type: filterNumbers.type,
              code: cityState,
            })
              .then(({ data: result }) => {
                if (!result.result.SearchResult) {
                  setListOfNumbers(null);
                  setErrorNumbers("Numbers not available");
                } else {
                  setListOfNumbers(
                    result.result.SearchResult.TelephoneNumberList.TelephoneNumber.map(
                      (element) => ({ label: element, value: element })
                    )
                  );
                  setResetActive(true);
                  setErrorNumbers(null);
                  setStep(step + 1);
                }
              })
              .catch((error) => {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              });

            break;

          case "NPANXX":
            if (npaNxx.nxx === "") {
              setLoading(false);
              return setErrorNumbers("Codes Required");
            }

            await petitionGet("orderNumbers", {
              type: filterNumbers.type,
              code: npaNxx,
            })
              .then(({ data: result }) => {
                if (
                  !result.result.SearchResult ||
                  result.result.SearchResult.Error
                ) {
                  setListOfNumbers(null);
                  setErrorNumbers("Numbers not available");
                } else {
                  setListOfNumbers(
                    result.result.SearchResult.TelephoneNumberList.TelephoneNumber.map(
                      (element) => ({ label: element, value: element })
                    )
                  );
                  setResetActive(true);
                  setErrorNumbers(null);
                  setStep(step + 1);
                }
              })
              .catch((error) => {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              });

            break;

          default:
            setErrorNumbers("Please Select Number Type");
        }
      } else {
        if (prefix.code === "selected") {
          setLoading(false);
          return setErrorNumbers("Codes Required");
        }
        await petitionGet("prefix", {
          code: prefix.code,
        })
          .then(({ data: result }) => {
            if (
              !result.result.SearchResult ||
              result.result.SearchResult.Error
            ) {
              setListOfNumbers(null);
              setErrorNumbers("Numbers not available");
            } else {
              setListOfNumbers(
                result.result.SearchResult.TelephoneNumberList.TelephoneNumber.map(
                  (element) => ({ label: element, value: element })
                )
              );
              setResetActive(true);
              setErrorNumbers(null);
              setStep(step + 1);
            }
          })
          .catch((error) => {
            setListOfNumbers(null);
            setErrorNumbers("Numbers not available");
          });
      }

      setLoading(false);
    } else {
      setErrorNumbers("Please Select Country");
    }
  };

  const nextStep = () => {
    if (step === 3) {
      if (!checkbox) {
        return setErrorChecked("Accept Terms");
      }

      setLoadingPetition(true);

      const numbers = {
        numbers: selectedNumbers.map((number) => ({
          carrier_did: number.number.includes("+1") ? number.number : `+1${number.number}`,
          did: number.number.includes("+1") ? number.number.slice(2) : number.number,
        })),
      };

      petitionPost("orders", { data: numbers })
        .then(({ data: result }) => {
          setLoadingPetition(false);
          reloadData()
        })
        .catch((error) => {
          setLoadingPetition(false);
        });
    } else {
      setStep(step + 1);
      if (step + 1 === 2) {
        closeModal();
      }
    }
  };

  useEffect(() => {
    if (step === 0) {
      setResetActive(false);
      resetAll();
    }
  }, [step]);

  useEffect(() => {
    petitionGet("services")
      .then((result) => {
        let price_per_did = result.data.result.filter(
          (element) => element.api_id === id
        );

        setPricePerDid(parseFloat(price_per_did[0]?.metadata?.price_per_did));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {step <= 1 && (
        <div className="d-flex">
          <div>
            <label className="itp-label-input__label mb-0">
              1. Select Location
            </label>
            <div
              className={`itp-add-number-border ${step === 0 ? "active" : ""}`}
            ></div>
          </div>
          <div className="itp-edit-number-container-second-step">
            <label className="itp-label-input__label mb-0">
              2. Select Numbers
            </label>
            <div
              className={`itp-add-number-border ${step === 1 ? "active" : ""}`}
            ></div>
          </div>
        </div>
      )}

      {step === 0 && (
        <Step1
          setFilterNumbers={setFilterNumbers}
          filterNumbers={filterNumbers}
          resetActive={resetActive}
          setErrorNumbers={setErrorNumbers}
          setCityState={setCityState}
          setNpaNxx={setNpaNxx}
          setNpa={setNpa}
          npa={npa}
          cityState={cityState}
          npaNxx={npaNxx}
          onSubmitNumbers={onSubmitNumbers}
          errorNumbers={errorNumbers}
          loading={loading}
          setLoading={setLoading}
          resetAll={resetAll}
          setPrefix={setPrefix}
        />
      )}

      {step === 1 && (
        <>
          <Step2
            listOfNumbers={listOfNumbers}
            selectedNumbers={selectedNumbers}
            setSelectedNumbers={setSelectedNumbers}
            filterNumbers={filterNumbers}
            prefix={prefix}
            npa={npa}
            npaNxx={npaNxx}
            cityState={cityState}
            loading={loading}
          />
        </>
      )}

      {step === 2 && (
        <Step3
          selectedNumbers={selectedNumbers}
          setSelectedNumbers={setSelectedNumbers}
          pricePerDid={pricePerDid}
        />
      )}

      {step === 3 && (
        <>
          <Step4
            selectedNumbers={selectedNumbers}
            pricePerDid={pricePerDid}
            setCheckBox={setCheckBox}
            errorChecked={errorChecked}
            setErrorChecked={setErrorChecked}
          />
        </>
      )}

      {step >= 2 && (
        <AlignedRow>
          <button
            style={{ width: "9rem" }}
            className="btn-primary mt-4 loading mb-4"
            onClick={nextStep}
            disabled={loadingPetition}
          >
            {loadingPetition && <LoadingButton />}
            {step === 3 ? "Confirm Order" : "Next"}
          </button>
        </AlignedRow>
      )}

      {step < 2 && selectedNumbers.length > 0 && (
        <button
          style={{ width: "9rem" }}
          className="btn-primary mt-4 loading"
          onClick={nextStep}
          disabled={loadingPetition}
        >
          {loadingPetition && <LoadingButton />}
          {step === 3 ? "Confirm Order" : "Next"}
        </button>
      )}
    </>
  );
};

export default AddNumber;
