/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from "react";
import { faEllipsis, faUserPlus, faMicrophone, faGrip, faHand, faPhoneFlip, faDeleteLeft, faMicrophoneSlash, faHandshakeSimpleSlash, faArrowRightArrowLeft, faUsers, faShuffle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownMenu, ButtonDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import { UserAgent } from "sip.js";
import { muteAudioInConference, setupRemoteMedia } from "../../logic/media";
import call from "../../logic/call";
import onNotifyFunction from "../../logic/onNotify";
import Select from 'react-select';
import "./OnCall.css";


const OnCall = ({
  sessionIncall,
  unHoldCall,
  holdCall,
  sipconfiguration,
  setCallSessions,
  callSessions,
  handleAnswerOnInvite,
  agent,
  setCallSessionsFunctionEnd,
  handleByeOnInvite,
  conferenseSession,
  setCloseSettings,
  callAudio,
  rinding,
  numberToCall,
  setNumberTocall,
  handleByeOnCall,
  connecting,
  setShowWebphone,
  audioInputSelected,
  sessionIncallRef,
  customSelectStyles,
  handleDropdownMenuOpen,
  assignedNumbers,
  callerIdNumber,
  handleChangeDropdonw,
  isLoadingNumbers,
}) => {
  const inputRef = useRef(null)

  // Definir el estado del número de teléfono
  const [number, setNumber] = useState("");
  const [onCallOpen, setOnCallOpen] = useState(false);
  const [addCallOpen, setAddCallOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // / addNumber en true añade al numero automaticamente a la llamada, se desactivó esta opción el 13-05-2024
  const [addNumber, setAddNumber] = useState(true);
  const [transferNumber, setTransferNumber] = useState(false);
  const [inTransfer, setInTransfer] = useState(false);
  const [disabletransferNumber, setDisableTransferNumber] = useState(false);

  const [mute, setMute] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [holdButton, setHoldButton] = useState(false);
  // const [callAudio] = useState(new Audio());
  const Numbers = [
    { number: "1", text: "/" },
    { number: "2", text: "A B C" },
    { number: "3", text: "D E F" },
    { number: "4", text: "G H I" },
    { number: "5", text: "J K L" },
    { number: "6", text: "M N O" },
    { number: "7", text: "P Q R S" },
    { number: "8", text: "T U V" },
    { number: "9", text: "W X Y Z" },
    { number: "*", text: " " },
    { number: "0", text: " " },
    { number: "#", text: " " },
  ];
  // Definir la función que se ejecuta al presionar una tecla
  const handleKeyPress = (key) => {
    // Añadir el símbolo al número de teléfono
    if (addCallOpen) {
      if (/^\d*[#*]?$|^[#*]?\d*$/.test(number + key)) {
        setNumber(number + key);
      }
    } else {
      setNumber(number + key);
      if (!addNumber && !transferNumber) {
        sendDTMF(key);
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (addCallOpen) {
      // Usa una expresión regular para permitir dígitos, "*" y "#" al principio o al final
      if (/^\d*[#*]?$|^[#*]?\d*$/.test(value)) {
        setNumber(value);
      }
    } else {
      setNumber(value);
      if (!addNumber && !transferNumber) {
        sendDTMF(value);
      }
    }
  };

  const handleClickOnCallNumber = () => {
    if (inputRef.current) {
        inputRef.current.focus()
    }
  }

  const handleGreenButton = async () => {
    let newNumberToCall = { number: number };
    if (addNumber) {
      console.log("add number");
      conferenseSession.current = true
      await sipconfiguration
        .endPointToFindContactsToCall("findContact", { number: number })
        .then(async ({ data: result }) => {
          await setNumberTocall({ number: number, data: result.result });
          let numberToCallTemp = { number: number, data: result.result };
          call(rinding, numberToCallTemp, sipconfiguration, setCallSessions, agent, callSessions, callAudio, sessionIncall, handleAnswerOnInvite, setCallSessionsFunctionEnd, true, null);
        })
        .catch((error) => {
          console.log(error);
          let numberToCallTemp = { number: number, data: {} };
          setNumberTocall({ number: number, data: {} });
          call(rinding, numberToCallTemp, sipconfiguration, setCallSessions, agent, callSessions, callAudio, sessionIncall, handleAnswerOnInvite, setCallSessionsFunctionEnd, true, null);
        });
    } else {
      if (transferNumber) {
        console.log("disabledTransfer1");
        setDisableTransferNumber(true);
        if (sessionIncall.length > 0) {
          let target = UserAgent.makeURI(
            `sip:${newNumberToCall.number}@${sipconfiguration.realm}`
          );

          const options = {
            onNotify: (notification) =>
              onNotifyFunction(notification, transferNumber, setCallSessionsFunctionEnd, handleCancel),
          };
          console.log("---------------------------------notification2");

          transferNumber.refer(target, options);

          transferNumber.transfered = true;
        }
      } else {
        await sipconfiguration
          .endPointToFindContactsToCall("findContact", { number: number })
          .then(async ({ data: result }) => {
            await setNumberTocall({ number: number, data: result.result });

            // Use setTimeout to delay the call function
            setTimeout(() => {
              let numberToCallTemp = { number: number, data: result.result };
              call(rinding, numberToCallTemp, sipconfiguration, setCallSessions, agent, callSessions, callAudio, sessionIncall, handleAnswerOnInvite, setCallSessionsFunctionEnd);
            }, 1000); // Adjust the delay time as needed
          })
          .catch((error) => {
            console.log(error);
            setNumberTocall({ number: number, data: {} });
            let numberToCallTemp = { number: number, data: {} };
            call(rinding, numberToCallTemp, sipconfiguration, setCallSessions, agent, callSessions, callAudio, sessionIncall, handleAnswerOnInvite, setCallSessionsFunctionEnd);
          });
      }
    }
    // Llamar a la función que realiza la transferencia de llamada
    // con el número de teléfono introducido
    // transferCall(phoneNumber);
    setOnCallOpen(false);
  };

  // Definir la función que se ejecuta al presionar el botón de cancelar
  const handleCancel = () => {
    setOnCallOpen(false);
    setAddCallOpen(false);
    setAddNumber(true);
    setTransferNumber(false);
    setInTransfer(false);
    setDisableTransferNumber(false);
    console.log("disabledTransfer2");
  };

  const handleNumberDelete = (e) => {
    e.stopPropagation()
    if (number.length > 0) {
      // Elimina el último carácter utilizando slice
      const newNumber = number.slice(0, -1);
      setNumber(newNumber);
    }
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const updateCallDuration = () => {
      // Calcula la diferencia entre Date.now() y sessionIncall[0].CallTimer
      const startTime = sessionIncall[0]?.CallTimer; // Asegúrate de que sessionIncall[0].CallTimer sea un timestamp válido
      const currentTime = Date.now();
      const differenceInSeconds = Math.floor((currentTime - startTime) / 1000);
      // Actualiza el estado de la duración de la llamada
      setCallDuration(differenceInSeconds);
    };

    // Actualiza la duración de la llamada cada segundo
    const intervalId = setInterval(updateCallDuration, 1000);

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, [sessionIncall]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const holdButtonActions = () => {
    setHoldButton(!holdButton);

    if (!holdButton) {
      sessionIncall.forEach(async (el) => {
        if (!el.onHold) {
          await holdCall(el);
        }
      });
    }  else if (holdButton && sessionIncall.length > 1) {
      unHoldAllCalls()
    } else if (holdButton && sessionIncall.length === 1) {
      sessionIncall.forEach(async (el) => {
        if (el.onHold) {
          await unHoldCall(el);
        }
      });
    }
  };

  const unHoldAllCalls = () => {
    sessionIncall.forEach((el) => {
      unHoldCall(el);
    });
    conferenseSession.current = true
  };

  const toggleMute = () => {
    setMute(!mute);
    if (!mute) {
      muteCall();
    } else {
      unmuteCall();
    }
  };

  function muteCall() {
    muteAudioInConference(sessionIncall, callAudio);

    // if (conferenseSession) {
    //   muteAudioInConference(sessionIncall, callAudio);
    // } else {
    //   sessionIncall.forEach((el) => {
    //     if (el?.sessionDescriptionHandler?.peerConnection?.getSenders()) {
    //       el.sessionDescriptionHandler.peerConnection.getSenders().forEach(function (receiver) {
    //         console.log(receiver);
    //         if (receiver.track != null) {
    //           receiver.track.enabled = false;
    //         }
    //       });
    //     }
    //   });
    // }
  }

  async function unmuteCall() {
    await setupRemoteMedia(callSessions, callAudio);
    onChangeAudioInput(audioInputSelected);

    setTimeout(() => {
      setupRemoteMedia(sessionIncall, callAudio);
    }, 500);


    // if (conferenseSession) {
    //   await setupRemoteMedia(callSessions, callAudio);
    //   onChangeAudioInput(audioInputSelected);

    //   setTimeout(() => {
    //     setupRemoteMedia(sessionIncall, callAudio);
    //   }, 500);
    // } else {
    //   console.error(sessionIncall);
    //   console.error(callSessions);
    //   sessionIncall.forEach((el) => {
    //     if (el?.sessionDescriptionHandler?.peerConnection?.getSenders()) {
    //       el.sessionDescriptionHandler.peerConnection.getSenders().forEach(function (receiver) {
    //         console.log(receiver);
    //         if (receiver.track != null) {
    //           receiver.track.enabled = true;
    //         }
    //       });
    //     }
    //   });
    //   cleanupMedia(sessionIncall, callAudio);
    //   sessionIncall.forEach((el) => {});
    // }
  }

  const onChangeAudioInput = async (audioInput) => {
    if (audioInput) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      let audioInputDevices = devices.filter((device) => device.kind === 'audioinput');
      let defaultAudioInput = audioInputDevices.find((device) => device.deviceId === 'default') || audioInputDevices[0];

      if (!defaultAudioInput) {
        console.error('No audio input device found.');
        return;
      }

      const audioSource = audioInput === "default" ? defaultAudioInput.deviceId : audioInput;
      const constraints = {
        audio: { deviceId: audioSource ? { exact: audioSource } : "undefined" },
        video: false,
      };
      try {
        if (sessionIncall.length !== 0) {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);

          gotStream(stream);
        } else {
          await navigator.mediaDevices.getUserMedia(constraints);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const gotStream = (stream) => {
    sessionIncall.forEach((element) => {
      if (element?.sessionDescriptionHandler?.peerConnection?.getSenders()[0]) {
        element.sessionDescriptionHandler.peerConnection.getSenders()[0].replaceTrack(stream.getAudioTracks()[0]);
      }
    });
    return navigator.mediaDevices?.enumerateDevices();
  };

  function sendDTMF(DTMFNumber) {
    if (sessionIncall.length !== 0) {
      const options = {
        requestOptions: {
          body: {
            contentDisposition: "render",
            contentType: "application/dtmf-relay",
            content: "Signal=" + DTMFNumber + "\r\nDuration=1000",
          },
        },
      };
      sessionIncall.forEach((element) => {
        element.info(options);
      });
    }
  }

  const handleTransfer = (element) => {
    setInTransfer(true);
    setTransferNumber(element);
    setOnCallOpen(true);
    setAddCallOpen(true);
    setAddNumber(false);
  };

  const handleParkingCall = (element) => {
    if (sessionIncall.length > 0) {
      let target = UserAgent.makeURI(`sip:70@${sipconfiguration.realm}`);

      const options = {
        onNotify: (notification) =>
          onNotifyFunction(
            notification,
            element,
            setCallSessionsFunctionEnd,
            handleCancel
          ),
      };

      element.refer(target, options);
      element.parked = true;
    }
  };

  const handleByeOnSwitch = (index) => {
    console.log("22222222222222222222-");
    sessionIncall.forEach((element, i) => {
      if (i === index) {
        element.onHold = true;
        holdCall(element);
      } else {
        element.onHold = false;
        unHoldCall(element);
      }
    });
  };

  const warmTransfer = () => {
    conferenseSession.current = false
    sipconfiguration.endpointToWarmTransfer("warmTransfer", {
      data: {
        user_id: 182,
      },
    });
  };

  useEffect(() => {
    // Función para desactivar la edición del input después de pegar
    if (onCallOpen) {
      const handlePaste = (e) => {
        e.preventDefault(); // Previene la acción predeterminada de pegar
        const pastedText = e.clipboardData.getData("text/plain");
        setNumber(pastedText); // Establece el valor del input con el texto pegado
      };

      // Agrega un event listener al input para manejar el pegado
      const inputElement = document.getElementById("numberInput");
      inputElement.addEventListener("paste", handlePaste);

      return () => {
        // Elimina el event listener cuando el componente se desmonta
        inputElement.removeEventListener("paste", handlePaste);
      };
    }
  }, [onCallOpen]);

  useEffect(() => {
    console.log("8888888888888888888888888888888888888888888888888numberToCall ha cambiado:", numberToCall);
    console.log("8888888888888888888888888888888888888888888888888numberToCall ha cambiado:",  sessionIncall);
    sessionIncall.forEach((el) => {
      console.log(el.remoteIdentity)
      console.log(el.dataCall)
    })
  }, [sessionIncall]);

  useEffect(() => {
    console.log(conferenseSession.current)
    console.log(sessionIncallRef.current)
  }, [conferenseSession, sessionIncallRef])

  // Retornar el elemento JSX que representa la pantalla de transferencia
  return (
    <div className={`oncall ${!onCallOpen ? "oncall-close" : "oncall-open"}`}>
      {inTransfer ? (
        <>
          <div className="oncall-header">
            <div className="oncall-top-data">
              <div>
                Transfer Call
                <br />
                Enter number to transfer to
              </div>
            </div>
            <br />
            <br />
          </div>

          {onCallOpen && (
            <div className="oncall-number">
              <input
                onChange={handleInputChange}
                type={"text"}
                className="oncall-number-span"
                value={number}
                id="numberInput"
                autoComplete="off"
              />
              <div className="oncall-number-delete">
                <div onClick={handleNumberDelete} id="keyboard-number-delete">
                  <FontAwesomeIcon icon={faDeleteLeft} />
                </div>
              </div>
            </div>
          )}

          {onCallOpen && (
            <>
              {" "}
              <div className="oncall-keypad">
                {Numbers.map((element, i) => (
                  <div
                    className="oncall-button-keyboard"
                    onClick={() => {
                      handleKeyPress(element.number);
                    }}
                    id={element.number}
                    key={i}
                  >
                    {element.number}
                  </div>
                ))}
              </div>
              <div className="buttons">
                <div
                  className={
                    disabletransferNumber
                      ? "oncall-buttons-actions oncall-transfer-disabled"
                      : "oncall-buttons-actions oncall-transfer"
                  }
                  onClick={
                    number && !disabletransferNumber ? handleGreenButton : null
                  }
                >
                  {addNumber ? "Add" : transferNumber ? "Transfer" : "Call"}
                </div>
                <div
                  className="oncall-buttons-actions oncall-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="oncall-header">
            <div className="oncall-top-data">
              <div className="d-flex align-items-center gap-2">
                <div>{conferenseSession.current && sessionIncall.length > 1 ? "Conference" : "On Call"}</div>
                <span className="oncall-span">{formatTime(callDuration)}</span>
                <div>
                <Select
                  styles={customSelectStyles}
                  options={assignedNumbers || []}
                  placeholder="Caller Id Number"
                  value={callerIdNumber}
                  onChange={handleChangeDropdonw}
                  isLoading={isLoadingNumbers}
                  onMenuOpen={handleDropdownMenuOpen}
                />
            </div>
              </div>
              <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="onCall-dropdown">
                  <FontAwesomeIcon icon={faEllipsis} />
                </DropdownToggle>
                <DropdownMenu>
                  {sessionIncall.map((element, i) => (
                    <DropdownItem
                      onClick={() => handleTransfer(element)}
                      key={i}
                    >
                      {"Transfer " + element.remoteIdentity.uri.normal.user}
                    </DropdownItem>
                  ))}

                  {sessionIncall.map((element, i) => (
                    <DropdownItem
                      onClick={() => handleParkingCall(element)}
                      key={i}
                    >
                      {"Park call " + element.remoteIdentity.uri.normal.user}
                    </DropdownItem>
                  ))}

                  {onCallOpen && (
                    <DropdownItem
                      onClick={() => {
                        handleCancel();
                      }}
                    >
                      Minimize
                    </DropdownItem>
                  )}

                  <DropdownItem
                    onClick={() => {
                      setCloseSettings(false);
                    }}
                  >
                    Settings
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      if (conferenseSession.current && sessionIncall.length > 1) {
                        warmTransfer();
                      } else {
                        handleByeOnCall();
                      }
                    }}
                    disabled={!(conferenseSession.current && sessionIncall.length > 1)}
                  >
                    Leave
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      setShowWebphone(false);
                    }}
                  >
                    Close
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
          {/* {sessionIncall.length > 1 && <p onClick={warmTransfer}>Salir de llamada</p>} */}

          {sessionIncall.map((element, index) => (
            <div className="oncall-call-info" key={index}>
              <div className="user-info-container">
                <span className="tooltip-message">
                  {element.remoteIdentity.uri.normal.user}
                </span>
                {/* {console.log(element)} */}
                <div className="d-flex">                  
                  <div className="oncall-button-container">
                    <div>
                      {
                        element.dataCall.firstname 
                        ? element.dataCall.firstname +  " " + element.dataCall.lastname
                        : element.remoteIdentity._displayName
                        ? element.remoteIdentity._displayName
                        : element.remoteIdentity.uri.normal.user
                      }
                    </div>
                    
                    {(element?.dataCall?.firstname || element?.remoteIdentity?._displayName) && (
                      <div className="oncall-buttons-item-hover-text user-info">
                        {element?.dataCall?.phone || element?.remoteIdentity?.uri?.normal?.user}
                      </div>
                    )}
                  </div>
                  
                  <span className="oncall-span">
                    {connecting
                      ? "Connecting"
                      : element.parked
                      ? "Parked"
                      : element.transfered
                      ? "Transfered"
                      : element.onHold
                      ? "On Hold"
                      : element?.CallTimer
                      ? "Connected"
                      : "Connecting"}
                  </span>
                  <div className="oncall-button-container">
                    <FontAwesomeIcon
                      className="oncall-button-bye-call rotate"
                      icon={faPhoneFlip}
                      onClick={() => {
                        handleByeOnInvite(element);
                      }}
                    />
                    
                    <div className="oncall-buttons-item-hover-text user-info">
                      Hangup
                    </div>
                  </div>
 
                  {!conferenseSession.current && !element.onHold && sessionIncallRef.current.length > 1 && (
                      <>
                        <div className="oncall-button-container">
                          <FontAwesomeIcon
                            className="oncall-button-bye-call"
                            icon={faArrowRightArrowLeft}
                            onClick={() => {
                              handleByeOnSwitch(index);
                            }}
                          />

                          <div className="oncall-buttons-item-hover-text user-info">
                            Switch
                          </div>
                        </div>

                        <div className="oncall-button-container">
                          <FontAwesomeIcon
                            className="oncall-button-bye-call"
                            icon={faShuffle}
                            onClick={() => {
                              unHoldAllCalls(index);
                            }}
                          />

                          <div className="oncall-buttons-item-hover-text user-info">
                            Merge Calls
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          ))}

          {conferenseSession.current && sessionIncall.length > 1 && (
            <div className="onConference">
              <FontAwesomeIcon icon={faUsers} />
            </div>
          )}

          {sessionIncall.length === 1 && (
            <>
              <div className="oncall-call-info">
                <div>
                  <FontAwesomeIcon icon={faUserPlus} />{" "}
                  <span
                    className="oncall-span"
                    onClick={() => {
                      setAddNumber(false);
                      setOnCallOpen(true);
                      setAddCallOpen(true);
                    }}
                  >
                    Add Call
                  </span>
                </div>
              </div>
            </>
          )}

          {!onCallOpen && (
            <div className="oncall-buttons">
              <div
                className="oncall-buttons-item oncall-buttons-item-mic"
                onClick={toggleMute}
              >
                {mute ? (
                  <FontAwesomeIcon icon={faMicrophoneSlash} />
                ) : (
                  <FontAwesomeIcon icon={faMicrophone} />
                )}
                
                <div className="oncall-buttons-item-hover-text">
                  {mute ? "Unmute" : "Mute"}
                </div>
              </div>

              <div
                className="oncall-buttons-item oncall-buttons-item-grip"
                onClick={() => {
                  setOnCallOpen(!onCallOpen);
                  setAddNumber(false);
                  setAddCallOpen(false);
                }}
              >
                <FontAwesomeIcon icon={faGrip} />

                <div className="oncall-buttons-item-hover-text">
                  Keypad 
                </div>
              </div>

              <div
                className="oncall-buttons-item oncall-buttons-item-hand"
                onClick={holdButtonActions}
              >
                <FontAwesomeIcon
                  icon={sessionIncall[sessionIncall.length - 1]?.onHold ? faHandshakeSimpleSlash : faHand}
                />

                <div className="oncall-buttons-item-hover-text">
                  {sessionIncall[sessionIncall.length - 1]?.onHold ? "Unhold" : "Hold"} 
                </div>
              </div>

              <div
                className="oncall-buttons-item oncall-buttons-item-bye"
                onClick={() => {
                  handleByeOnCall();
                }}
              >
                <FontAwesomeIcon
                  className="oncall-button-bye"
                  icon={faPhoneFlip}
                />

                <div className="oncall-buttons-item-hover-text">
                  Hangup  
                </div>
              </div>
            </div>
          )}

          {onCallOpen && (
            <div onClick={handleClickOnCallNumber} className="oncall-number">
              <input
                onChange={handleInputChange}
                type={"text"}
                className="oncall-number-span"
                value={number}
                id="numberInput"
                autoComplete="off"
                ref={inputRef}
              />
              <div className="oncall-number-delete">
                <div onClick={handleNumberDelete} id="keyboard-number-delete">
                  <FontAwesomeIcon icon={faDeleteLeft} />
                </div>
              </div>
            </div>
          )}
          {onCallOpen && (
            <>
              {" "}
              <div className="oncall-keypad">
                {Numbers.map((element, i) => (
                  <div
                    className="oncall-button-keyboard"
                    onClick={() => handleKeyPress(element.number)}
                    id={element.number}
                    key={i}
                  >
                    {element.number}
                  </div>
                ))}
              </div>
              <div className="buttons">
                {addCallOpen && (
                  <div
                    className={disabletransferNumber ? "oncall-buttons-actions oncall-transfer-disabled" : "oncall-buttons-actions oncall-transfer"}
                    onClick={ number && !disabletransferNumber ? handleGreenButton : null
                    }
                  >
                    {addNumber ? "Add" : transferNumber ? "Transfer" : "Call"}
                  </div>
                )}
                <div
                  className="oncall-buttons-actions oncall-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OnCall;
