export const returnInputs = (handleClickToolipIcon) => {
  return [
    { 
      label: "Opt-in Keywords", 
      input: "text",
      placeholder: ": Enter Opt-in Keywords",
      key: "optinKeywords",
      toolip: "optinKeywords",
      toolipText: "Click to show example",
      handleClick: () => handleClickToolipIcon("optinKeywords"),
    },
    { 
      label: "Will you be sending phone numbers in your messages?", 
      input: "select",
      key: "embeddedPhone",
      options: [{ value: false, text: "No" }, { value: true, text: "Yes" }],
    },
    { 
      label: "Opt-out Keywords", 
      input: "text",
      placeholder: ": Enter Opt-out Keywords",
      key: "optoutKeywords",
      toolip: "optoutKeywords",
      toolipText: "Click to show example",
      handleClick: () => handleClickToolipIcon("optoutKeywords"),
    },
    { 
      label: "Will you be sending any Age Restricted Content?", 
      input: "select",
      key: "ageGated",
      options: [{ value: false, text: "No" }, { value: true, text: "Yes" }],
    },
    { 
      label: "Help Keywords", 
      input: "text",
      placeholder: ": Enter Help Keywords",
      key: "helpKeywords",
      toolip: "helpKeywords",
      toolipText: "Click to show example",
      handleClick: () => handleClickToolipIcon("helpKeywords"),
    },
    { 
      label: "Help Message", 
      input: "text",
      placeholder: ": Enter Help Message",
      key: "helpMessage",
      toolip: "helpMessage",
      toolipText: "Click to show example",
      handleClick: () => handleClickToolipIcon("helpMessage"),
      counter: true,
      charMin: 20,
      charMax: 320,
    },
    { 
      label: "Will you be sending Links (URLs) in your messages?", 
      input: "select",
      key: "embeddedLink",
      options: [{ value: false, text: "No" }, { value: true, text: "Yes" }],
    },
    { 
      label: "Opt-in Message", 
      input: "text",
      placeholder: ": Enter Opt-in Message",
      key: "optinMessage",
      toolip: "optinMessage",
      toolipText: "Click to Enter Company Name",
      handleClick: () => handleClickToolipIcon("optinMessage"),
      counter: true,
      charMin: 20,
      charMax: 320,
    },
    { 
      label: "Opt-out Message", 
      input: "text",
      placeholder: ": Enter Opt-out Message",
      key: "optoutMessage",
      toolip: "optoutMessage",
      toolipText: "Click to show example",
      handleClick: () => handleClickToolipIcon("optoutMessage"),
      counter: true,
      charMin: 20,
      charMax: 320,
    },
    { 
      label: "Do you offer Direct Lending or Loan Arrangement Services ?", 
      input: "select",
      key: "directLending",
      options: [{ value: false, text: "No" }, { value: true, text: "Yes" }],
    },
    { 
      label: "Please confirm you will not be using affiliated marketing for this campaign", 
      input: "checkbox",
      key: "affiliateMarketing",
      inverse: true,
    },
  ]
}