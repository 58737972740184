const processLeads = (leads) => {
  const processedLead =  leads.map((element) => {
    element.contactName = `${element?.contact?.firstname || ''} ${element?.contact?.lastname || ''}`;
    element.contactEmail = element?.contact?.email || '';
    element.name = element?.name || 'Unassigned';
    element.leadSource = element?.lead_source?.name || '';
    element.funnelName = element?.funnel_pipeline_stage?.funnel?.name || "";
    element.funnelStage = element?.funnel_pipeline_stage?.name || "";
    element.paramContact = element?.contact?.pk || null;
    element.paramFunnel = element?.funnel_pipeline_stage?.funnel?.pk || null;
    element.assignedUser = `${element?.owner?.firstname || ''} ${element?.owner?.lastname || ''}`;

    return element;
  });

  return processedLead
}

export default processLeads;