import { useEffect, useState } from 'react';
import petitionGet from '../../../services/petitionGet';
import petitionPatch from '../../../services/petitionPatch';

export const useLeadState = ({ leadDetails }) => {
  const [valueLeadSource, setValueLeadSource] = useState(null);
  const [valueFunnel, setValueFunnel] = useState(null);
  const [valueStage, setValueStage] = useState(null);
  const [loadingFunnels, setLoadingFunnels] = useState(false);
  const [loadingStages, setLoadingStages] = useState(false);
  const [stageOptions, setStageOptions] = useState([]);
  const [errorEditLead, setErrorEditLead] = useState('');

  const [leadData, setLeadData] = useState({
    pk: '',
    name: '',
    amount: '',
    lead_source_id: '',
    funnel_pipeline_stage_id: '',
    funnel_id: '',
  });

  const [leadForm, setLeadForm] = useState({
    pk: '',
    name: '',
    amount: '',
    lead_source_id: '',
    funnel_pipeline_stage_id: '',
    funnel_id: '',
  });

  const loadOptionFunnel = (inputValue, callback) => {
    setLoadingFunnels(true);

    petitionGet('funnels', { parameter: inputValue ? `?name=${inputValue}` : '?offset=0&limit=10' })
      .then(({ data: result }) => {
        if (result && result.result) {
          const options = result.result.map((funnel) => ({
            value: funnel.pk,
            label: funnel.name || '',
            pk: funnel.pk,
            ...funnel,
          }));

          callback(options);
        } else {
          callback([]);
        }
        setLoadingFunnels(false);
      })
      .catch((error) => {
        console.error('Error loading funnels:', error);
        callback([]);
        setLoadingFunnels(false);
      });
  };

  const loadStages = async (funnelId) => {
    if (!funnelId || !leadDetails) return;

    try {
      setLoadingStages(true);
      const response = await petitionGet('stages', { parameter: `?funnel_id=${funnelId}` });

      if (response && response.data && response.data.result) {
        const options = response.data.result.map((stage) => ({
          value: stage.pk,
          label: stage.name,
          ...stage,
        }));

        setStageOptions(options);
      }
    } catch (error) {
      console.error('Error loading stages:', error);
    } finally {
      setLoadingStages(false);
    }
  };

  const handleLeadInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "amount") {
      // Only update if value is a number or empty string
      if (value === "" || /^[0-9]+(\.[0-9]*)?$/.test(value)) {
        setLeadForm({ ...leadForm, [name]: value });
      }
    } else {
      // Update normally for other fields
      setLeadForm({ ...leadForm, [name]: value });
    }
  };

  const handleLeadSourceChange = (selected) => {
    if (!selected) return;

    setValueLeadSource(selected);
    setLeadForm({ ...leadForm, lead_source_id: selected.value || '' });
  };

  const handleChangeFunnel = (selected) => {
    if (!selected) return;

    setValueFunnel(selected);
    setValueStage(null);

    setLeadForm({ ...leadForm, funnel_id: selected.value || '', funnel_pipeline_stage_id: '' });
    loadStages(selected.value);
  };

  const handleChangeStage = (selected) => {
    if (!selected) return;

    setValueStage(selected);
    setLeadForm({ ...leadForm, funnel_pipeline_stage_id: selected.value || '' });
  };

  const editLead = async () => {
    if (!leadDetails || !leadForm) return true;

    try {
      setErrorEditLead('');

      // Format lead data for API call
      const data = {
        ...leadForm,
        amount: leadForm.amount || 0,
        name: leadForm.name && leadForm.name !== 'Unassigned' ? leadForm.name : '',
      };

      // Update the lead - use the correct endpoint
      await petitionPatch('leads', { lead_id: leadDetails.pk, data });

      setLeadData(data);
      setLeadForm(data);

      return true;
    } catch (error) {
      console.error('Error editing lead:', error);
      const errorMessage = error?.response?.data?.error || error?.message || 'An error occurred while updating the lead';

      setErrorEditLead(errorMessage);
      return false;
    }
  };

  useEffect(() => {
    if (leadDetails) {
      const leadInfo = {
        pk: leadDetails?.pk || '',
        name: leadDetails?.name || '',
        amount: leadDetails?.amount || '',
        lead_source_id: leadDetails?.lead_source?.pk || '',
        funnel_pipeline_stage_id: leadDetails?.funnel_pipeline_stage?.pk || '',
        funnel_id: leadDetails?.funnel_pipeline_stage?.funnel?.pk || '',
      };

      setValueLeadSource({
        label: leadDetails.lead_source.name || '',
        value: leadDetails.lead_source.pk || '',
      });
      setValueFunnel({
        label: leadDetails?.funnel_pipeline_stage?.funnel?.name || '',
        value: leadDetails?.funnel_pipeline_stage?.funnel?.pk || '',
      });
      setValueStage({
        label: leadDetails?.funnel_pipeline_stage?.name || '',
        value: leadDetails?.funnel_pipeline_stage?.pk || '',
      });

      const handleFetchStages = async () => {
        const funnelId = leadDetails?.funnel_pipeline_stage?.funnel?.pk;
        if (funnelId) await loadStages(funnelId);
      };

      handleFetchStages();
      setLeadData(leadInfo);
      setLeadForm(leadInfo);
    }
  }, [leadDetails]);

  return {
    leadData,
    leadForm,
    valueLeadSource,
    valueFunnel,
    valueStage,
    loadingFunnels,
    loadingStages,
    stageOptions,
    errorEditLead,
    setErrorEditLead,
    loadOptionFunnel,
    handleChangeFunnel,
    handleChangeStage,
    handleLeadInputChange,
    handleLeadSourceChange,
    editLead,
  };
};
