import { useEffect, useRef } from "react";
import "./ResizingTextarea.css"

const ResizingTextarea = ({ 
  className = "", 
  value = "",
  name = "",
  placeholder = "",
  rows = 1,
  handleChange = () => {},
}) => {
  const textareaRef = useRef(null);

  const autoResize = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      const maxRows = 9;
      const lineHeight = 24; // Adjust this if your font size/line height is different
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = maxRows * lineHeight;

      if (scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight === 0 ? 'auto' : maxHeight}px`;
        textarea.style.overflowY = "auto";
      } else {
        textarea.style.height = `${scrollHeight === 0 ? 'auto' : scrollHeight}px`;
        textarea.style.overflowY = "hidden";
      }
    }
  };

  const handleChangeTextarea = (e) => {
    handleChange(e)
    autoResize()
  }

  // Ejecutar autoResize cuando cambie el valor
  useEffect(() => {
    if (value?.trim()) autoResize();
  }, [value]);

  return (
    <>
      <textarea
        className={`itp-resizing-textarea ${className}`}
        ref={textareaRef}
        value={value}
        onChange={handleChangeTextarea}
        name={name}
        placeholder={placeholder}
        rows={rows}
      />
    </>
  )
};

export default ResizingTextarea;