import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileText, faRobot } from "@fortawesome/free-solid-svg-icons";
import { PaginationITP } from "../index";
import { fetchCallHistory } from "./services";
import { countryList } from "../../const/countryList";
import Skeleton from "react-loading-skeleton";
import getQueryString from "../../utils/getQueryString";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import "./ContactHistory.css";

const formatFieldName = (fieldName) => {
  if (!fieldName) return '';

  let formatted = fieldName.replace(/_/g, ' ').replace(/\?/g, ' ');

  const commonFields = {
    zipcode: 'Zip code',
    firstname: 'First name',
    lastname: 'Last name',
    phonenumber: 'Phone number',
    fullname: 'Full name',
    emailaddress: 'Email address',
    birthdate: 'Birth date',
    streetaddress: 'Street address',
    postalcode: 'Postal code',
  };

  return (
    commonFields[formatted.toLowerCase()] ||
    formatted.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, (c) => c.toUpperCase())
  );
};

const formatFieldValue = (field, value) => {
  if (!value) return '';

  let formattedValue = value.replace(/\?/g, ' ');

  const phoneFields = [
    'phone',
    'phonenumber',
    'phone_number',
    'cellphone',
    'mobile',
    'telephone',
    'tel',
    'fromnumber',
  ];

  if (phoneFields.includes(field.toLowerCase().replace(/[_\s]/g, ''))) {
    try {
      // Fix double plus signs in phone numbers (++ -> +)
      if (formattedValue.startsWith('++')) {
        formattedValue = formattedValue.replace('++', '+');
      }

      return formatPhoneNumber(formattedValue);
    } catch (error) {
      console.log('Error formatting phone number:', error);
      return formattedValue;
    }
  }

  // Format country values
  const countryFields = [
    'country',
    'country_code',
    'countrycode',
    'origin_country',
    'destination_country',
  ];

  if (countryFields.includes(field.toLowerCase().replace(/[_\s]/g, ''))) {
    // Try to find the country in the countryList
    const country = countryList.find(
      (c) =>
        c.value === formattedValue ||
        c.alpha2 === formattedValue ||
        c.alpha3 === formattedValue ||
        c.code === formattedValue
    );

    // If found, return the country name, otherwise return the original value
    return country ? country.name : formattedValue;
  }

  return formattedValue;
};

const formatReason = (reason, historyData) => {
  // Si reason es falso, verificamos si historyData existe y tiene campos
  if (!reason) {
    if (historyData && typeof historyData === "object" && Object.keys(historyData).length > 0) {
      // Lista de campos que deben ser formateados como números de teléfono
      const phoneNumberFields = [
        "dnis",
        "caller_id_number",
        "forwarded_to_number",
        "original_caller_id_number"
      ];
      
      return (
        <div>
          <p className="reason-message">
            <strong>Call Details</strong>
          </p>
          <div className="history-data">
            <ul>
              {Object.entries(historyData).map(([key, value], index) => {
                let displayValue = value;
                
                // Formatear específicamente los campos de teléfono
                if (phoneNumberFields.includes(key) && value) {
                  displayValue = formatPhoneNumber(value.toString());
                } else if (Array.isArray(value)) {
                  displayValue = value.map((v) => formatPhoneNumber(v)).join(', ');
                } else if (value) {
                  displayValue = formatFieldValue(key, value.toString());
                }
                
                return (
                  <li key={index}>
                    <strong>{formatFieldName(key)}</strong>:{' '}
                    {displayValue}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    } else {
      return <div className="no-data">No information found</div>;
    }
  }

  // Lógica existente para cuando reason tiene valor
  // Extraer mensaje antes de los cambios
  const reasonParts = reason.split('Fields changed: :\n');
  const message = reasonParts[0].trim();
  const changesText = reasonParts[1] || '';
  const changesArray = changesText.split('\n').filter(Boolean);

  return (
    <div>
      <p className="reason-message">
        <strong>{message}</strong>
      </p>

      {changesArray.length > 0 && (
        <ul className="reason-changes-list">
          {changesArray.map((change, index) => {
            if (change.includes(': ')) {
              const [field, value] = change.split(': ').map((s) => s.trim());
              return (
                <li key={index} className="reason-changes-item">
                  <strong>{formatFieldName(field)}</strong>: {formatFieldValue(field, value)}
                </li>
              );
            } else {
              return (
                <li key={index} className="reason-changes-item">
                  {change}
                </li>
              );
            }
          })}
        </ul>
      )}

      {historyData && Object.keys(historyData).length > 0 && (
        <div className="history-data">
          <p>
            <strong>Additional details:</strong>
          </p>
          <ul>
            {Object.entries(historyData).map(([key, value], index) => (
              <li key={index}>
                <strong>{formatFieldName(key)}</strong>:{' '}
                {Array.isArray(value) ? value.map((v) => formatPhoneNumber(v)).join(', ') : formatFieldValue(key, value?.toString())}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const ContactHistory = ({
  contactId,
  setFinishFetchCallHistory,
  loading,
  myExtension,
  onDataReceived,
  shouldFetch = true
}) => {
  const firstLoad = useRef(true);
  const pagination = useRef({ offset: 0, limit: 5 });

  const [data, setData] = useState([]);
  const [arrayHistory, setArrayHistory] = useState([{}, {}, {}, {}, {}]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(Math.floor(pagination.current.offset / pagination.current.limit) + 1);
  const [loadingPage, setLoadingPage] = useState(false);

  const petition = async (parameter) => {
    try {
      setFinishFetchCallHistory(true);
      const { processedHistory, result } = await fetchCallHistory(
        contactId,
        myExtension,
        parameter
      );
      
      // Pass the result data to parent component if onDataReceived prop exists
      if (onDataReceived && typeof onDataReceived === 'function') {
        onDataReceived(result);
      }
      
      firstLoad.current = false;
      const allPages = result?.total_pages || 1;
      setTotalPages(allPages);
      setData(processedHistory);
      setFinishFetchCallHistory(true);
      setLoadingPage(false);
    } catch (error) {
      console.log(error);
      setLoadingPage(false);
      setData([]);
    }
  };

  const handleChangePage = (page) => {
    if (loading || loadingPage) return;

    const newOffset = (page - 1) * pagination.current.limit;

    if (!loadingPage) {
      setLoadingPage(true);
      setArrayHistory([{}, {}, {}, {}, {}]);
      setCurrentPage(page);

      const paginationTemp = { offset: newOffset, limit: pagination.current.limit };
      const query = getQueryString(paginationTemp);
      petition(query);
    }
  };

  useEffect(() => {
    if (!loading && !loadingPage) setArrayHistory(data);
  }, [loading, data, loadingPage]);

  useEffect(() => {
    const query = getQueryString(pagination.current);
    // Fetch data if contactId exists and shouldFetch is true
    if (contactId && shouldFetch) {
      petition(query);
    }
  }, [contactId, shouldFetch]);

  return (
    <>
      {arrayHistory.map((element, i) => (
        <div key={element?.pk || i} className="itp-note-content">
          <div className="itp-note-content__title">
            <div
              className={`title-note-left-section ${
                loading || loadingPage ? 'd-flex align-items-center gap-1' : ''
              }`}
            >
              {loading || loadingPage ? (
                <>
                  <Skeleton height={10} width="90px" />
                </>
              ) : (
                <>
                  <span className="itp-note-text">{element?.formatedCreatedAt}</span>
                  {element?.was_ai && <FontAwesomeIcon icon={faRobot} />}
                </>
              )}
            </div>
          </div>

          <div className="itp-note-content__content">
            {!loading && !loadingPage && (
              <FontAwesomeIcon icon={faFileText} className="icon-text-note" />
            )}

            {loading || loadingPage ? (
              <Skeleton height={60} width="250px" />
            ) : (
              <div className="itp-contact-details-p">
                {formatReason(element.reason, element.history_data)}
              </div>
            )}
          </div>
        </div>
      ))}

      {arrayHistory.length > 0 && (
        <PaginationITP
          pages={totalPages}
          active={currentPage}
          setActive={(page) => handleChangePage(page)}
          loading={loading}
        />
      )}
    </>
  );
};

export default ContactHistory; 