import petitionGet from "../../../../../../services/petitionGet";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const fetchAssignedNumbers = async (setAssignedNumbers, setIsLoadingNumbers) => {
  try {
    const { data: result } = await petitionGet("assignedNumbers")

    const assignedNumbers = result.result.map((number) => {
      return { label: formatPhoneNumber(number.number), value: number.number }
    })

    setAssignedNumbers(assignedNumbers)
    setIsLoadingNumbers(false)
  } catch (error) {
    console.log(error)
  }
};

export default fetchAssignedNumbers;