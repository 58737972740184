import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LoadingButton } from '../index';

const ModalDelete = ({
  modal,
  closeModal,
  loading,
  modalFunction,
  text,
  title,
  noSaveButton,
  error,
  closeButtonName,
}) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title ? title : 'Delete Contact'}</ModalHeader>
      <ModalBody>
        {text ? text : 'Are You Sure?'}
        {error && <p className="text-danger">{error}</p>}
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          {!closeButtonName ? 'No' : closeButtonName}
        </button>
        {!noSaveButton && (
          <button disabled={loading} className="btn-primary loading" onClick={modalFunction}>
            {loading && <LoadingButton />}
            Yes
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
