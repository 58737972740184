import { useEffect, useRef, useState } from "react";
import { AlertNotificactions } from "../../../../../components";
import { editFunnelService } from "../../services";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";

const FunelData = ({ funnelDetails, priveLevel, loading, pk }) => {
  const currentForm = useRef(funnelDetails);

  const [showErrorTime, setShowErrorTime] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [form, setForm] = useState({
    name: "",
    description: "",
    lead_strategy: "",
    automatically_assign_lead: false,
  });

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
        >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleChangeLeadStrategy = async (e) => {
    const { name, value } = e.target;
    const newForm = { ...form, [name]: value };
    setForm(newForm);

    await editFunnelService(name, pk, currentForm, newForm, setForm, setAlertType, setAlertTitle, setAlertMessage, setShowErrorTime);
  };

  const handleChangeSwitch = async (field) => {
    const newForm = { ...form, [field]: !form[field] };
    setForm(newForm);

    await editFunnelService(field, pk, currentForm, newForm, setForm, setAlertType, setAlertTitle, setAlertMessage, setShowErrorTime);
  };

  const handleBlurInput = async (e) => {
    const field = e.target.name;
    
    await editFunnelService(field, pk, currentForm, form, setForm, setAlertType, setAlertTitle, setAlertMessage, setShowErrorTime);
  };

  useEffect(() => {
    setForm({
      name: funnelDetails.name || "",
      description: funnelDetails.description || "",
      lead_strategy: funnelDetails.lead_strategy || "",
      automatically_assign_lead:
        funnelDetails.automatically_assign_lead || false,
    });
    currentForm.current = funnelDetails;
  }, [funnelDetails]);

  return (
    <>
      <AlertNotificactions
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      {priveLevel !== "admin" ? (
        <h6 className="itp-funnel-details-title">
          {funnelDetails.name} : Funnel Details
        </h6>
      ) : (
        <>
          <div className="d-flex align-items-center gap-4 mb-3">
            <div className="d-flex flex-column mt-3">
              <label className="itp-edit-number-label-1">Funnel Name : </label>
            </div>
            {loading ? (
              <Skeleton height={50} width="12rem" />
            ) : (
              <input
                placeholder=": Enter a funnel name"
                className="form-control-itp itp-edit-number-input"
                name="name"
                value={form.name}
                onChange={handleChangeForm}
                onBlur={handleBlurInput}
              />
            )}
          </div>

          <div className="d-flex align-items-center gap-4 mb-3">
            <div className="d-flex flex-column mt-3">
              <label className="itp-edit-number-label-1">
                Funnel Description :{" "}
              </label>
            </div>
            {loading ? (
              <Skeleton height={50} width="12rem" />
            ) : (
              <input
                placeholder=": Enter a funnel description"
                className="form-control-itp itp-edit-number-input"
                name="description"
                value={form.description}
                onChange={handleChangeForm}
                onBlur={handleBlurInput}
              />
            )}
          </div>

          <div className="d-flex align-items-center gap-4 mb-3">
            <div className="d-flex flex-column mt-3">
              <label className="itp-edit-number-label-1">
                Lead Strategy :{" "}
              </label>
            </div>
            {loading ? (
              <Skeleton height={50} width="12rem" />
            ) : (
              <select
                placeholder=": Enter a funnel description"
                className="form-select-itp w-auto"
                name="lead_strategy"
                value={form.lead_strategy}
                onChange={handleChangeLeadStrategy}
              >
                <option value="" disabled>
                  Select Option
                </option>
                <option value="random">Random</option>
              </select>
            )}
          </div>

          <div className="d-flex align-items-center gap-4 mb-3">
            <div className="d-flex flex-column mt-3">
              <label className="itp-edit-number-label-1">
                Automatically Assign Lead :{" "}
              </label>
            </div>
            {loading ? (
              <Skeleton height={50} width="12rem" />
            ) : (
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => handleChangeSwitch("automatically_assign_lead")}
                checked={form.automatically_assign_lead}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FunelData;
