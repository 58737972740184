import React from "react";
import { FormGroup, Input } from "reactstrap";
import { CustomFields } from "../../index";

const CustomFieldsSection = ({
  isEditing,
  customFieldsRef,
  form,
  setForm,
  contactData,
  handleInputChange,
  myExtension,
  handleAddedCustomField,
  handleEditedCustomField,
  handleDeletedCustomField,
}) => {
  return (
    <div className="itp-custom-fields-section mt-4">
      <h6 className="mb-3">Custom Fields</h6>
      {isEditing ? (
        <CustomFields
          customFields={customFieldsRef}
          form={form}
          setForm={setForm}
          onchangeForm={handleInputChange}
          loading={false}
          myExtension={myExtension || { priv_level: "user" }}
          setAddedCustomField={handleAddedCustomField}
          setEditedCustomField={handleEditedCustomField}
          setDeletedCustomField={handleDeletedCustomField}
          customReload={false}
        />
      ) : (
        customFieldsRef.current.map((field, index) => (
          <FormGroup key={index}>
            <div className="itp-field-label">{field.label}</div>
            {field.field_type === "checkbox" ? (
              <Input
                type="text"
                disabled
                readOnly
                value={contactData[field.name] ? "Enabled" : "Disabled"}
              />
            ) : (
              <Input
                type="text"
                disabled
                readOnly
                value={
                  (contactData[field.name] !== undefined ? contactData[field.name] : "") || ""
                }
              />
            )}
          </FormGroup>
        ))
      )}
    </div>
  );
};

export default CustomFieldsSection; 