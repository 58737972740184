const generateDataTableTitles = (noViewColumns) => {
  return [
    {
      name: 'Contact Name',
      key: 'contactName',
      viewColumn: !noViewColumns.includes('Contact Name'),
    },
    {
      name: 'Contact Email',
      key: 'contactEmail',
      viewColumn: !noViewColumns.includes('Contact Email'),
    },
    {
      name: 'Lead Name',
      key: 'name',
      viewColumn: !noViewColumns.includes('Lead Name'),
    },
    {
      name: 'Lead Source',
      key: 'leadSource',
      viewColumn: !noViewColumns.includes('Lead Source'),
    },
    {
      name: 'Funnel',
      key: 'funnelName',
      viewColumn: !noViewColumns.includes('Funnel'),
    },
    {
      name: 'Funnel Stage',
      key: 'funnelStage',
      viewColumn: !noViewColumns.includes('Funnel Stage'),
    },
    {
      name: 'Lead Created Date',
      key: 'date_added',
      viewColumn: !noViewColumns.includes('Lead Created Date'),
    },
    {
      name: 'Assigned User',
      key: 'assignedUser',
      viewColumn: !noViewColumns.includes('Assigned User'),
    },
  ];
};

export default generateDataTableTitles;
