import petitionPost from "../../../../../../services/petitionPost";

const assignUserToAreaCodeService = async (form, area_code_id) => {
  const data = {
    user_id: form.user.value,
    number_id: form.number.value,
  };

  await petitionPost("areaCodeAssignment", { data, area_code_id });
};

export default assignUserToAreaCodeService;
