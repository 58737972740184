import React from "react";
import { FormGroup, Input, Spinner } from "reactstrap";
import { LeadSource } from "../../index";
import AsyncSelect from "react-select/async";
import Select from "react-select";

const LeadInfoSection = ({
  isEditing,
  leadForm,
  leadData,
  handleLeadInputChange,
  valueLeadSource,
  handleLeadSourceChange,
  valueFunnel,
  loadOptionFunnel,
  handleChangeFunnel,
  loadingFunnels,
  loadingStages,
  valueStage,
  stageOptions,
  handleChangeStage,
}) => {
  return (
    <div className="itp-lead-section mt-4 mb-4">
      <h6 className="mb-3">Lead Information</h6>

      {/* Using LeadSource component instead of custom select */}
      <LeadSource
        defaultLeadSource={valueLeadSource}
        onChange={(e) => handleLeadSourceChange(e)}
        isEditing={isEditing}
      />

      <FormGroup>
        <div className="itp-field-label">Lead Name</div>
        <Input
          type="text"
          name="name"
          placeholder="Enter a lead name"
          onChange={(e) => handleLeadInputChange(e)}
          value={isEditing ? leadForm.name || "" : leadData.name || ""}
          disabled={!isEditing}
        />
      </FormGroup>

      <FormGroup>
        <div className="itp-field-label">Amount</div>
        <Input
          type="text"
          name="amount"
          placeholder="Enter an amount"
          onChange={(e) => handleLeadInputChange(e)}
          value={isEditing ? leadForm.amount || "" : leadData.amount || ""}
          disabled={!isEditing}
        />
      </FormGroup>

      <FormGroup>
        <div className="itp-field-label">Funnel</div>
        {isEditing ? (
          <AsyncSelect
            onChange={(e) => handleChangeFunnel(e)}
            className="basic-single"
            classNamePrefix="select"
            value={valueFunnel}
            isSearchable={true}
            name="funnel_id"
            loadOptions={loadOptionFunnel}
            defaultOptions
            cacheOptions
            isLoading={loadingFunnels}
            placeholder="Choose funnel"
          />
        ) : (
          <Input type="text" disabled value={valueFunnel?.label || ""} />
        )}
      </FormGroup>

      {isEditing ? (
        loadingStages ? (
          <FormGroup>
            <div className="itp-field-label">Stage</div>
            <div className="d-flex align-items-center">
              <Spinner size="sm" className="me-2" /> Loading stages...
            </div>
          </FormGroup>
        ) : (
          valueFunnel && (
            <FormGroup>
              <div className="itp-field-label">Stage</div>
              {stageOptions.length > 0 ? (
                <Select
                  onChange={(e) => handleChangeStage(e)}
                  className="basic-single"
                  classNamePrefix="select"
                  value={valueStage}
                  isSearchable={true}
                  name="funnel_pipeline_stage_id"
                  options={stageOptions}
                  placeholder="Choose stage"
                />
              ) : (
                <div className="text-muted">No stages available for this funnel</div>
              )}
            </FormGroup>
          )
        )
      ) : (
        <FormGroup>
          <div className="itp-field-label">Stage</div>
          <Input type="text" disabled value={valueStage?.label || ""} />
        </FormGroup>
      )}
    </div>
  );
};

export default LeadInfoSection; 