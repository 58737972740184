import axios from "axios";
import json from "../config.json";

export default function petitionPut(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

  var url;
  var data;

  if (token) {
    switch (key) {
      case "uploadFile":
        url = urlBase + `/itpvoice/v2/${id}/media-groups/${value.media_group_id}`;
        data = value.data;
        break;
      case "operationPanelGroupsUsers":
        url = urlBase + `/itpvoice/v2/${id}/operator-panel/groups/${value.group_id}/users`;
        data = value.data;
        break;
      case "operationPanelGroupsQueue":
        url = urlBase + `/itpvoice/v2/${id}/operator-panel/groups/${value.group_id}/queues`;
        data = value.data;
        break;
      case "operationPanelGroupsPermissions":
        url = urlBase + `/itpvoice/v2/${id}/operator-panel/groups/${value.group_id}/permissions`;
        data = value.data;
        break;
      case "loadVoicemailNumber":
        url = urlBase + `/itpvoice/v2/${id}/numbers/${value.number}/voice-messages`;
        data = value.data;
        break;
      case "resources":
        url = urlBase + `/360/accounts/${id}/my-account/calendar/calendly-crm-resources`;
        data = value.data;
        break;
      case "vmGreeting":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/voicemail-box/unavail`;
        data = value.data;
        break;
      default:
        return "error";
    }
    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.put(url, data, config);
  } else {
    window.location.href = "./profile";
  }
}
