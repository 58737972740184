const downloadPDF = async (response, filename = 'report.pdf') => {
  const blob = new Blob([response.data], { type: 'application/pdf' });
  console.log(blob);

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadPDF;
