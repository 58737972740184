/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomTableMain, AlignedRow, BulkActions } from "../../../../components";
import { useDynamicFunction } from "../../../../contexts/DynamicFunctionContext";
import { applyBulkActionService, exportToCssService } from "../services";
import petitionGet from "../../../../services/petitionGet";
import getQueryString from "../../../../utils/getQueryString";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";
import addTag from "../../../../assets/icons/addTag.svg";
import doc from "../../../../assets/icons/document.svg";
import deleteIcon from "../../../../assets/icons/deleteIcon.svg";
import funnels from "../../../../assets/icons/funnels.svg";
import clipboardExport from "../../../../assets/icons/clipboardExport.svg"
import ModalWizzard from "../ModalUtility/ModalWizzard";

const ViewLogs = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get("pk");
  const name = searchParams.get("name");

  const { setDynamicFunction, setIsArrowVisible } = useDynamicFunction();
  const navigate = useNavigate();

  const filterStatus = useRef("");
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [loading, setLoading] = useState(true);
  const [allResult, setAllResult] = useState(null);
  const [valueLog, setValueLog] = useState("");
  const [loadingPagItem, setLoadingPagItem] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false)
  const [modalWizzard, setModalWizzard] = useState(false)
  const [bulkAction, setBulkAction] = useState("")
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Task Status", key: "formatStatus" },
      { name: "Task Priority", key: "task_priority" },
      { name: "Max Retries", key: "max_retries" },
      { name: "Recipient Number", key: "formatedNumber" },
      { name: "Schedule Start Time", key: "schedule_start_time" },
      { name: "Schedule End Time", key: "schedule_end_time" },
      { name: "Created At", key: "created_at" },
      { name: "Updated At", key: "updated_at" },
      { name: "Time Zone", key: "time_zone" },
    ],
  });

  const formatText = (text) => {
    switch (text) {
      case "sms":
        return "SMS Campaign";
      case "in_progress":
        return "In Progress";
      case "pending":
        return "Pending";
      case "completed":
        return "Completed";
      case "paused":
        return "Paused";
      case "starting":
        return "Starting";
      case "delivered":
        return "Delivered";
      case "dnd_sms":
        return "DND SMS";
      default:
        return text;
    }
  };

  const changePage = (offset) => {
    setLoadingPagItem(true);

    const paginationTemp = {
      offset,
      limit: pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp) + filterStatus.current;

    petition(parameter, true);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    console.log(data)
    setLoadingPagItem(true);

    const paginationTemp = {
      offset: 0,
      limit: data,
    };

    const parameter = getQueryString(paginationTemp) + filterStatus.current;

    petition(parameter, true);
    pagination.current = paginationTemp;
    itemsPerPage.current = data
  };

  const returnTextHover = (status) => {
    switch (status) {
      case "dnd_sms":
        return "Contact Opted Out, no message was sent";
      default:
        return "";
    }
  };

  const onChangeLogs = (e) => {
    if (loading || loadingPagItem) return;
    const { value } = e.target;

    setValueLog(value);

    setLoading(true);

    const paginationTemp = {
      offset: 0,
      limit: 10,
    };
    let status = "";

    if (value === "responded" || value === "not responded") {
      const query = value === "responded" ? "&contact_responded=true" : "&contact_responded=false";
      status = query;
    } else {
      status = value !== "" ? `&status=${value}` : "";
    }

    const parameter = getQueryString(paginationTemp) + status;

    petition(parameter, true);
    filterStatus.current = status;
    pagination.current = paginationTemp;
  };

  const closeModal = () => {
    setLoadingModal(false)
    setModalWizzard(false)
    setBulkAction("")
  }

  const activeModalWizzard = (action) => {
    setBulkAction(action)
    setModalWizzard(true)
  }

  const applyBulkAction = async (form, action) => {
    const query = filterStatus.current;
    await applyBulkActionService(form, action, query, pk, setLoadingModal, closeModal)
  }

  const exportToCsv = async () => {
    const query = filterStatus.current;
    await exportToCssService(query, pk, setLoadingModal, closeModal)
  }

  const modalFunction = (data) => {
    switch (bulkAction) {
      case "Add tags":
        applyBulkAction(data, "assign_contact_tag")
        break;
      case "Move contacts to list":
        applyBulkAction({ contact_list_id: data.pk }, "move_to_contact_list")
        break;
      case "Delete contacts":
        applyBulkAction({}, "delete_contacts")
        break;
      case "Move contacts to funnel":
        const form = {
          funnel_id: data.funnel_id.pk,
          funnel_pipeline_stage_id: data.funnel_pipeline_stage_id.pk,
          lead_source_id: data.lead_source_id.pk
        }
    
        applyBulkAction(form, "move_to_funnel")
        break;
      case "Export to csv":
        exportToCsv()
        break;
      default:
        break;
    }
  }

  const petition = (parameter, noLoading) => {
    if (!noLoading) setLoading(true);

    petitionGet("tasksSmartCampaign", { campaign_id: pk, parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element) => {
          element.formatStatus = formatText(element.status);
          element.keyHover = returnTextHover(element.status);
          element.formatedNumber = formatPhoneNumber(element.recipient_number);
        });

        setDataTable({
          columns: [
            { name: "Task Status", key: "formatStatus" },
            { name: "Task Priority", key: "task_priority" },
            { name: "Max Retries", key: "max_retries" },
            { name: "Recipient Number", key: "formatedNumber" },
            { name: "Schedule Start Time", key: "schedule_start_time" },
            { name: "Schedule End Time", key: "schedule_end_time" },
            { name: "Created At", key: "created_at" },
            { name: "Updated At", key: "updated_at" },
            { name: "Time Zone", key: "time_zone" },
          ],
          content: [...result.result],
        });

        setLoading(false);
        setLoadingPagItem(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (!pk || !name) {
      navigate("/Smart-Campaigns");
    } else {
      setDynamicFunction(() => () => {
        navigate("/Smart-Campaigns");
      });
      setIsArrowVisible(true);

      petition(getQueryString(pagination.current));
    }
  }, []);

  return (
    <>
      {modalWizzard && (
        <ModalWizzard 
          modal={modalWizzard}
          closeModal={closeModal}
          loading={loadingModal}
          modalActions={modalFunction}
          title={bulkAction}
          allResult={allResult}
          valueLog={valueLog}
          action={bulkAction}
        />
      )}

      <div style={{ marginTop: "3rem" }}>
        <AlignedRow noCentered m1={true}>
          <h3 className="itp-accordion-header-title">{name}</h3>
        </AlignedRow>

        <CustomTableMain
          data={dataTable}
          loading={loading}
          loadingPagItems={loadingPagItem}
          module="logs"
          isBackend={true}
          totalPages={allResult?.total_pages}
          onPageChange={changePage}
          offset={pagination.current.offset}
          showSelectSingleCheckbox={true}
          onChangeItemsPerPage={activeItemsPerPage}
          paginationData={parseInt(itemsPerPage.current)}
          noDataMessage={valueLog && !allResult.result.length ? "No results found" : ""}
          columnConfig={{
            "Task Status": {
              type: "hoverInfo",
              hoverKey: "keyHover",
            },
            "Created At": {
              type: "date",
              spliter: "T",
            },
            "Updated At": {
              type: "date",
              spliter: "T",
            },
          }}
          actionsLeft={[
            {
              component: (
                <BulkActions
                  className="mt-4"
                  actions={[
                    {
                      icon: <img src={addTag} alt="Add Tag" />,
                      name: "Add tags",
                      handleClick: () => activeModalWizzard("Add tags"),
                    },
                    {
                      icon: <img style={{ width: "16px", height: "16px" }} src={doc} alt="Document" />,
                      name: "Move contacts to list",
                      handleClick: () => activeModalWizzard("Move contacts to list"),
                    },
                    {
                      icon: <img src={deleteIcon} alt="Delete Contact" />,
                      name: "Delete contacts",
                      handleClick: () => activeModalWizzard("Delete contacts"),
                    },
                    {
                      icon: <img style={{ width: "16px", height: "16px" }} src={funnels} alt="Funnel"/>,
                      name: "Move contacts to funnel",
                      handleClick: () => activeModalWizzard("Move contacts to funnel"),
                    },
                    {
                      icon: <img style={{ width: "16px", height: "16px" }} src={clipboardExport} alt="Export" />,
                      name: "Export to csv",
                      handleClick: () => activeModalWizzard("Export to csv"),
                    },
                  ]}
                />
              ),
            },
            {
              component: (
                <select
                  onChange={onChangeLogs}
                  value={valueLog}
                  disabled={loading || loadingPagItem}
                  className="form-select-itp itp-call-logs-select"
                >
                  <option value="">All Logs</option>
                  <option value="pending">Pending</option>
                  <option value="delivered">Delivered</option>
                  <option value="failed">Failed</option>
                  <option value="responded">Contact Responded</option>
                  <option value="not responded">
                    No Response from Conctact
                  </option>
                </select>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default ViewLogs;
