/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { ChartLine, CustomTableMain, DateReportCalls, AlignedRow, DropdownButton } from "../../../../../components";
import { downloadCallQueueMemberLogs, numberReportService, queueLogsService, queueMemberLogsService, queueMemberReportService, queueReportService, smsReportService, userReportService } from "../../services";
import { selectDefaultColumns } from "../../const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { getExportReportKey, selectTimeStartAndEnd } from "../../utils";
import Skeleton from "react-loading-skeleton";
import useScreenSize from "../../../../../hooks/useScreenSize";
import view from "../../../../../assets/icons/view.svg";
import callCalling from "../../../../../assets/icons/callCalling.svg";
import ViewLogs from "./ViewLogs";
import CallEvents from "./CallEvents";
import ModalConfirm from "../ModalUtility/ModalConfirm";
import petitionPost from "../../../../../services/petitionPost";
import downloadCSV from "../../../../../utils/downloadCSV";
import downloadPDF from "../../../../../utils/downloadPDF";
import "./DataReport.css";

const DataReport = ({
  selectedReport,
  selectedUsers,
  selectedNumbers,
  selectedQueuesReport,
  selectedQueueMembersReport,
  timeframe,
  viewLogs,
  setViewLogs,
  activeViewLogs,
  loadingCall,
  timeZone,
  completedLogs,
  callEvents,
  setCallEvents,
  totalUsers,
  totalQueues,
  myExtension,
  setModalError,
  setLoadingCall,
}) => {
  const From = `${timeframe.timeStart.split("-")[1]}-${timeframe.timeStart.split("-")[2]}-${timeframe.timeStart.split("-")[0]}`;
  const to = `${timeframe.timeEnd.split("-")[1]}-${timeframe.timeEnd.split("-")[2]}-${timeframe.timeEnd.split("-")[0]}`;
  const arrayNotShowGraphics = ["Queues", "Queue Members", "SMS"]

  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const itemsPerPage = useRef("10");
  const itemsPerPageFrontEnd = useRef("10");
  const currentDataQueueLog = useRef(null);
  const filterDataQueueLog = useRef("");
  const firstLoad = useRef(true)

  filterDataQueueLog.current = "&outcome=Answered Calls";

  const { width } = useScreenSize()

  const [loading, setLoading] = useState(true);
  const [totalCalls, setTotalCalls] = useState(0);
  const [xLabel, setXLabel] = useState([]);
  const [yLabel, setYLabel] = useState([]);

  const [xlabelQueueReportPerHour, setXlabelQueueReportPerHour] = useState([]);
  const [ylabelQueueReportPerHour, setYlabelQueueReportPerHour] = useState([]);
  const [xlabelQueueReportDay, setXlabelQueueReportDay] = useState([]);
  const [ylabelQueueReportDay, setYlabelQueueReportDay] = useState([]);
  const [xlabelQueueReportPerMonth, setXlabelQueueReportPerMonth] = useState([]);
  const [ylabelQueueReportPerMonth, setYlabelQueueReportPerMonth] = useState([]);
  const [xlabelQueueReportPerYear, setXlabelQueueReportPerYear] = useState([]);
  const [ylabelQueueReportPerYear, setYlabelQueueReportPerYear] = useState([]);
  const [loadingViewLogs, setLoadingViewLogs] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Answered Calls");
  const [loadingTable, setLoadingTable] = useState(false);
  const [showCallTranscription, setShowCallTranscription] = useState(null)
  const [modalConfirm, setModalConfirm] = useState(false);
  const [exportType, setExportType] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);

  const [dataTable, setDataTable] = useState({
    columns: selectDefaultColumns[selectedReport],
    actions: { title: "Actions", content: [] },
  });

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const closeModal = () => {
    setModalConfirm(false);
    setExportType("");
    setLoadingModal(false);
  }

  const handleExport = (type) => {
    setModalConfirm(true);
    setExportType(type);
  }

  const getExportPayload = () => {
    let data = {};

    switch (selectedReport) {
      case 'Users':
        data = { users: selectedUsers.map((user) => user.pk) };
        return data;
      case 'Numbers':
        data = { numbers: selectedNumbers.map((number) => number.number) };
        return data;
      case 'Queues':
        data = { queues: selectedQueuesReport.map((element) => element.pk) };
        return data;
      case 'Queue Members':
        const queues = selectedQueuesReport.map((queue) => queue.pk.toString());
        data = { agents: selectedQueueMembersReport.map((element) => `${element.pk}`), queues };
        return data;
      case 'SMS':
        data = { phone_numbers: selectedNumbers.map((number) => number.number) };
        return data;
      default:
        break;
    }
  };

  const activeItemsPerPageQueueLogs = (data) => {
    setLoadingTable(true);

    const paginationTemp = { offset: 0, limit: parseInt(data) };

    petitionQueueLogs(currentDataQueueLog.current, paginationTemp, filterDataQueueLog.current, true, true );
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const activeItemsPerPageQueueMemberLogs = (data) => {
    setLoadingTable(true);

    const paginationTemp = { offset: 0, limit: parseInt(data) };

    petitionQueueMemberLogs(currentDataQueueLog.current, paginationTemp, filterDataQueueLog.current, true, true);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const changePageQueueLogs = (offset) => {
    setLoadingTable(true)

    const paginationTemp = {
      offset: offset,
      limit: pagination.current.limit,
    };

    petitionQueueLogs(currentDataQueueLog.current, paginationTemp, filterDataQueueLog.current, true);
    pagination.current = paginationTemp;
  };

  const changePageQueueMemberLogs = (offset) => {
    setLoadingTable(true)

    const paginationTemp = {
      offset: offset,
      limit: pagination.current.limit,
    };

    petitionQueueMemberLogs(currentDataQueueLog.current, paginationTemp, filterDataQueueLog.current, true);
    pagination.current = paginationTemp;
  };

  const onChangeFilterqueueLog = (value) => {
    setLoadingFilter(true);
    const paginationTemp = {
      offset: 0,
      limit: 10,
    };

    let parameterFilter = "";

    if (value !== "all") {
      parameterFilter = `&outcome=${value}`;
    }

    petitionQueueLogs(currentDataQueueLog.current, paginationTemp, parameterFilter, true);
    pagination.current = paginationTemp;
    filterDataQueueLog.current = parameterFilter;
  };

  const onChangeFilterqueueMemberLogs = (value) => {
    setLoadingFilter(true);
    const paginationTemp = {
      offset: 0,
      limit: 10,
    };

    let parameterFilter = "";

    if (value !== "all") {
      parameterFilter = `&outcome=${value}`;
    }
    petitionQueueMemberLogs(currentDataQueueLog.current, paginationTemp, parameterFilter, true);
    pagination.current = paginationTemp;
    filterDataQueueLog.current = parameterFilter;
  };

  const handleChangeItemsPerPage = (data) => {
    itemsPerPageFrontEnd.current = data
    forceUpdate()
  }

  const activeCallEvents = (e) => {
    setCallEvents(e)
  }

  const selectReloadPetition = () => {
    if (selectedReport === "Queues") {
      petitionQueueLogs(currentDataQueueLog.current, pagination.current, filterDataQueueLog.current)
    } else if (selectedReport === "Queue Members") {
      petitionQueueMemberLogs(currentDataQueueLog.current, pagination.current, filterDataQueueLog.current)
    }
  }

  const downloadCall = async (e, key) => {
    await downloadCallQueueMemberLogs(e, setLoadingCall, setModalError, key)
  }

  const activeCallTranscription = (e) => {
    if (e?.callTranscription) {
      setShowCallTranscription(e)
    }
  }

  const exportReport = async () => {
    if (loadingModal) return;

    try {
      setLoadingModal(true);

      const data = getExportPayload()
      const key = getExportReportKey(selectedReport)
      const { timeStart, timeEnd } = selectTimeStartAndEnd(selectedReport, timeframe);

      let responseType = null;

      if (exportType === "pdf") {
        responseType = "blob";
      }

      const response = await petitionPost(key, { data, exportType, starDate: timeStart, endDate: timeEnd }, responseType);

      console.log(response);

      if (exportType === 'csv') {
        downloadCSV(response.data);
      } else if (exportType === 'pdf') {
        await downloadPDF(response);
      }

      closeModal();
    } catch (error) {
      console.log(error)
    }
  }

  const petitionQueueLogs = async (e, pagination, filter, noLoading, isChageItems) => {
    if (firstLoad.current) {
      setViewLogs({
        columns: [
          { name: "Date", key: "dateFormated" },
          { name: "Caller ID", key: "formatedNumber" },
          { name: "Contact", key: "contactName" },
          { name: "Agent", key: "agentName" },
          { name: "Wait Time", key: "wait_time" },
          { name: "Duration", key: "duration" },
          { name: "Outcome", key: "outcome" },
        ],
        actions: { title: "Actions", content: [] }
      });
    }

    if (!noLoading) setLoadingViewLogs(true);

    try {
      const logs = await queueLogsService(e, pagination, timeframe, isChageItems, setAllResult, filter, timeZone)

      setViewLogs({
        columns: [
          { name: "Date", key: "dateFormated" },
          { name: "Caller ID", key: "formatedNumber" },
          { name: "Contact", key: "contactName" },
          { name: "Agent", key: "agentName" },
          { name: "Wait Time", key: "wait_time" },
          { name: "Duration", key: "duration" },
          { name: "Outcome", key: "outcome" },
        ],
        content: [...logs],
        actions: { 
          title: "Actions", 
          content: [
            {
              name: "Call Events",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={callCalling}
                  alt=""
                />
              ),
              handleClick: activeCallEvents,
            },
            {
              name: "Download recording",
              icon: <FontAwesomeIcon icon={faDownload} />,
              handleClick: (e) => downloadCall(e, "callid"),
              canBeDisabled: true,
              keyDisabled: "call_recording_filename",
              hoverDisabledAction: true,
              textHover: "This call was not recorded or no longer available",
            },
            {
              name: "View transcription",
              icon: <FontAwesomeIcon icon={faEye} />,
              handleClick: activeCallTranscription,
              canBeDisabled: true,
              keyDisabled: "callTranscription",
              hoverDisabledAction: true,
              textHover: "This transcription is not available",
            },
          ] 
        },
        changePage: changePageQueueLogs,
        itemsPerPage: activeItemsPerPageQueueLogs,
      });

      setLoadingFilter(false);
      setLoadingViewLogs(false);
      setLoadingTable(false);
      firstLoad.current = false
      currentDataQueueLog.current = e;
    } catch (error) {
      console.log(error)
      setLoadingViewLogs(false);
      setLoadingFilter(false);
    }
  };

  const petitionQueueMemberLogs = async (e, pagination, filter, noLoading, isChageItems) => {
    if (firstLoad.current) {
      setViewLogs({
        columns: [
          { name: "Date", key: "dateFormated" },
          { name: "Caller ID", key: "formatedNumber" },
          { name: "Contact", key: "contactName" },
          { name: "Queue Name", key: "queue_name" },
          { name: "Wait Time", key: "wait_time" },
          { name: "Duration", key: "duration" },
          { name: "Outcome", key: "outcome" },
        ],
        actions: { title: "Actions", content: [] }
      });
    }

    if (!noLoading) setLoadingViewLogs(true);

    try {
      const logs = await queueMemberLogsService(e, setSelectedFilter, filter, pagination, timeframe, setAllResult, isChageItems, timeZone, selectedQueuesReport)

      setViewLogs({
        columns: [
          { name: "Date", key: "dateFormated" },
          { name: "Caller ID", key: "formatedNumber" },
          { name: "Contact", key: "contactName" },
          { name: "Queue Name", key: "queue_name" },
          { name: "Wait Time", key: "wait_time" },
          { name: "Duration", key: "duration" },
          { name: "Outcome", key: "outcome" },
        ],
        content: [...logs],
        actions: { 
          title: "Actions", 
          content: [
            {
              name: "Call Events",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={callCalling}
                  alt=""
                />
              ),
              handleClick: activeCallEvents,
            },
            {
              name: "Download recording",
              icon: <FontAwesomeIcon icon={faDownload} />,
              handleClick: downloadCall,
              canBeDisabled: true,
              keyDisabled: "call_recording_filename",
              hoverDisabledAction: true,
              textHover: "This call was not recorded or no longer available",
            },
            {
              name: "View transcription",
              icon: <FontAwesomeIcon icon={faEye} />,
              handleClick: activeCallTranscription,
              canBeDisabled: true,
              keyDisabled: "callTranscription",
              hoverDisabledAction: true,
              textHover: "This transcription is not available",
            },
          ] 
        },
        changePage: changePageQueueMemberLogs,
        itemsPerPage: activeItemsPerPageQueueMemberLogs,
      });

      setLoadingFilter(false);
      setLoadingViewLogs(false);
      setLoadingTable(false);
      firstLoad.current = false
      currentDataQueueLog.current = e;
    } catch (error) {
      console.log(error)
      setLoadingViewLogs(false);
      setLoadingFilter(false);
    }
  };

  const petitionUserReport = async () => {
    setLoading(true);

    try {
      const report = await userReportService(selectedUsers, timeframe, timeZone, setTotalCalls, setXLabel, setYLabel)

      setDataTable({
        columns: [
          { name: "Name", key: "user" },
          { name: "Total Calls", key: "total_calls" },
          { name: "Completed Calls", key: "completed" },
          { name: "Missed Calls", key: "missed" },
          { name: "Missed %", key: "missed_percentage" },
          { name: "Total Duration", key: "duration" },
          { name: "Average Duration", key: "avg_duration" },
        ],
        content: [...report],
        actions: {
          title: "Actions",
          content: [
            {
              name: "View Call logs",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={view}
                  alt=""
                />
              ),
              handleClick: (e) => activeViewLogs(e),
            },
          ],
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const petitionNumbersReport = async () => {
    setLoading(true);

    try {
      const report = await numberReportService(selectedNumbers, timeframe, timeZone, setTotalCalls, setXLabel, setYLabel)

      setDataTable({
        columns: [
          { name: "Number", key: "formatedNumber" },
          { name: "Total Calls", key: "total_calls" },
          { name: "Completed Calls", key: "completed" },
          { name: "Missed Calls", key: "missed" },
          { name: "Missed %", key: "missed_percentage" },
          { name: "Total Duration", key: "duration" },
          { name: "Average Duration", key: "avg_duration" },
        ],
        content: [...report],
        actions: {
          title: "Actions",
          content: [
            {
              name: "View Call logs",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={view}
                  alt=""
                />
              ),
              handleClick: (e) => activeViewLogs(e),
            },
          ],
        },
      });

      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const petitionQueueReports = async () => {
    setLoading(true);

    try {
      const { report, countingTotalCalls, objectHoursPerHour, ObjectCallsPerHour, objectHoursPerDay, ObjectCallsPerDay, objectHoursPerMonth, ObjectCallsPerMonth, objectHoursPerYear, ObjectCallsPerYear } = await queueReportService(selectedQueuesReport, timeframe)

      setTotalCalls(countingTotalCalls);
      setXlabelQueueReportPerHour(objectHoursPerHour);
      setYlabelQueueReportPerHour(ObjectCallsPerHour);

      setXlabelQueueReportDay(objectHoursPerDay);
      setYlabelQueueReportDay(ObjectCallsPerDay);

      setXlabelQueueReportPerMonth(objectHoursPerMonth);
      setYlabelQueueReportPerMonth(ObjectCallsPerMonth);

      setXlabelQueueReportPerYear(objectHoursPerYear);
      setYlabelQueueReportPerYear(ObjectCallsPerYear);

      setDataTable({
        columns: [
          { name: "Queue Name", key: "queueName" },
          { name: "Total Calls", key: "total_calls" },
          { name: "Answered Calls", key: "answered_calls" },
          { name: "Abandoned Calls", key: "abandoned_calls" },
          { name: "Missed Calls", key: "missed_calls" },
          { name: "Avg Call Duration", key: "avg_call_duration" },
          { name: "Avg Hold Time", key: "avg_hold_time" },
        ],
        content: [...report],
        actions: {
          title: "Actions",
          content: [
            {
              name: "View Call logs",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={view}
                  alt=""
                />
              ),
              handleClick: (e) => petitionQueueLogs( e, pagination.current, filterDataQueueLog.current)
            },
          ],
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const petitionQueueMembers = async () => {
    setLoading(true);

    try {
      const report = await queueMemberReportService(selectedQueueMembersReport, selectedQueuesReport, timeframe, setTotalCalls)

      setDataTable({
        columns: [
          { name: "User", key: "userName" },
          { name: "Total Calls", key: "total_calls" },
          { name: "Answered Calls", key: "answered_calls" },
          { name: "Missed Calls", key: "missed_calls" },
          { name: "Avg Call Duration", key: "avg_call_duration" },
        ],
        content: [...report],
        actions: {
          title: "Actions",
          content: [
            {
              name: "View Call logs",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={view}
                  alt=""
                />
              ),
              handleClick: (e) => petitionQueueMemberLogs(e, pagination.current, filterDataQueueLog.current),
            },
          ],
        },
      });

      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const petitionSMSReport = async () => {
    try {
      setLoading(true);

      const report = await smsReportService(selectedNumbers, timeframe)

      setDataTable({
        columns: selectDefaultColumns[selectedReport],
        content: [...report],
      });

      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!viewLogs) {
      pagination.current = {
        offset: 0,
        limit: 10,
      };

      itemsPerPage.current = "10";
    }
  }, [viewLogs]);

  useEffect(() => {
    switch (selectedReport) {
      case "Users":
        petitionUserReport();
        break;
      case "Numbers":
        petitionNumbersReport();
        break;
      case "Queues":
        petitionQueueReports();
        break;
      case "Queue Members":
        petitionQueueMembers();
        break;
      case "SMS":
        petitionSMSReport();
        break;
      default:
        break;
    }
  }, []);

  return (
    <div style={{ marginTop: "3rem" }}>
      {(viewLogs || loadingViewLogs) && !callEvents && (
        <div style={{ marginTop: "3rem" }}>
          <ViewLogs
            data={viewLogs}
            loadingCall={loadingCall}
            From={From}
            to={to}
            completedLogs={completedLogs}
            selectedReport={selectedReport}
            loading={loadingViewLogs}
            onChangeFilterqueueLog={onChangeFilterqueueLog}
            onChangeFilterqueueMemberLogs={onChangeFilterqueueMemberLogs}
            currentDataQueueLog={currentDataQueueLog.current}
            allResult={allResult}
            loadingFilter={loadingFilter}
            selectedFilter={selectedFilter}
            itemsPerPage={itemsPerPage}
            loadingTable={loadingTable}
            pagination={pagination}
            myExtension={myExtension}
            reloadDataTableLogs={selectReloadPetition}
            showCallTranscription={showCallTranscription}
            setShowCallTranscription={setShowCallTranscription}
          />
        </div>
      )}

      {callEvents && !loadingViewLogs && (
        <CallEvents  
          details={callEvents}
          timeZone={timeZone}
          totalUsers={totalUsers}
          totalQueues={totalQueues}
          selectedReport={selectedReport}
        />
      )}

      {modalConfirm && (
        <ModalConfirm
          modal={modalConfirm}
          closeModal={closeModal}
          text={`export to ${exportType}`}
          loading={loadingModal}
          modalFunction={exportReport}
        />
      )}

      {!viewLogs && !loadingViewLogs && !callEvents && (
        <div>
          <div className={selectedReport === "Queue Members" ? "mb-5" : ""}>
            <CustomTableMain 
              data={dataTable} 
              loading={loading}
              module="reports"
              m3={true}
              showSelectSingleCheckbox={true}
              paginationData={parseInt(itemsPerPageFrontEnd.current)}
              onChangeItemsPerPage={handleChangeItemsPerPage}
              responsive={width <= 1182 ? true : false}
              responsiveActionsClassMenu="itp-responsive-table-action-dropdown"
              actionsLeft={[
                { 
                  component: (
                    <DropdownButton
                      name="Export to"
                      actions={[
                        { name: "CSV", handleClick: () => handleExport("csv") },
                        { name: "PDF", handleClick: () => handleExport("pdf") },
                      ]}
                    />
                  )
                }
              ]}
              actionsRight={[
                {
                  component: (
                    <DateReportCalls 
                      reportDateText="Report Date :"
                      from={From}
                      to={to}
                      totalCallsText={selectedReport === "SMS" ? "" : "Total Calls :"}
                      totalCalls={totalCalls}
                      loadingTotalCalls={loading}
                    />
                  )
                }
              ]} 
            />
          </div>
     
          {!arrayNotShowGraphics.includes(selectedReport) && (
            <AlignedRow noCentered className="mb-4">
              <Card>
                <CardBody>
                  <div className="grphic-title">
                    <span className="itp-label-input__label">
                      Call Volume per Hour
                    </span>
                  </div>
                  <div>
                    {loading ? (
                      <Skeleton height={350} width="100%" />
                    ) : (
                      <ChartLine xLabel={xLabel} yLabel={yLabel} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </AlignedRow>
          )}
     
          {selectedReport === "Queues" && (
            <>
              <AlignedRow noCentered className="mb-4">
                <Card>
                  <CardBody>
                    <div className="grphic-title">
                      <span className="itp-label-input__label">
                        Call Volume per Hour
                      </span>
                    </div>
                    <div>
                      {loading ? (
                        <Skeleton height={350} width="100%" />
                      ) : (
                        <ChartLine
                          xLabel={xlabelQueueReportPerHour}
                          yLabel={ylabelQueueReportPerHour}
                          sCustomized={false}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AlignedRow>
     
              <AlignedRow noCentered className="mb-4">
                <Card>
                  <CardBody>
                    <div className="grphic-title">
                      <span className="itp-label-input__label">
                        Call Volume per Day
                      </span>
                    </div>
                    <div>
                      {loading ? (
                        <Skeleton height={350} width="100%" />
                      ) : (
                        <ChartLine
                          xLabel={xlabelQueueReportDay}
                          yLabel={ylabelQueueReportDay}
                          isCustomized={false}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AlignedRow>
     
              <AlignedRow noCentered className="mb-4">
                <Card>
                  <CardBody>
                    <div className="grphic-title">
                      <span className="itp-label-input__label">
                        Call Volume per Month
                      </span>
                    </div>
                    <div>
                      {loading ? (
                        <Skeleton height={350} width="100%" />
                      ) : (
                        <ChartLine
                          xLabel={xlabelQueueReportPerMonth}
                          yLabel={ylabelQueueReportPerMonth}
                          isCustomized={false}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AlignedRow>
     
              <AlignedRow noCentered className="mb-4">
                <Card>
                  <CardBody>
                    <div className="grphic-title">
                      <span className="itp-label-input__label">
                        Call Volume per Year
                      </span>
                    </div>
                    <div>
                      {loading ? (
                        <Skeleton height={350} width="100%" />
                      ) : (
                        <ChartLine
                          xLabel={xlabelQueueReportPerYear}
                          yLabel={ylabelQueueReportPerYear}
                          isCustomized={false}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AlignedRow>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DataReport;
