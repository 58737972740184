import { useState } from "react"
import "./HoverInfo.css"

const HoverInfo = ({ element, config, column }) => {
  const rowData = element[column.key]
  const hoverInfo = element[config?.hoverKey]
  const text =   config?.alternativeKey && element[config?.alternativeKey] ? config?.alternativeText : config?.text || ""
  const styles = element[config?.keyStyles] || {};
  const hideTextKey = element[config?.hideTextKey]
  const downTextKey = element[config?.downTextKey]

  const [stylePosition, setStylePosition] = useState({})

  const handleMouseEnter = (event) => {    
    const togglePosition = event.currentTarget.getBoundingClientRect();
    const top = togglePosition.top;
    
    setStylePosition({ position: "fixed", top: `${top - 40}px`, zIndex: 99999 })
  };

  const handleClick = () => {
    if (typeof config?.clickRow === "function") {
      config?.clickRow(element)
    }
  }

  return (
    <div
      className={`itp-hoverInfo-container ${
        typeof config?.clickRow === "function" ? "click-row" : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
    >
      {hoverInfo && (
        <div style={stylePosition} className="itp-hoverInfo-content">
          <div className="d-flex gap-1 w-100 justify-content-center align-items-center">
            {text && !hideTextKey && (
              <div>
                <p className="itp-hoverInfo-p">{text}</p>
              </div>
            )}

            <span>{hoverInfo}</span>
          </div>
          {downTextKey && (
            <>
              <span className="itp-hoverInfo-p">{downTextKey}</span>
            </>
          )}
        </div>
      )}
      <span
        style={typeof styles === "object" ? styles : {}}
        className="itp-hoverInfo-rowData"
      >
        {rowData}
      </span>
    </div>
  );
}

export default HoverInfo;