import React from "react";
import { FormGroup, Input } from "reactstrap";
import Select from "react-select";
import { orderedCountryList } from "../../../const/countryList";

/**
 * Component for displaying and editing location information
 * Modified to work with external label and icon
 */
const LocationSection = ({
  isEditing,
  form,
  contactData,
  handleInputChange,
  countryValue,
  setCountryValue,
  showLabel = true, // Optional flag to show/hide the label
}) => {
  return (
    <FormGroup className="mb-0">
      {showLabel && <div className="itp-field-label">Location</div>}
      {isEditing ? (
        <div className="row g-2">
          <div className="col-12 mt-2">
            <Select
              onChange={(selected) => {
                setCountryValue(selected);
                handleInputChange({
                  target: {
                    name: 'country',
                    value: selected?.value || '',
                  },
                });
              }}
              className="basic-single"
              classNamePrefix="select"
              value={countryValue}
              isSearchable={true}
              name="country"
              options={orderedCountryList}
              placeholder="Select a Country"
            />
          </div>
          <div className="col-6">
            <Input
              type="text"
              name="state"
              placeholder="State"
              value={form.state || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6">
            <Input
              type="text"
              name="city"
              placeholder="City"
              value={form.city || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 mt-2">
            <Input
              type="text"
              name="address"
              placeholder="Address"
              value={form.address || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 mt-2">
            <Input
              type="text"
              name="zipcode"
              placeholder="Zip Code"
              value={form.zipcode || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
      ) : (
        <Input
          type="text"
          disabled
          onChange={handleInputChange}
          value={`${countryValue?.label || ''}, ${contactData.state || ''}, ${contactData.city || ''}, ${contactData.address || ''}${contactData.zipcode ? ', ' + contactData.zipcode : ''}`}
          className="pe-4"
        />
      )}
    </FormGroup>
  );
};

export default LocationSection; 