import React, { Fragment } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

const SelectComponent = ({
  onChange,
  includeOption,
  defaultValue,
  placeholder,
  options,
  styles,
  delimiter,
  isSearchable,
  name,
  functionOption,
  asyncSelectOptions,
  loadOptions,
  className,
}) => {
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <div className="d-flex justify-content-between">
        {props.data.label}
        <div
          className="icon-hover"
          onClick={(e) => functionOption(e, props.data)}
        >
          {props.data.icon}
        </div>
      </div>
    </Option>
  );

  const asyncOptions = (inputValue, callback) => {
    loadOptions(inputValue, callback)
  }

  return (
    <Fragment>
      {includeOption ? (
        <>
          {asyncSelectOptions ? (
            <>
              <AsyncSelect
                onChange={(e) => onChange(e)}
                className={`basic-single ${className || ""}`}
                classNamePrefix="select"
                value={defaultValue}
                isSearchable={isSearchable}
                placeholder={placeholder}
                styles={styles}
                delimiter={delimiter}
                loadOptions={asyncOptions}
                options={options}
                components={{ Option: IconOption }}
                defaultOptions
                cacheOptions
              />
            </>
          ) : (
            <>
              <Select
                onChange={(e) => onChange(e)}
                className={`basic-single ${className || ""}`}
                classNamePrefix="select"
                value={defaultValue}
                isSearchable={isSearchable}
                placeholder={placeholder}
                name={name}
                options={options}
                styles={styles}
                delimiter={delimiter}
                components={{
                  Option: IconOption,
                }}
              />
            </>
          )}
        </>
      ) : (
        <>
          {asyncSelectOptions}
          <Select
            onChange={(e) => onChange(e)}
            className="basic-single"
            classNamePrefix="select"
            value={defaultValue}
            isSearchable={isSearchable}
            placeholder={placeholder}
            name={name}
            options={options}
            styles={styles}
            delimiter={delimiter}
          />
        </>
      )}
    </Fragment>
  );
};

export default SelectComponent;
