import React, { useState, useEffect } from "react";
import SipRegistration from "./SipRegistration";
import inviteCall from "./call";
import {
  SessionState
} from "sip.js";

function SipComponent({
  sipconfiguration,
  reject,
  setReject,
  call,
  setCall,
  bye,
  setBye,
  byeOnCall,
  setByeOnCall,
  numberToCall,
  setNumberTocall,
  answer,
  setAnswer,
  setAnswerCount,
  setCallSessions,
  callSessions,
  answerCount,
  handleAnswerOnInvite,
  sessionIncall,
  agent,
  setAgent,
  setCallSessionsFunctionEnd,
  callAudio,
  setCallAudio,
  ringtone,
  enablePhone,
  rinding,
  setConnecting
}) {
  const [callOnInvite, setCallOnInvite] = useState(0);
  // const [invitationSession, setInvitationSession] = useState(null);
  // const [callAudio] = useState(new Audio());
  useEffect(() => {
    console.log("Call Session callOnInvite()", callSessions)
  }, [callSessions])

  useEffect(() => {
    if (reject > 0) {
      handleReject();
    }
  }, [reject]);

  const handleReject = () => {
    // console.log("Llamada rechazada");
    // console.log(invitationSession);
    callSessions[callSessions.length - 1].reject();
    setReject(0);
  };

  useEffect(() => {
    if (call > 0) {
      handleCall();
    }
  }, [call]);

  const handleCall = () => {
    inviteCall(rinding, numberToCall, sipconfiguration, setCallSessions, agent, callSessions, callAudio, sessionIncall, handleAnswerOnInvite, setCallSessionsFunctionEnd, false,setConnecting, setNumberTocall)
    setCall(0);
  };



  useEffect(() => {
    if (bye > 0) {
      handleBye();
    }
  }, [bye]);

  useEffect(() => {
    if (byeOnCall > 0) {
      handleByeOnCall();
    }
  }, [byeOnCall]);

  const handleBye = () => {
    console.log(callSessions[callSessions.length - 1])
    console.log("2222222222222222222222")

    if (callSessions.length > 0) {
      callSessions.map((element) => {
        switch (element.state) {
          case SessionState.Initial:
            console.log("1111111111111111111ttt")

            element.reject();
            break;
          case SessionState.Establishing:
            console.log("222222222222222222ttt")

            element.cancel();
            break;
          case SessionState.Established:
            console.log("333333333333333333333333ttt")
            element.bye();
            break;
          case SessionState.Terminating:
          case SessionState.Terminated:
            break;
          default:
            break;
        }
      });

      // callSessions[callSessions.length -1].bye();
      setBye(0);
    }

  };

  const handleByeOnCall = () => {
    console.log(sessionIncall[sessionIncall.length - 1])

    if (sessionIncall.length > 0) {
      sessionIncall.map((element) => {
        switch (element.state) {
          case SessionState.Initial:
            element.reject();
            break;
          case SessionState.Establishing:
            element.cancel();
            break;
          case SessionState.Established:
            element.bye();
            break;
          case SessionState.Terminating:
          case SessionState.Terminated:
            break;
          default:
            break;
        }
      });

      // callSessions[callSessions.length -1].bye();
      setByeOnCall(0);
    }

  };

  // const setCallSessionsFunction = (session)=>{
  //   setCallSessions([...callSessions, session])
  //   setCallOnInvite(callOnInvite+1)
  //   console.log("------------------------->1",callSessions)
  //   console.log("------------------------->2",session)
  //   console.log("------------------------->3",callOnInvite)
  //   if(callSessions >= 1){
  //     session.reject({ statusCode: 486 })
  //   }
  // }

  const setCallSessionsFunction = (session) => {
    // console.log("fffffffffffffffffff")
    if (session && session.state !== "Terminated") {
      setCallSessions((prevSessions) => {
        if (prevSessions.length >= sipconfiguration.maxCalls) {
          session.reject({ statusCode: 486 });
          return prevSessions;
        } else {
          setAnswerCount([...answerCount, session]);
          return [...prevSessions, session];

        }
      });
    }

  };




  return (
    <div>
      <SipRegistration
        sipconfiguration={sipconfiguration}
        agent={agent}
        setAgent={setAgent}
        setInvitationSession={setCallSessionsFunction}
        callSessions={callSessions}
        setCallSessionsFunctionEnd={setCallSessionsFunctionEnd}
        callAudio={callAudio}
        setCallAudio={setCallAudio}
        ringtone={ringtone}
        enablePhone={enablePhone}
        handleAnswerOnInvite = {handleAnswerOnInvite}
        sessionIncall={sessionIncall}


      />
      {/* <OnInvite
        agent={agent}
        callSessions={callSessions}
        setCallSessions={setCallSessions}
        sipconfiguration={sipconfiguration}

      /> */}
    </div>
  );
}

export default SipComponent;
