import petitionGet from "../../../../services/petitionGet"

const fetchContactDetails = async (contactId) => {
  const parameter = `/${contactId}`
  const { data: result } = await petitionGet("contacts", { parameter })
  result.result.tags = result.result.tags.map((tag) => ({
    ...tag,
    title: tag.tag.name,
    tag_color: tag.tag.tag_color,
    pk: tag.tag_id,
  }));
    
  return result.result;
}

export default fetchContactDetails
