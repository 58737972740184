import React, { useState } from "react";
import { LabelSelect, LoadingButton } from "../../../../components";
import { Row, Col } from "reactstrap";
import Switch from "react-switch";

const AddFunnel = ({ addFunnel, loading }) => {
  const [form, setForm] = useState({
    name: "",
    description: "",
    create_lead_if_contact_exists: false,
    lead_strategy: "",
    automatically_assign_lead: false,
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleChangeSwitch = (field) => {
    setForm({ ...form, [field]: !form[field] });
  };

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const save = () => {
    addFunnel(form)
  }

  return (
    <>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Funnel Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter a funnel name"
            className="form-control-itp itp-label-input__input"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Funnel Description</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter a funnel description"
            className="form-control-itp itp-label-input__input"
            name="description"
            value={form.description}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <LabelSelect label="Lead Strategy">
        <select
          value={form.lead_strategy}
          onChange={handleChange}
          className="form-select-itp"
          name="lead_strategy"
        >
          <option value="" disabled>
            Select Option
          </option>
          <option value="random">Random</option>
        </select>
      </LabelSelect>
      <LabelSelect label="Automatically assign lead">
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => handleChangeSwitch("automatically_assign_lead")}
          checked={form.automatically_assign_lead}
        />
      </LabelSelect>
      <LabelSelect label="Create a lead even if contact exists">
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => handleChangeSwitch("create_lead_if_contact_exists")}
          checked={form.create_lead_if_contact_exists}
        />
      </LabelSelect>
      <button
        onClick={save}
        className="btn-primary itp-lead-detail-button-save loading"
        disabled={form.name === "" || form.description === ""}
      >
        {loading && <LoadingButton />}
        Save Changes
      </button>
    </>
  );
};

export default AddFunnel;
