import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { ModalTagsColor, LoadingDots } from "../index";
import { useContactDetailsState } from "./hooks/useContactDetailsState";
import { useCustomFieldsHandling } from "./hooks/useCustomFieldsHandling";
import { useTabsData } from "./hooks/useTabsData";
import { useLeadsData } from "./hooks/useLeadsData";
import { ContactModalHeader } from "./components/ContactModalHeader";
import { ContactTabs } from "./components/ContactTabs";
import { ContactSidebar } from "./components/ContactSidebar";
import { ContactMainContent } from "./components/ContactMainContent";
import { useState } from "react";
import { useLeadState } from "./hooks/useLeadState";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import ModalDelete from "../../pages/private/Contacts/AddContact/ModalUtility/ModalDelete";
import "./ContactDetailsModal.css";
import "react-phone-input-2/lib/style.css";

// Helper function to format tags
const formatTags = (tags, newTags) => {
  let dataTagsNew = [];
  tags.forEach((element) => {
    if (typeof element !== "string") {
      if (element.inputValue) {
        let dataFilter = newTags.filter(
          (elementTwo) => elementTwo?.name === element?.inputValue
        );
        if (dataFilter[0]?.pk) {
          dataTagsNew.push(dataFilter[0].pk);
        }
      } else if (element.pk) {
        dataTagsNew.push(element.pk);
      }
    }
  });

  let result = dataTagsNew.filter((item, index) => {
    return dataTagsNew.indexOf(item) === index;
  });

  return result;
};

// Helper function to download CSV file
const downloadCsv = (base64Data) => {
  const binary = atob(base64Data);
  const array = new Uint8Array(binary.length);
  
  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }
  
  const blob = new Blob([array], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  
  link.href = url;
  link.download = "contacts.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Function to get the color of the last tag
const getLastColor = (tags) => {
  // Check if tags is an array and has items
  if (Array.isArray(tags) && tags.length > 0) {
    // Get the last tag directly
    const lastTag = tags[tags.length - 1];
    
    // Check if the last tag has a valid color
    if (
      lastTag && 
      typeof lastTag === "object" &&
      lastTag.tag_color && 
      lastTag.tag_color !== null && 
      lastTag.tag_color !== undefined &&
      lastTag.tag_color !== "" && 
      lastTag.tag_color !== "#fff"
    ) {
      return lastTag.tag_color;
    }
  }
  
  // If no valid color found, return default purple color
  return "rgb(109, 40, 217)";
};

const ContactDetailsModal = ({
  isOpen,
  closeModal,
  contact,
  customFields,
  myExtension,
  onSave,
  setAddedCustomField = () => {},
  leadDetails = null,
  loading = false,
  noDeleteButton = false,
  leads = false,
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);

  // Custom hooks for state management
  const {
    activeTab,
    contactData,
    form,
    isEditing,
    isSaving,
    error,
    tags,
    disabled,
    avatarColor,
    toggleTab,
    handleInputChange,
    handleTag,
    toggleEdit,
    setOpenModalColor,
    openModalColor,
    closeTagModal,
    setForm,
    setContactData,
    users,
    setUsers,
    isLoadingUsers,
    setIsLoadingUsers,
    contactLists,
    setContactLists,
    isLoadingContactLists,
    setIsLoadingContactLists,
    valueContactList,
    setValueContactList,
    asignedUser,
    setAsignedUser,
    countryValue,
    setCountryValue,
  } = useContactDetailsState({ contact, customFields, formatTags, getLastColor, onSave });

  const {
    leadData,
    leadForm,
    valueLeadSource,
    valueFunnel,
    valueStage,
    loadingFunnels,
    loadingStages,
    stageOptions,
    errorEditLead,
    setErrorEditLead,
    loadOptionFunnel,
    handleChangeFunnel,
    handleChangeStage,
    handleLeadInputChange,
    handleLeadSourceChange,
    editLead,
  } = useLeadState({ leadDetails });

  // Custom hook for custom fields handling
  const {
    customFieldsRef,
    handleAddedCustomField,
    handleEditedCustomField,
    handleDeletedCustomField,
  } = useCustomFieldsHandling({
    customFields,
    form,
    contactData,
    setForm,
    setContactData,
    setAddedCustomField,
  });

  // Custom hook for tabs data handling (notes and activities)
  const {
    notesCount,
    activityCount,
    notesLoaded,
    activityLoaded,
    finishFetchNotes,
    finishFetchCallHistory,
    handleNotesData,
    handleActivityData,
    shouldLoadNotes,
    shouldLoadActivity,
    setFinishFetchNotes,
    setFinishFetchCallHistory,
  } = useTabsData({ contactData, activeTab, myExtension });

  // Custom hook for leads data handling
  const { leadsCount, isLoadingLeads, setLeadsCount } = useLeadsData({ contactData, activeTab, leads });

  // Function to get initials from first and last name
  const getInitials = () => {
    const firstInitial = contactData.firstname ? contactData.firstname.charAt(0).toUpperCase() : '';
    const lastInitial = contactData.lastname ? contactData.lastname.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  };

  // Function to export contact
  const handleExportContact = async (contactId) => {
    setIsExporting(true);
    try {
      const response = await petitionPost('exportContacts', {
        contacts: [contactId],
        selected_all: false,
      });

      if (response?.data?.file) {
        downloadCsv(response.data.file);
      }
    } catch (error) {
      console.error('Error exporting contact:', error);
    } finally {
      setIsExporting(false);
    }
  };

  const confirmDeleteContact = (contactId) => {
    setContactToDelete(contactId);
    setShowDeleteConfirmation(true);
  };

  const cancelDeleteContact = () => {
    setContactToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const handleDeleteContact = async () => {
    setIsDeleting(true);
    try {
      await petitionDelete('contact', { contact_id: contactToDelete });
      // Si no hay error, cerramos el modal
      setShowDeleteConfirmation(false);
      closeModal(true);
    } catch (error) {
      console.error('Error deleting contact:', error);
      setShowDeleteConfirmation(false);
    } finally {
      setIsDeleting(false);
    }
  };

  // Custom save handler to handle both contact and lead updates
  const handleSave = async () => {
    // First, if we have lead data, update it
    if (leadDetails) {
      try {
        const leadUpdateSuccess = await editLead();

        if (!leadUpdateSuccess) {
          return; // Stop if lead update failed
        }
      } catch (error) {
        console.error('Error in lead update:', error);
        setErrorEditLead('Failed to update lead information');
        return;
      }
    }

    // Then update the contact data
    toggleEdit();
  };

  return (
    <>
      <Modal isOpen={!!isOpen} modalClassName="itp-contact-details-modal">
        {/* Header with contact info and actions */}
        <ContactModalHeader
          contactData={contactData}
          avatarColor={avatarColor}
          getInitials={getInitials}
          closeModal={closeModal}
          onExport={handleExportContact}
          isExporting={isExporting}
          onDelete={confirmDeleteContact}
          isDeleting={isDeleting}
          noDeleteButton={noDeleteButton}
          form={form}
        />

        {/* Main content area */}
        <ModalBody className="itp-contact-section p-0">
          {openModalColor && (
            <ModalTagsColor
              modal={openModalColor}
              closeModal={closeTagModal}
              title="Add tag"
              sendDatatoParent={handleTag}
              defaultTags={tags}
              loading={false}
            />
          )}

          {/* Left sidebar - Always visible */}
          <ContactSidebar
            isEditing={isEditing}
            isSaving={isSaving}
            toggleEdit={toggleEdit}
            handleSave={handleSave}
            error={error || errorEditLead}
            tags={tags}
            handleTag={handleTag}
            disabled={disabled}
            setOpenModalColor={setOpenModalColor}
            form={form}
            setForm={setForm}
            contactData={contactData}
            handleInputChange={handleInputChange}
            customFieldsRef={customFieldsRef}
            myExtension={myExtension}
            handleAddedCustomField={handleAddedCustomField}
            handleEditedCustomField={handleEditedCustomField}
            handleDeletedCustomField={handleDeletedCustomField}
            users={users}
            setUsers={setUsers}
            isLoadingUsers={isLoadingUsers}
            setIsLoadingUsers={setIsLoadingUsers}
            contactLists={contactLists}
            setContactLists={setContactLists}
            isLoadingContactLists={isLoadingContactLists}
            setIsLoadingContactLists={setIsLoadingContactLists}
            valueContactList={valueContactList}
            setValueContactList={setValueContactList}
            asignedUser={asignedUser}
            setAsignedUser={setAsignedUser}
            countryValue={countryValue}
            setCountryValue={setCountryValue}
            leadDetails={leadDetails}
            leadData={leadData}
            leadForm={leadForm}
            valueLeadSource={valueLeadSource}
            valueFunnel={valueFunnel}
            valueStage={valueStage}
            loadingFunnels={loadingFunnels}
            loadingStages={loadingStages}
            stageOptions={stageOptions}
            loadOptionFunnel={loadOptionFunnel}
            handleChangeFunnel={handleChangeFunnel}
            handleChangeStage={handleChangeStage}
            handleLeadInputChange={handleLeadInputChange}
            handleLeadSourceChange={handleLeadSourceChange}
            loading={loading}
          />

          {/* Main Content - Changes based on active tab */}
          <div className="itp-contact-content-container">
            {/* Tabs navigation moved inside the main content area */}
            <ContactTabs
              activeTab={activeTab}
              toggleTab={toggleTab}
              notesCount={notesCount}
              activityCount={activityCount}
              leads={leads}
              leadsCount={leadsCount}
            />

            {loading ? (
              <div className="itp-contact-main d-flex justify-content-center align-items-center">
                <LoadingDots className="my-5" />
              </div>
            ) : (
              <ContactMainContent
                activeTab={activeTab}
                contactData={contactData}
                finishFetchCallHistory={finishFetchCallHistory}
                activityLoaded={activityLoaded}
                myExtension={myExtension}
                handleActivityData={handleActivityData}
                shouldLoadActivity={shouldLoadActivity}
                setFinishFetchCallHistory={setFinishFetchCallHistory}
                finishFetchNotes={finishFetchNotes}
                notesLoaded={notesLoaded}
                handleNotesData={handleNotesData}
                shouldLoadNotes={shouldLoadNotes}
                setFinishFetchNotes={setFinishFetchNotes}
                leads={leads}
                isLoadingLeads={isLoadingLeads}
                setLeadsCount={setLeadsCount}
              />
            )}
          </div>
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <ModalDelete
        modal={showDeleteConfirmation}
        closeModal={cancelDeleteContact}
        loading={isDeleting}
        modalFunction={handleDeleteContact}
        text="Are you sure you want to delete this contact?"
        title="Delete Contact"
      />
    </>
  );
};

export default ContactDetailsModal; 