import React, { useEffect, useState } from "react";

const CharacterCounter = ({ text, charMin, charMax, colorOutRange = 'red', colorInRange = 'black', className = '' }) => {
  const [characterCount, setCharacterCount] = useState(0);
  const [color, setColor] = useState(colorInRange);

  useEffect(() => {
    const length = text.length;

    if (length < charMin || length > charMax) {
      setColor(colorOutRange);
    } else {
      setColor(colorInRange);
    }

    setCharacterCount(length);
  }, [text, charMin, charMax, colorOutRange, colorInRange]);

  return (
    <div style={{ color }} className={className}>
      Characters: {characterCount}/{charMax}
    </div>
  );
};

export default CharacterCounter;
