/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import { LoadingButton, MenuRight, CustomTableMain, AddButton, SearchBoxFilter, AlignedRow } from "../../../../components/index.js";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import { useDynamicFunction } from "../../../../contexts/DynamicFunctionContext.js";
import { addAreaCodeService, deleteAreaCodeService, fetchAreaCodes, fetchNumbers, fetchPortRequest } from "./services/index.js";
import axios from "axios";
import Switch from "react-switch";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import UpdateIcon from "@mui/icons-material/Update";
import AddNumber from "./components/AddNumber/AddNumber.js";
import TransferNumber from "./components/TransferNumber/TransferNumber.js";
import ModalDelete from "../ModalUtility/ModalDelete.js";
import petitionPatch from "../../../../services/petitionPatch.js";
import petitionDelete from "../../../../services/petitionDelete.js";
import useScreenSize from "../../../../hooks/useScreenSize.js";
import getQueryString from "../../../../utils/getQueryString.js";
import ModalAddAreaCode from "./components/ModalAddAreaCode/ModalAddAreaCode.js";
import "./PhoneNumbers.css";
import ModalEditAreaCode from "./components/ModalEditAreaCode/ModalEditAreaCode.js";

const PhoneNumbers = () => {
  const { width } = useScreenSize();

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();
  const { setDynamicFunction, resetFunction } = useDynamicFunction();

  const itemsPerPageAreaCodes = useRef("10");
  const searchAreaCodesFiler = useRef("");
  const currentCancelToken = useRef(null);
  const areaCodesPagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [loading, setLoading] = useState(true);
  const [loadingPagItems, setLoadingPagItems] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [textModalDelete, setTextModalDelete] = useState("");
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [loadingModalDelete, setLoadingModalDelete] = useState(false);
  const [openTransferNumber, setOpenTransferNumber] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [form, setForm] = useState({});
  const [infoPortRequest, setInfoPortRequest] = useState(null);
  const [loadingPortRequest, setLoadingPortRequest] = useState(false);
  const [searchInput, setSearchInput] = useState("")
  const [addNumber, setAddNumber] = useState(false);
  const [stepAddNumber, setStepAddNumber] = useState(0);
  const [listOfNumbers, setListOfNumbers] = useState(null);
  const [errorNumbers, setErrorNumbers] = useState(null);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [resetActive, setResetActive] = useState(false);
  const [pricePerDid, setPricePerDid] = useState(null);
  const [checkbox, setCheckBox] = useState(false);
  const [errorChecked, setErrorChecked] = useState(null);
  const [loadingPetitionAddNumber, setLoadingPetitionAddNumber] = useState(false)
  const [searchAreaCodes, setSearchAreaCodes] = useState("")
  const [allResultAreaCodes, setAllResultAreaCodes] = useState(null)
  const [modalAddAreaCode, setModalAddAreaCode] = useState(false)
  const [loadingAddAreaCode, setLoadingAddAreaCode] = useState(false)
  const [showDeleteAreaCode, setShowDeleteAreaCode] = useState(false)
  const [modalEditAreaCode, setModalEditAreaCode] = useState(false)

  const [dataTableNumbers, setDataTableNumbers] = useState({
    columns: [
      { name: "Number", key: "formatedNumber" },
      { name: "Status", key: "status" },
      { name: "Description", key: "description" },
      { name: "Used By", key: "usedByCallFlow" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const [dataTablePortRequest, setDataTablePortRequest] = useState({
    columns: [
      { name: "Number", key: "tableNumber" },
      { name: "Status", key: "provision_status" },
      { name: "Porting Status", key: "provision_result" },
      { name: "FOC Date", key: "porting_actual_foc_date" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const [dataTableAreaCodes, setDataTableAreaCodes] = useState({
    columns: [
      { name: "Primary Area Code", key: "npa_id" },
      { name: "Additional Area Codes", key: "additionalNpaIds" },
      { name: "Users with Custom Caller ID", key: "users" },
    ],
    actions: {
      title: "Actions",
      content: [],
    },
  });

  const [filterNumbers, setFilterNumbers] = useState({
    country: "selected",
    category: "selected",
    type: "",
  });

  const [prefix, setPrefix] = useState({
    code: "selected",
  });

  const [npa, setNpa] = useState({
    code: "",
  });

  const [cityState, setCityState] = useState({
    state: "",
    city: "",
  });

  const [npaNxx, setNpaNxx] = useState({
    npa: "",
    nxx: "",
  });

  const resetAll = () => {
    setFilterNumbers({
      country: "selected",
      category: "",
      type: "",
    });
    setCityState({
      state: "",
      city: "",
    });
    setNpa({
      code: "",
    });
    setNpaNxx({
      npa: "",
      nxx: "",
    });

    setStepAddNumber(0)
    setErrorChecked(false)
    setCheckBox(false)
    setPricePerDid(null)
    setResetActive(false)
    setErrorNumbers(null)
    setListOfNumbers([])  
    setListOfNumbers(null);
    setSelectedNumbers([]);
    setResetActive(false);
  };

  const resetStep = () => {
    setStepAddNumber(0);
  };

  const activeAddNewNumber = () => {
    if (loading) return;
    setAddNumber(true);
    setOpenMenu(true);
  };

  const closeModal = () => {
    setAddNumber(false);
    setInfoPortRequest(null);
    setOpenMenu(false);
    setTextModalDelete("");
    setDisabledSaveButton(false);
    setInfo(null);
    setModalDelete(false);
    setOpenTransferNumber(false);
    setModalAddAreaCode(false);
    setShowDeleteAreaCode(false)
    setModalEditAreaCode(false)
  };

  const activeUpdatePortRequest = (e) => {
    setInfoPortRequest(e);
  };

  const activeEditNumber = (e) => {
    navigate(`/Phone-Numbers/Edit-Number?number=${e.number}`)
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeTransferNumber = () => {
    if (loading) return;
    setOpenTransferNumber(true);
  };

  const clickUsedByColumn = (e) => {
    if (e?.in_use && e?.in_use_by?.internal_use === false) {
      const callFlowID = e?.in_use_by?.pk
      navigate(`/Advanced-Call-flows/Edit-Numbers?pk=${callFlowID}`)
    }
  }

  const reloadData = () => {
    resetAll()
    closeModal();
    petition();
  };

  const searchNumber = (e) => {
    if (loading) return;

    const { value } = e.target

    setSearchInput(value);
  
    let numbersTemp = numbers;
  
    if (value !== "") {
      numbersTemp = numbersTemp.filter((element) => {
        // Check if element.number and element.description are not null or undefined
        const number = element.number || "";
        const description = element.description || "";
  
        return (
          number.toLowerCase().includes(value.toLowerCase()) ||
          description.toLowerCase().includes(value.toLowerCase())
        );
      });

      setDataTableNumbers((prev) => {
        return {
          ...prev,
          content: [...numbersTemp],
        }
      })
    } else {
      
      setDataTableNumbers((prev) => {
        return {
          ...prev,
          content: [...numbers],
        }
      })
    }
  };

  const deleteNumber = async () => {
    setLoadingModalDelete(true);
    petitionDelete("number", { phone: info.number })
      .then(({ data: result }) => {
        setLoadingModalDelete(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          setLoadingModalDelete(false);
          setDisabledSaveButton(true);
          setTextModalDelete(error.response.data.error.error_message);
        }
      });
  };

  const handleClickAddAreaCode = () => {
    setModalAddAreaCode(true);
  }

  const activeModalDeleteAreaCode = (e) => {
    setInfo(e)
    setModalDelete(true)
    setShowDeleteAreaCode(true)
  }

  const activeModalEditAreaCode = (e) => {
    setInfo(e)
    setModalEditAreaCode(true)
  }

  const submitData = () => {
    if (form.porting_billing_name && form.porting_billing_name.length > 25)
      return;

    if (form.porting_zip_code && form.porting_zip_code.length > 5) return;

    setLoadingPortRequest(true);
    petitionPatch("orderItemUpdate", {
      order_item_id: infoPortRequest.pk,
      data: { metadata: form },
    })
      .then((result) => {
        setLoadingPortRequest(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const changePage = async (offset) => {
    setLoadingPagItems(true);
    const paginationTemp = {
      limit: areaCodesPagination.current.limit,
      offset: offset,
    };

    const parameter = getQueryString(paginationTemp) + searchAreaCodesFiler.current;

    await petitionAreaCodes(parameter);
    areaCodesPagination.current = paginationTemp;
  };

  const activeItemsPerPage = async (data) => {
    setLoadingPagItems(true);

    const paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    const parameter = getQueryString(paginationTemp) + searchAreaCodesFiler.current;
    await petitionAreaCodes(parameter);
    itemsPerPageAreaCodes.current = data;
    areaCodesPagination.current = paginationTemp;
  };

  const handleBackStepAddNumber = () => {
    if (!loadingPetitionAddNumber) {
      setStepAddNumber(stepAddNumber - 1);
      if (stepAddNumber - 1 === 1) {
        activeAddNewNumber();
      }
    }
  };

  const handleChangeSearchAreaCodes = async (e) => {
    try {
      if (loading) return;

      const { value } = e.target
      setSearchAreaCodes(value)

      // Cancelar la solicitud anterior si existe
      if (currentCancelToken.current) {
        currentCancelToken.current.cancel();
      }

      // Crear un nuevo CancelToken para la nueva solicitud
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      currentCancelToken.current = source;
  
      const parameterInput = value ? `&area_id=${value}` : "";
      const paginationTemp = { offset: 0, limit: areaCodesPagination.current.limit };
      const parameter = getQueryString(paginationTemp) + parameterInput;
  
      await petitionAreaCodes(parameter, source.token);

      areaCodesPagination.current = paginationTemp;
      searchAreaCodesFiler.current = parameterInput;
    } catch (error) {
      console.log(error)
      if (!axios.isCancel(error)) {
        console.log("isCancel", error);
      }
    }
  };

  const addAreaCode = async (data) => {
    try {
      setLoadingAddAreaCode(true)
      await addAreaCodeService(data)

      const parameter = getQueryString(areaCodesPagination.current) + searchAreaCodesFiler.current;
      await petitionAreaCodes(parameter)
    } catch (error) {
      console.log(error)
      setLoadingAddAreaCode(false)
    }
  }

  const deleteAreaCode = async () => {
    try {
      setLoadingModalDelete(true)

      await deleteAreaCodeService(info.pk)

      setDataTableAreaCodes((prev) => {
        return {
          ...prev,
          content: [...prev.content.filter((element) => element.pk !== info.pk)]
        }
      })

      closeModal()
      setLoadingModalDelete(false)
    } catch (error) {
      console.log(error)
      setLoadingModalDelete(false)
    }
  }

  const petitionPortRequest = async () => {
    const portRequest = await fetchPortRequest()

    setDataTablePortRequest({
      columns: [
        { name: "Number", key: "tableNumber" },
        { name: "Status", key: "provision_status" },
        { name: "Porting Status", key: "provision_result" },
        { name: "FOC Date", key: "porting_actual_foc_date" },
      ],
      content: [...portRequest],
      actions: {
        title: "Actions",
        content: [
          {
            name: "Update",
            icon: <UpdateIcon />,
            handleClick: activeUpdatePortRequest,
          },
        ],
      },
    });
  };

  const petitionNumbers = async () => {
    const numbers = await fetchNumbers()

    setNumbers(numbers)
    setDataTableNumbers({
      columns: [
        { name: "Number", key: "formatedNumber" },
        { name: "Status", key: "status" },
        { name: "Description", key: "description" },
        { name: "Used By", key: "usedByCallFlow" },
      ],
      content: [...numbers],
      actions: {
        title: "Actions",
        content: [
          {
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
            name: "Edit",
            handleClick: activeEditNumber,
          },
          {
            icon: <FontAwesomeIcon icon={faTrash} />,
            name: "Delete",
            handleClick: activeModalDelete,
          },
        ],
      },
      addButton: {
        label: "Add Number",
        handleClick: activeAddNewNumber,
      },
    });
  }

  const petitionAreaCodes = async (parameter, cancelToken) => {
    const result = await fetchAreaCodes(parameter, cancelToken);
    const areaCodes = result.result;

    setAllResultAreaCodes(result);

    setDataTableAreaCodes({
      columns: [
        { name: "Primary Area Code", key: "npa_id" },
        { name: "Additional Area Codes", key: "additionalNpaIds" },
        { name: "Users with Custom Caller ID", key: "user_count" },
      ],
      content: [...areaCodes],
      actions: {
        title: "Actions",
        content: [
          {
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
            name: "Edit",
            handleClick: activeModalEditAreaCode,
          },
          {
            icon: <FontAwesomeIcon icon={faTrash} />,
            name: "Delete",
            handleClick: activeModalDeleteAreaCode,
          },
        ],
      },
    });

    closeModal();
    setLoadingAddAreaCode(false);
    setLoadingPagItems(false);
  };

  const refreshTable = async () => {
    if (loading) return;

    try {
      setLoading(true)
      await petitionNumbers()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const petition = async () => {
    try {
      setLoading(true)

      const parameter = getQueryString(areaCodesPagination.current);
      await petitionAreaCodes(parameter);

      await petitionPortRequest()

      await petitionNumbers()

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (stepAddNumber > 0) {
      setDynamicFunction(() => () => {
        handleBackStepAddNumber()
      });
    } else if (openTransferNumber) {
      setDynamicFunction(() => () => {
        setOpenTransferNumber(false)
      });
    } else {
      resetFunction();
    }
  }, [openTransferNumber, setDynamicFunction, resetFunction, stepAddNumber]);

  useEffect(() => {
    if (infoPortRequest) {
      setForm(infoPortRequest?.metadata);
      setOpenMenu(true);
    }
  }, [infoPortRequest]);

  useEffect(() => {    
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_phone_numbers
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          dispatch({ type: "SET_DEFAULT_MENU" });
          navigate("/Dashboard");
        } else {
          petition();
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  return (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingModalDelete}
          modalFunction={showDeleteAreaCode ? deleteAreaCode : deleteNumber}
          module={showDeleteAreaCode ? "Area Code" : "Number"}
          text={showDeleteAreaCode ? "" : textModalDelete}
          disabledSaveButton={disabledSaveButton}
        />
      )}

      {modalAddAreaCode && (
        <ModalAddAreaCode
          modal={modalAddAreaCode}
          closeModal={closeModal}
          modalFunction={addAreaCode}
          loadingButton={loadingAddAreaCode}
        />
      )}

      {modalEditAreaCode && (
        <ModalEditAreaCode
          modal={modalEditAreaCode}
          closeModal={closeModal}
          areaCodeInfo={info}
          numbers={numbers}
        />
      )}

      {!openTransferNumber && stepAddNumber <= 1 && (
        <div style={{ marginTop: "3rem" }}>
          <div id="numbers">
            <AlignedRow m1>
              <h3 className="itp-accordion-header-title">Phone Numbers</h3>
            </AlignedRow>
            <AlignedRow m1>
              <h6 className="itp-accordion-header-subtitle">
                Manage all phone numbers in your workspace
              </h6>
            </AlignedRow>
            <CustomTableMain
              data={dataTableNumbers}
              centered={width >= 1697 ? true : false}
              loading={loading}
              module="phone numbers"
              actionsLeft={[
                {
                  component: (
                    <SearchBoxFilter
                      placeholder="Search Number, description or assigned user"
                      handleChange={searchNumber}
                      value={searchInput}
                    />
                  ),
                },
              ]}
              actionsRight={[
                {
                  component: (
                    <RefreshIcon
                      className="itp-phone-numbers-refresh-icon"
                      onClick={refreshTable}
                    />
                  ),
                },
                {
                  component: (
                    <button
                      className="btn-light loading itp-phone-numbers-transfer-button mt-0"
                      onClick={activeTransferNumber}
                    >
                      <ArrowCircleRightOutlinedIcon
                        style={{
                          color: "#437097",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      Transfer Number
                    </button>
                  ),
                },
                {
                  component: (
                    <AddButton
                      handleClick={activeAddNewNumber}
                      name="Add Number"
                    />
                  ),
                },
              ]}
              columnConfig={{
                "Used By": {
                  type: "hoverInfo",
                  keyStyles: "style",
                  clickColumn: clickUsedByColumn,
                  hoverKey: "hoverKey",
                },
              }}
            />
          </div>

          <br></br>
          <div>
            <Row>
              <Col>
                <div className="itp-my-settings-separator-line"></div>
              </Col>
            </Row>
          </div>
          <br></br>

          <div id="campaigns">
            <div className="itp-table-container-centered overflow-hidden">
              <Row>
                <Col>
                  <div className="text-align-with-table">
                    <span className="table-alt-text-title"></span>
                    <br></br>

                    <span className="table-alt-text-title-description"></span>
                  </div>
                </Col>
              </Row>
            </div>
            <CustomTableMain
              data={dataTablePortRequest}
              loading={loading}
              centered={width >= 1697 ? true : false}
              module="port request"
              actionsLeft={[
                {
                  component: (
                    <div className="d-flex flex-column gap-2">
                      <h3 className="itp-accordion-header-title">
                        Port Request
                      </h3>
                      <h6 className="itp-accordion-header-subtitle">
                        Manage Port Request in your workspace
                      </h6>
                    </div>
                  ),
                },
              ]}
            />
          </div>

          <br></br>
          <div>
            <Row>
              <Col>
                <div className="itp-my-settings-separator-line"></div>
              </Col>
            </Row>
          </div>
          <br></br>

          <div id="Area codes">
            <AlignedRow m1>
              <h3 className="itp-accordion-header-title">Smart Caller ID by Area Code</h3>
            </AlignedRow>
            <AlignedRow m1>
              <h6 className="itp-accordion-header-subtitle">
                Automatically set Caller ID based on the area code to optimize call recognition and response rates.
              </h6>
            </AlignedRow>
            <CustomTableMain
              data={dataTableAreaCodes}
              centered={width >= 1697 ? true : false}
              loading={loading}
              loadingPagItems={loadingPagItems}
              module="area codes"
              isBackend={true}
              paginationData={parseInt(itemsPerPageAreaCodes.current)}
              totalPages={allResultAreaCodes?.total_pages}
              onPageChange={changePage}
              showSelectSingleCheckbox={true}
              onChangeItemsPerPage={activeItemsPerPage}
              offset={areaCodesPagination.current.offset}
              noDataMessage={
                (searchAreaCodesFiler.current || searchAreaCodes) &&
                !allResultAreaCodes?.result.length
                  ? "no results found"
                  : ""
              }
              actionsLeft={[
                {
                  component: (
                    <SearchBoxFilter
                      placeholder="Search area code"
                      handleChange={handleChangeSearchAreaCodes}
                      value={searchAreaCodes}
                    />
                  ),
                },
              ]}
              actionsRight={[
                {
                  component: (
                    <AddButton
                      handleClick={handleClickAddAreaCode}
                      name="Add Area Code"
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      )}

      {!loading && openTransferNumber && (
        <div style={{ marginTop: "3rem" }}>
          <TransferNumber />
        </div>
      )}

      {!loading && stepAddNumber > 1 && (
        <AddNumber
          step={stepAddNumber}
          setStep={setStepAddNumber}
          closeModal={closeModal}
          listOfNumbers={listOfNumbers}
          setListOfNumbers={setListOfNumbers}
          errorNumbers={errorNumbers}
          setErrorNumbers={setErrorNumbers}
          selectedNumbers={selectedNumbers}
          setSelectedNumbers={setSelectedNumbers}
          resetActive={resetActive}
          setResetActive={setResetActive}
          pricePerDid={pricePerDid}
          setPricePerDid={setPricePerDid}
          filterNumbers={filterNumbers}
          setFilterNumbers={setFilterNumbers}
          prefix={prefix}
          setPrefix={setPrefix}
          npa={npa}
          setNpa={setNpa}
          cityState={cityState}
          setCityState={setCityState}
          npaNxx={npaNxx}
          setNpaNxx={setNpaNxx}
          checkbox={checkbox}
          setCheckBox={setCheckBox}
          errorChecked={errorChecked}
          setErrorChecked={setErrorChecked}
          resetAll={resetAll}
          reloadData={reloadData}
          loadingPetition={loadingPetitionAddNumber}
          setLoadingPetition={setLoadingPetitionAddNumber}
        />
      )}

      <MenuRight
        show={openMenu}
        closeMenu={stepAddNumber === 1 ? resetStep : closeModal}
        title={openMenu && !addNumber ? "Update" : "Add New Number"}
        to={stepAddNumber === 1 ? "to Step 1" : "to Phone Numbers"}
      >
        {openMenu && openMenu !== "other" && !addNumber && (
          <>
            {Object.keys(infoPortRequest.metadata).map((element) => (
              <Row
                key={element}
                className={`itp-container-label-${
                  infoPortRequest.metadata[element] === true ||
                  infoPortRequest.metadata[element] === false
                    ? "select"
                    : "input"
                }`}
              >
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  className="itp-label-input-col"
                >
                  <label className="itp-label-input__label">{element}</label>
                </Col>
                <Col
                  xs={7}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  xxl={7}
                  className="itp-label-input-col"
                >
                  {infoPortRequest.metadata[element] === true ||
                  infoPortRequest.metadata[element] === false ? (
                    <>
                      <Switch
                        className="itp-custom-table-switch"
                        onColor="#626ed4"
                        height={20} // Ajusta la altura según tus necesidades
                        width={40}
                        onChange={() => {
                          setForm({
                            ...form,
                            [element]: form[element] === true ? false : true,
                          });
                        }}
                        checked={form[element]}
                      />
                    </>
                  ) : (
                    <input
                      type="text"
                      placeholder=""
                      className="form-control-itp itp-label-input__input"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          [element]: e.target.value,
                        })
                      }
                      value={form[element] || ""}
                    />
                  )}
                </Col>
              </Row>
            ))}
            <button
              className="btn-primary loading add-lead-new-contact"
              onClick={submitData}
            >
              {loadingPortRequest && <LoadingButton />}
              Save
            </button>
          </>
        )}

        {openMenu && openMenu !== "other" && addNumber && (
          <AddNumber
            step={stepAddNumber}
            setStep={setStepAddNumber}
            closeModal={closeModal}
            listOfNumbers={listOfNumbers}
            setListOfNumbers={setListOfNumbers}
            errorNumbers={errorNumbers}
            setErrorNumbers={setErrorNumbers}
            selectedNumbers={selectedNumbers}
            setSelectedNumbers={setSelectedNumbers}
            resetActive={resetActive}
            setResetActive={setResetActive}
            pricePerDid={pricePerDid}
            setPricePerDid={setPricePerDid}
            filterNumbers={filterNumbers}
            setFilterNumbers={setFilterNumbers}
            prefix={prefix}
            setPrefix={setPrefix}
            npa={npa}
            setNpa={setNpa}
            cityState={cityState}
            setCityState={setCityState}
            npaNxx={npaNxx}
            setNpaNxx={setNpaNxx}
            checkbox={checkbox}
            setCheckBox={setCheckBox}
            errorChecked={errorChecked}
            setErrorChecked={setErrorChecked}
            resetAll={resetAll}
            reloadData={reloadData}
            loadingPetition={loadingPetitionAddNumber}
            setLoadingPetition={setLoadingPetitionAddNumber}
          />
        )}
      </MenuRight>
    </>
  );
};

export default PhoneNumbers;