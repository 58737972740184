import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ContactDetailsModal from "../ContactDetailsModal/ContactDetailsModal";
import { getContactModalDataService } from "./services";

const Voice360Chat = () => {
  const customFields = useRef([])
  const myExtension = useRef({})

  const [searchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false); // Estado para saber si ya cargó
  const [currentPhone, setCurrentPhone] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [contactDetails, setContactDetails] = useState(null);

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  const handleOpenContactModal = async (contactProps) => {
    try {
      setLoadingModal(true);

      const contactId = contactProps.contactId
      const { customFieldsArray, contactDetails } = await getContactModalDataService(contactId);

      myExtension.current = contactProps.myExtension;
      customFields.current = customFieldsArray;
      setContactDetails(contactDetails);
      setLoadingModal(false);
    } catch (error) {
      console.error(error);
      setLoadingModal(false);
    }
  };

  const closeModal = (form) => {
    const iframe = document.querySelector("iframe[title='Voice360 Chat']");

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ type: "CLOSE_CONTACT_MODAL", form }, "*");
      setContactDetails(null);
    }
  };

  const handleMessageFromIframe = (event) => {
    // Make sure the message is coming from our iframe
    if (event.data && event.data.type === "SHOW_CONTACT_MODAL") {
      // Set contact props from the message data
      console.log(event.data.contactProps)
      handleOpenContactModal(event.data.contactProps);
    }
  };

  const phone = searchParams.get("phone") || ""; // Obtiene el número de la query string

  useEffect(() => {
    // Si cambia el phone, recarga el iframe
    if (phone !== currentPhone) {
      setCurrentPhone(phone);
      setIsLoaded(false);
    }
  }, [phone]);

  // Setup event listener for iframe messages
  useEffect(() => {
    window.addEventListener("message", handleMessageFromIframe);
    
    return () => {
      window.removeEventListener("message", handleMessageFromIframe);
    };
  }, []);

  return (
    <div>
      <iframe
        src={`/voice360-fe-chat/index.html?phone=${currentPhone}`}
        title="Voice360 Chat"
        loading="lazy" // Forzar carga anticipada
        onLoad={handleOnLoad}
        style={{
          width: '100%',
          height: 'calc(100vh - 104px)',
          opacity: isLoaded ? 1 : 0, // Oculta el iframe mientras carga
          transition: 'opacity 0.3s ease-in-out', // Transición suave
        }}
      ></iframe>

      {contactDetails && (
        <ContactDetailsModal
          isOpen={contactDetails}
          closeModal={closeModal}
          contact={contactDetails}
          customFields={customFields.current}
          myExtension={myExtension.current}
          loading={loadingModal}
          noDeleteButton
          leads
        />
      )}
    </div>
  );
};

export default Voice360Chat;