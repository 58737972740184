import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { count } from "sms-length";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton, MenuRight, TextAreaVarSelect, CustomTableMain, AddButton, CollapsePanel } from "../../../../../components";
import ModalDelete from "../../ModalUtility/ModalDelete";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import { defaultVariables } from "../../../../../const/defaultVariables";

const MessageTemplates = ({
  loading,
  setCompletedRequests,
  openMenu,
  setOpenMenu,
  modalDelete,
  setModalDelete,
}) => {
  const [options, setOptions] = useState([]);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [contMessage, setContMessage] = useState({});

  const [formTemplate, setFormTemplate] = useState({
    description: "",
    title: "",
    body: "",
  });

  const [dataTableTemplates, setDataTableTemplates] = useState({
    columns: [
      { name: "Template Name", key: "title" },
      { name: "Description", key: "description" },
      { name: "Message", key: "body" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const closeMenu = () => {
    setLoadingTemplate(false);
    setFormTemplate({
      description: "",
      title: "",
      body: "",
    });
    setModalDelete(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const onChangeFormTemplate = (e) => {
    setFormTemplate({ ...formTemplate, [e.target.name]: e.target.value });
  };

  const onChangeTextArea = (e) => {
    setFormTemplate({ ...formTemplate, body: e });
    setContMessage(count(e))
  };

  const addEditTemplate = () => {
    if (!info) {
      setLoadingTemplate(true);
      petitionPost("messageTemplates", { data: formTemplate })
        .then(({ data: result }) => {
          setDataTableTemplates((prevState) => {
            const newContent = [...prevState?.content, result?.result];

            return {
              ...prevState,
              content: [...newContent],
            };
          });
          setLoadingTemplate(false);
          closeMenu();
        })
        .catch((error) => console.log(error));
    } else {
      setLoadingTemplate(true);
      petitionPatch("messageTemplates", {
        data: formTemplate,
        message_template_id: info.pk,
      })
        .then(({ data: result }) => {
          setDataTableTemplates((prevState) => {
            const newContent = prevState?.content.map((element) => {
              if (element.pk === info.pk) {
                element = { ...result?.result };
              }

              return element;
            });

            return {
              ...prevState,
              content: [...newContent],
            };
          });
          setLoadingTemplate(false);
          closeMenu();
        })
        .catch((error) => console.log(error));
    }
  };

  const deleteTemplate = () => {
    setLoadingModal(true);
    petitionDelete("messageTemplates", { message_template_id: info.pk })
      .then(({ data: result }) => {
        setDataTableTemplates((prevState) => {
          const newContent = prevState?.content.filter(
            (element) => element.pk !== info.pk
          );

          return {
            ...prevState,
            content: [...newContent],
          };
        });

        setLoadingModal(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  const activeAddTemplate = () => {
    if (loading) return;
    setOpenMenu(1);
  };

  const activeDeleteTemplate = (e) => {
    setInfo(e);
    setModalDelete(1);
  };

  const activeEditTemplate = (e) => {
    setInfo(e);
    setFormTemplate({
      description: e?.description || "",
      title: e?.title || "",
      body: e?.body || "",
    });

    setContMessage(count(e?.body || ""))
    setOpenMenu(1);
  };

  const petitionMessageTemplate = async () => {
    await petitionGet("customFieldAdmin")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = `{{contact.${element.name}}}`;
        });

        const variables = defaultVariables.map((element) => {
          if (element.title === "Contact") {
            element.options = [...element.options, ...result.result]
          }

          return element
        })

        setOptions([
          {
            title: "Variables",
            options: [...variables],
            dropdownOpen: false,
          },
        ]);
      })
      .catch((error) => console.log(error));

    await petitionGet("messageTemplates")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.description = element?.description || "";
        });

        setDataTableTemplates({
          columns: [
            { name: "Template Name", key: "title" },
            { name: "Description", key: "description" },
            { name: "Message", key: "body", checkLenght: true },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditTemplate,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteTemplate,
              },
            ],
          },
        });
        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petitionMessageTemplate();
  }, []);

  return (
    <>
      {modalDelete === 1 && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingModal}
          modalFunction={deleteTemplate}
          module="Messages Template"
        />
      )}

      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="messageTemplates"
        title="Message Templates"
        subtitle="Create templates that can be used inside workflows or messages"
    >
       <CustomTableMain 
         data={dataTableTemplates}
         m0={true}
         module="Message Templates"
         loading={loading}
         actionsRight={[
           {
             component: (
               <AddButton 
                 handleClick={activeAddTemplate}
                 name="Add Template"
               />
             )
           }
         ]}
        />
      </CollapsePanel>

      <MenuRight
        show={openMenu === 1 ? true : false}
        closeMenu={closeMenu}
        title={`${!info ? "Add" : "Edit"} Message Template`}
        to=""
      >
        {openMenu === 1 && (
          <>
              <Row className="itp-container-label-input">
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  className="itp-label-input-col"
                >
                  <label className="itp-label-input__label">
                    Template Name
                  </label>
                </Col>
                <Col
                  xs={7}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  xxl={7}
                  className="itp-label-input-col"
                >
                  <input
                    type="text"
                    placeholder=": Add Name to this template"
                    className="form-control-itp itp-label-input__input"
                    name="title"
                    value={formTemplate.title}
                    onChange={onChangeFormTemplate}
                  />
                </Col>
              </Row>
              <Row className="itp-container-label-input">
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  className="itp-label-input-col"
                >
                  <label className="itp-label-input__label">Description</label>
                </Col>
                <Col
                  xs={7}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  xxl={7}
                  className="itp-label-input-col"
                >
                  <input
                    type="text"
                    placeholder=": Add Name to this switch"
                    className="form-control-itp itp-label-input__input"
                    name="description"
                    value={formTemplate.description}
                    onChange={onChangeFormTemplate}
                  />
                </Col>
              </Row>
              <label className="itp-label-input__label">Template Text</label>
              <Col>
                <TextAreaVarSelect
                  options={options}
                  onChangeTextArea={onChangeTextArea}
                  textAreaDefaultValue={formTemplate.body}
                />
              </Col>
              <button
                onClick={addEditTemplate}
                className="btn-primary itp-lead-detail-button-save loading mb-4"
                disabled={
                  !formTemplate.body ||
                  !formTemplate.title ||
                  !formTemplate.description
                }
              >
                {loadingTemplate && <LoadingButton />}
                Save
              </button>
          </>
        )}
      </MenuRight>
    </>
  )
};

export default MessageTemplates;
