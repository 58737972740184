/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,useRef } from 'react';
import { AlertNotificactions } from "../index"
import { SessionState, Web } from "sip.js";
import { myExtensionPetition } from '../../services/myExtension.service';
import { fetchAssignedNumbers, updateCallerIdService } from './views/Keyboard/services';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import SipComponent from './logic/SipComponent';
import Keyboard from './views/Keyboard/Keyboard'
import IncomingCall from './views/IncomingCall/IncomingCall'
import OnCall from './views/OnCall/OnCall'
import CallTimer from "./views/OnCall/CallerTimer"
import Settings from './views/Settings/Settings';
import ringtoneSound from "./assets/audio/ringtone2.mpeg";
import ringbacktone from "./assets/audio/ringbacktone.mp3";
import ringtoneSoundBeeb from "./assets/audio/double_beep_with_silence.wav";
import "./WebPhone.css"
import getMessageErrorTryCatch from '../../utils/getMessageErrorTryCatch';



const WebPhone = ({ sipconfiguration, showWebphone, isClickCall, numberToCallOnClick, handleCloseActiveCall,setShowWebphone }) => {
    const [reject, setReject] = useState(0);
    const [call, setCall] = useState(0);
    const [bye, setBye] = useState(null);
    const [byeOnCall, setByeOnCall] = useState(null);
    const [answer, setAnswer] = useState(0);
    const [callSessions, setCallSessions] = useState([]);
    const [answerCount, setAnswerCount] = useState([]);
    const [sessionIncall, setSessionIncall] = useState([]);
    const [numberToCall, setNumberTocall] = useState({ number: "", data: {} });
    const [showOnCall, setShowOnCall] = useState(false);
    const [agent, setAgent] = useState(null);
    const [webphonePosition, setWebphonePosition] = useState({ x: 0, y: 0 });
    const [incomingCallPosition, setIncomingCallPosition] = useState({ x: 0, y: 0 });
    const [onCallPosition, setOncallPosition] = useState({ x: 0, y: 0 });
    const [devices, setDevices] = useState({ input: [], output: [] })
    const [volume, setVolume] = useState(100);
    const [audioInputSelected, setAudioInputSeleced] = useState("default");
    const [audioOutputSelected, setAudioOutputSelected] = useState("default");
    const [callAudio, setCallAudio] = useState(new Audio());
    const [closeSettings, setCloseSettings] = useState(true);
    const [enablePhone, setEnablePhone] = useState(true);
    const [connecting, setConnecting] = useState(false);
    const [audioInputStream, setAudioInputStream] = useState(null);
    const [audioOutputSinkId, setAudioOutputSinkId] = useState(null)
    const [showErrorTime, setShowErrorTime] = useState(false)
    const [alertTitle, setAlertTitle] = useState("")
    const [alertType, setAlertType] = useState()
    const [isLoadingNumbers, setIsLoadingNumbers] = useState(false)
    const [assignedNumbers, setAssignedNumbers] = useState(null)
    const [callerIdNumber, setCallerIdNumber] = useState(null)
    const [alertMessage, setAlertMessage] = useState("Unable to perform action Listen on this call")
    const indexIncomming = useRef(0);

    const [ringtone] = useState(new Audio(ringtoneSound));
    const [rinding] = useState(new Audio(ringbacktone));
    const [ringtoneBeeb] = useState(new Audio(ringtoneSoundBeeb));

    ringtone.loop = true;
    rinding.loop = true;

    const sessionIncallRef = useRef([])
    const conferenseSession = useRef(false)
    const onSettingsPosition = { x: -200, y: 0 }

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#0A2540',
            borderRadius: '8px',
            border: state.isFocused ? '1px solid #4A90E2' : 'none',
            boxShadow: 'none',
            color: 'white',
            fontSize: '10px',
            padding: '6px',
            minHeight: '40px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#FFFFFF99',
            whiteSpace: "nowrap",
            fontSize: "12px",
            marginRight: "-0.5rem",
        }),
        input: (provided) => ({
          ...provided,
          color: 'white'
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#0A2540',
            borderRadius: '8px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#12395A' : '#0A2540',
            color: 'white',
            padding: '8px',
            cursor: 'pointer',
            fontSize: '10px',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'white'
        }),
        indicatorSeparator: () => ({
            display: 'none'
        })
    };

    const handleCall = () => {
        setCall(prevCounter => prevCounter + 1);
    };

    const handleBye = () => {
        conferenseSession.current = false
        setBye(prevCounter => prevCounter + 1);
    };

    const handleByeOnCall = () => {
        conferenseSession.current = false
        setByeOnCall(prevCounter => prevCounter + 1);
    };

    const handleByeOnInvite = (element, index) => {
        conferenseSession.current = false
        switch (element.state) {
            case SessionState.Initial:
                element.reject();
                break;
            case SessionState.Establishing:
                element.cancel();
                break;
            case SessionState.Established:
                element.bye();
                break;
            case SessionState.Terminating:
            case SessionState.Terminated:
                break;
            default:
                break;
        }

        // answerCount.splice(index, 1)
    };

    useEffect(() => {
        if (callSessions.length === 0) {
            setShowOnCall(false)
        }
        console.log("---------------------------------------------", callSessions)

        if (callSessions.length < 2) {
            ringtone.volume = volume / 100
        } else {
            ringtone.pause()
            ringtone.volume = 0
            ringtoneBeeb.play()

        }
    }, [callSessions])

    const handleAnswerOnInvitebefore = async (element, index2, makecall, conference, numberInf) => {
        console.log("📞 handleAnswerOnInvitebefore called at:", new Date().toISOString());
        console.log("🔍 Checking session state:", element.state);
      
        if (element.state !== "Initial") {
          console.error(`❌ Session is not in 'Initial' state, it is '${element.state}'`);
          return;
        }
      
        // Log or store the index
        indexIncomming.current = index2;
      
        console.log("🔄 Calling element.accept() at:", new Date().toISOString());
        console.log("🔍 Checking SIP session internals before forcing media setup...");
        console.log("➡️ Session Object:", element);
        console.log("➡️ Session Delegate:", element.delegate);
        console.log("➡️ Session Options:", element.options);
      
        // STEP A: If the SessionDescriptionHandler already exists, attach ICE listeners now.
        //         Otherwise, we attach a delegate callback to catch when it is created.
        if (element.sessionDescriptionHandler && element.sessionDescriptionHandler.peerConnection) {
          attachIceListeners(element.sessionDescriptionHandler.peerConnection);
        } else {
          element.delegate = {
            ...element.delegate, // keep existing delegate fields if any
            onSessionDescriptionHandlerCreated: (sdh) => {
              console.log("🔧 SessionDescriptionHandler was just created!");
              if (sdh.peerConnection) {
                attachIceListeners(sdh.peerConnection);
              }
            },
          };
        }
      
        console.log("🎤 Starting SDP Generation at:", new Date().toISOString());
        try {
          console.log("✅ SDP Generated at:", new Date().toISOString());
          console.log("📤 Sending SIP 200 OK...");
      
          // Accept the invite, which triggers the SIP 200 OK and starts the WebRTC flow
          await element.accept({
            // SessionDescriptionHandler Options
            sessionDescriptionHandlerOptions: {
              constraints: {
                audio: true,
                video: false
              },
              // If you want to shorten ICE check
              iceCheckingTimeout: 1000,
              iceGatheringTimeout: 1000
            },
          
            // Factory Options (including RTC config for STUN/TURN)
            sessionDescriptionHandlerFactoryOptions: {
              peerConnectionOptions: {
                rtcConfiguration: {
                  // Provide your STUN/TURN server(s) here
                  iceServers: [
                    { urls: "stun:stun.l.google.com:19302" },
                  ],
                  // 'all' tries host, srflx, and relay
                  iceTransportPolicy: "all",
                }
              }
            }
          });
          
      
          console.log("✅ SIP 200 OK Sent at:", new Date().toISOString());
        } catch (error) {
          console.error("❌ Error in SDP Generation:", error);
        }
      };
      
      /**
       * Attach ICE gathering & connection state listeners to peerConnection
       */
      function attachIceListeners(pc) {
        console.log("🚀 Attaching ICE listeners now...");
      
        pc.addEventListener("icegatheringstatechange", () => {
          console.log("ICE Gathering State:", pc.iceGatheringState, new Date().toISOString());
        });
      
        pc.addEventListener("iceconnectionstatechange", () => {
          console.log("ICE Connection State:", pc.iceConnectionState, new Date().toISOString());
        });
      }
    
    
    
    
    
    
    
    
    
    
    
    
    //////////// ultia actualizacion
    const handleAnswerOnInvite = (element, index, makecall, conference, numberInf) => {
        element.dataCall = numberToCall.data;

        let sesionInCallCopy = sessionIncallRef.current
        // const timestamp = Date.now();
        if (!conference) {
            sesionInCallCopy.forEach(el => {
                holdCall(el)
            })
        }

        if (numberInf) {
            element.dataCall = numberInf.data ? numberInf.data : numberToCall.data;
        }

        element.CallTimer = Date.now()

        element.onHold = false
        sessionIncallRef.current = [...sesionInCallCopy, element]
        setSessionIncall([...sesionInCallCopy, element])
        if (!makecall) {
            let answerCountTemp = answerCount.splice(indexIncomming.current, 1)
            setAnswerCount(answerCountTemp)
            console.log("indexIncomming", indexIncomming.current ,answerCount)
        } else {
            answerCount.splice(index, 1)
        }

        console.log(sessionIncallRef.current)
        setShowOnCall(true)
    };

    async function holdCall(session) {
        if (session._state === "Established") {
            try {
                session.onHold = true
                const options = {
                    sessionDescriptionHandlerModifiers: [Web.holdModifier],
                };
                await session.invite(options);
                console.log("hold call: ", session)
            } catch (error) {
              const messageError = getMessageErrorTryCatch(error)

              setAlertType("error")
              setAlertTitle("Error")
              setAlertMessage(messageError)
              setShowErrorTime(5000)
        
              setTimeout(() => {
                setShowErrorTime(false)
                setAlertType("")
                setAlertTitle("")
                setAlertMessage("")
              }, 5300)
            }
        }
    }

    async function unHoldCall(session) {
        if (session._state === "Established") {
            try {
                session.onHold = false
                const options = {
                    sessionDescriptionHandlerModifiers: [],
                };
                await session.invite(options);
                console.log("un hold call: ", session)
            } catch (error) {
              const messageError = getMessageErrorTryCatch(error)

              setAlertType("error")
              setAlertTitle("Error")
              setAlertMessage(messageError)
              setShowErrorTime(5000)
        
              setTimeout(() => {
                setShowErrorTime(false)
                setAlertType("")
                setAlertTitle("")
                setAlertMessage("")
              }, 5300)
            }
        }
    }

    function changeRingtoneVolume(vol) {
        ringtone.volume = vol / 100
    }

    const setCallSessionsFunctionEnd = (element, sendingCall) => {
        // Filtrar y actualizar el array callSessions
        setCallSessions((prevSessions) => {
            const updatedCallSessions = prevSessions.filter(session => session !== element);
            return updatedCallSessions
        })

        if (!sendingCall) {
            setAnswerCount((prevSessions) => {
                const updatedAnswerCount = prevSessions.filter(session => session !== element);
                return updatedAnswerCount
            })
        }


        setSessionIncall((prevSessions) => {
            const updatedSessionInCall = prevSessions.filter(session => session !== element);

            sessionIncallRef.current = updatedSessionInCall
            return updatedSessionInCall
        })

    };

    const handleDragStart = (e, val, setVal) => {
        const startX = e.clientX - val.x;
        const startY = e.clientY - val.y;

        const handleDragMove = (e) => {
            const newX = e.clientX - startX;
            const newY = e.clientY - startY;

            setVal({ x: newX, y: newY });
        };

        const handleDragEnd = () => {
            window.removeEventListener("mousemove", handleDragMove);
            window.removeEventListener("mouseup", handleDragEnd);
        };

        window.addEventListener("mousemove", handleDragMove);
        window.addEventListener("mouseup", handleDragEnd);
    };

    const fetchMyExtensionData = async () => {
      try {
        const data = await myExtensionPetition()
        
        if (data.default_outbound_callerid_number) {
          const number = data.default_outbound_callerid_number
          const value = { label: formatPhoneNumber(number), value: number }
          setCallerIdNumber(value)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const handleDropdownMenuOpen = () => {
      if (!assignedNumbers) {
        setIsLoadingNumbers(true);
      }
    }

    const handleChangeDropdonw = async (e) => {
      await updateCallerIdService(e, callerIdNumber, setCallerIdNumber)
    }

    useEffect(() => {
        let numberToCalltemClick = { number: numberToCallOnClick, data: {} }
        if (isClickCall) {
            if (numberToCalltemClick.number.length !== 0) {

                sipconfiguration.endPointToFindContactsToCall("findContact", { number: numberToCalltemClick.number })
                    .then(({ data: result }) => {
                        setNumberTocall({ number: numberToCalltemClick.number, data: result.result });
                        handleCall()
                        handleCloseActiveCall()
                    })
                    .catch((error) => {
                        console.log(error);
                        setNumberTocall({ number: numberToCalltemClick.number, data: {} });
                        handleCall()
                        handleCloseActiveCall()
                    });
            }
        }
    }, [isClickCall])

    useEffect(() => {
      if (audioInputStream && sessionIncall.length > 0) {
        sessionIncall.forEach((element) => {
          if (element?.sessionDescriptionHandler?.peerConnection?.getSenders()) {
            element.sessionDescriptionHandler.peerConnection.getSenders().forEach((sender) => {
                if (sender.track.kind === 'audio') {
                  sender.replaceTrack(audioInputStream.getAudioTracks()[0]);
                }
            });
          }
        });
      }
    }, [audioInputStream, sessionIncall]);

    useEffect(() => {
        if (audioOutputSinkId) {
          const sinkId = audioOutputSinkId
          const callAudioTemp = sessionIncall.length > 0 ? callAudio : new Audio()
  
          if (typeof callAudioTemp.sinkId !== "undefined") {
              callAudioTemp
                  .setSinkId(sinkId)
                  .then(() => {
                      console.log(`Success, audio output device attached: ${sinkId}`);
                      if (!sessionIncall.length) setCallAudio(callAudioTemp)
                      
                      setAudioOutputSelected(sinkId)
                  })
                  .catch((error) => {
                      let errorMessage = error;
                      if (error.name === "SecurityError") {
                          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                      }
                      console.error(errorMessage);
                  });
          } else {
            console.warn("Browser does not support output device selection.");
          }
  
          if (typeof ringtone.sinkId !== "undefined") {
              ringtone
                  .setSinkId(sinkId)
                  .then(() => {
                      console.log(`Success, audio output device attached: ${sinkId}`);
                      setAudioOutputSelected(sinkId)
                  })
                  .catch((error) => {
                      let errorMessage = error;
                      if (error.name === "SecurityError") {
                          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                      }
                      console.error(errorMessage);
                  });
          } else {
            console.warn("Browser does not support output device selection.");
          }
  
          if (typeof rinding.sinkId !== "undefined") {
              rinding
                  .setSinkId(sinkId)
                  .then(() => {
                      console.log(`Success, audio output device attached: ${sinkId}`);
                      setAudioOutputSelected(sinkId)
                  })
                  .catch((error) => {
                      let errorMessage = error;
                      if (error.name === "SecurityError") {
                          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                      }
                      console.error(errorMessage);
                  });
          } else {
            console.warn("Browser does not support output device selection.");
          }
  
        }
      }, [audioOutputSinkId]);

      useEffect(() => {
        console.log("Probando sessionIncall 123456789---------********asdfshsuwhuww sessionIncall",  sessionIncall);
      }, [sessionIncall]);

    useEffect(() => {
      if (isLoadingNumbers) {
        fetchAssignedNumbers(setAssignedNumbers, setIsLoadingNumbers);
      }
    }, [isLoadingNumbers]);

    useEffect(() => {
      if (showWebphone) fetchMyExtensionData()
    }, [showWebphone])

    useEffect(() => {
        const Devices = async () => {
            try {
                if (!navigator.mediaDevices?.enumerateDevices()) {
                    console.log("Not Devices false")
                } else {
                    let devices = await navigator.mediaDevices?.enumerateDevices();
                    let outputsAudioDevices = devices.filter(
                        (device) => device.kind === "audiooutput"
                    );
                    let inputsAudioDevices = devices.filter(
                        (device) => device.kind === "audioinput"
                    );

                    if (inputsAudioDevices.length === 0 || outputsAudioDevices === 0)
                        console.log("Not Devices true")
                    setDevices({
                        input: inputsAudioDevices,
                        output: outputsAudioDevices,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };
        Devices();
    }, []);

    return (
        <div className={showWebphone ? "showWebphone" : "hideWebphone"}>
          <AlertNotificactions 
            type={alertType}
            alertMessage={alertMessage}
            showTime={showErrorTime}
            title={alertTitle}
          />

            <div className='111111111'
                style={{
                    position: "absolute",
                    left: webphonePosition.x + "px",
                    top: webphonePosition.y + "px",
                }}
                onMouseDown={(e) => handleDragStart(e, webphonePosition, setWebphonePosition)}>
                {callSessions.length === 0 && <Keyboard
                    sipconfiguration={sipconfiguration}
                    numberToCall={numberToCall}
                    setNumberTocall={setNumberTocall}
                    handleCall={handleCall}
                    setCloseSettings={setCloseSettings}
                    enablePhone={enablePhone}
                    connecting={connecting}
                    setShowWebphone={setShowWebphone}
                    customSelectStyles={customSelectStyles}
                    assignedNumbers={assignedNumbers}
                    callerIdNumber={callerIdNumber}
                    handleChangeDropdonw={handleChangeDropdonw}
                    isLoadingNumbers={isLoadingNumbers}
                    handleDropdownMenuOpen={handleDropdownMenuOpen}
                ></Keyboard>}
                {/* <OnCall ></OnCall> */}
            </div>

            <div>
                <SipComponent
                    sipconfiguration={sipconfiguration}
                    call={call}
                    setCall={setCall}
                    reject={reject}
                    setReject={setReject}
                    bye={bye}
                    setBye={setBye}
                    byeOnCall={byeOnCall}
                    setByeOnCall={setByeOnCall}
                    answer={answer}
                    setAnswer={setAnswer}
                    numberToCall={numberToCall}
                    setNumberTocall={setNumberTocall}
                    setAnswerCount={setAnswerCount}
                    answerCount={answerCount}
                    setCallSessions={setCallSessions}
                    callSessions={callSessions}
                    handleAnswerOnInvite={handleAnswerOnInvite}
                    sessionIncall={sessionIncall}
                    agent={agent}
                    setAgent={setAgent}
                    setCallSessionsFunctionEnd={setCallSessionsFunctionEnd}
                    callAudio={callAudio}
                    setCallAudio={setCallAudio}
                    ringtone={ringtone}
                    enablePhone={enablePhone}
                    rinding={rinding}
                    setConnecting={setConnecting}
                />

                {answerCount.map((element, index) => (

                    <div key={index} style={{
                        position: "absolute",
                        left: incomingCallPosition.x + "px",
                        top: incomingCallPosition.y + "px",
                    }}
                        onMouseDown={(e) => handleDragStart(e, incomingCallPosition, setIncomingCallPosition)}>
                        <IncomingCall session={element} rejectedFunction={() => handleByeOnInvite(element, index)} answerFunction={() => handleAnswerOnInvitebefore(element, index)}></IncomingCall>
                        <br />
                    </div>
                ))}
                {
                    (sessionIncall.length > 0 && showOnCall) && <div style={{
                        position: "absolute",
                        left: onCallPosition.x + "px",
                        top: onCallPosition.y + "px",
                    }}
                        onMouseDown={(e) => handleDragStart(e, onCallPosition, setOncallPosition)}>
                        <OnCall
                            callSessions={callSessions}
                            handleBye={handleBye}
                            handleByeOnCall={handleByeOnCall}
                            sessionIncall={sessionIncall}
                            unHoldCall={unHoldCall}
                            holdCall={holdCall}
                            sipconfiguration={sipconfiguration}
                            setCallSessions={setCallSessions}
                            handleAnswerOnInvite={handleAnswerOnInvite}
                            agent={agent}
                            setAgent={setAgent}
                            setCallSessionsFunctionEnd={setCallSessionsFunctionEnd}
                            handleByeOnInvite={handleByeOnInvite}
                            conferenseSession={conferenseSession}
                            setCloseSettings={setCloseSettings}
                            callAudio={callAudio}
                            setCallAudio={setCallAudio}
                            rinding={rinding}
                            numberToCall={numberToCall}
                            setNumberTocall={setNumberTocall}
                            connecting={connecting}
                            setShowWebphone={setShowWebphone}
                            audioInputSelected={audioInputSelected}
                            sessionIncallRef={sessionIncallRef}
                            customSelectStyles={customSelectStyles}
                            assignedNumbers={assignedNumbers}
                            callerIdNumber={callerIdNumber}
                            handleChangeDropdonw={handleChangeDropdonw}
                            isLoadingNumbers={isLoadingNumbers}
                            handleDropdownMenuOpen={handleDropdownMenuOpen}
                        ></OnCall>
                    </div>
                }
                <div style={{
                    position: "absolute",
                    left: onSettingsPosition.x + "px",
                    top: onSettingsPosition.y + "px",
                }}
                >

                    {!closeSettings && <Settings
                        devices={devices}
                        setVolume={setVolume}
                        volume={volume}
                        sessionIncall={sessionIncall}
                        setCloseSettings={setCloseSettings}
                        callAudio={callAudio}
                        setCallAudio={setCallAudio}
                        changeRingtoneVolume={changeRingtoneVolume}
                        setEnablePhone={setEnablePhone}
                        enablePhone={enablePhone}
                        setAudioOutputSelected={setAudioOutputSelected}
                        audioOutputSelected={audioOutputSelected}
                        setAudioInputSeleced={setAudioInputSeleced}
                        audioInputSelected={audioInputSelected}
                        ringtone={ringtone}
                        rinding={rinding}
                        setAudioInputStream={setAudioInputStream}
                        setAudioOutputSinkId={setAudioOutputSinkId}
                    />}
                </div>

            </div>
        </div >
    );
};
export default WebPhone;
