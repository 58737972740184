import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

/**
 * Component for displaying contact details tabs
 * @param {Object} props - Component props
 * @param {string} props.activeTab - Currently active tab
 * @param {Function} props.toggleTab - Function to change active tab
 * @param {number} props.notesCount - Count of notes
 * @param {number} props.activityCount - Count of activities
 * @param {boolean} props.leads - Whether to show leads tab
 * @param {number} props.leadsCount - Count of leads
 */
export const ContactTabs = ({ 
  activeTab, 
  toggleTab, 
  notesCount, 
  activityCount,
  leads = false,
  leadsCount = 0
}) => {
  return (
    <div className="itp-contact-details-tabs">
      <Nav tabs className="itp-contact-details-nav">
        <NavItem>
          <NavLink
            className={activeTab === "activity" ? "active" : ""}
            onClick={() => toggleTab("activity")}
          >
            Activity {activityCount > 0 && <span className="itp-tab-count">{activityCount}</span>}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "notes" ? "active" : ""}
            onClick={() => toggleTab("notes")}
          >
            Notes {notesCount > 0 && <span className="itp-tab-count">{notesCount}</span>}
          </NavLink>
        </NavItem>
        {leads && (
          <NavItem>
            <NavLink
              className={activeTab === "leads" ? "active" : ""}
              onClick={() => toggleTab("leads")}
            >
              Leads {leadsCount > 0 && <span className="itp-tab-count">{leadsCount}</span>}
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </div>
  );
}; 