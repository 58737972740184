import formatTimeForReport from "../formatTimeForReport/formatTimeForReport";

const getTimeStaoFromDate = (timeframe) => {
  const timeStart = timeframe.timeStart;
  const timeEnd = timeframe.timeEnd;
  const hourStart = formatTimeForReport(timeframe.hourStart, false);
  const hourEnd = formatTimeForReport(timeframe.hourEnd, true);

  // Extraer horas, minutos y segundos del hourStart
  const [hoursStart, minutesStart, secondsStart] = hourStart.split(':').map(Number);

  // Extraer horas, minutos y segundos del hourEnd
  const [hoursEnd, minutesEnd, secondsEnd] = hourEnd.split(':').map(Number);

  const newTimeStart = new Date(timeStart);
  newTimeStart.setHours(hoursStart, minutesStart, secondsStart);

  const newTimeEnd = new Date(parseInt(timeEnd.split('-')[0]), parseInt(timeEnd.split('-')[1]) - 1, parseInt(timeEnd.split('-')[2]));
  newTimeEnd.setHours(hoursEnd, minutesEnd, secondsEnd);

  const finallyTimeStart = Math.floor(newTimeStart.getTime() / 1000);
  const finallyTimeEnd = Math.floor(newTimeEnd.getTime() / 1000);

  return { timeStart: finallyTimeStart, timeEnd: finallyTimeEnd };
};

export default getTimeStaoFromDate;
