/**
 * Formatea una hora para asegurarse de que tenga el formato HH:MM:SS
 * @param {string} time - La hora a formatear
 * @param {boolean} isEndTime - Indica si es la hora de fin (para agregar :59 en lugar de :00)
 * @returns {string} - La hora formateada
 */
const formatTimeForReport = (time, isEndTime = false) => {
  if (!time) {
    return isEndTime ? "23:59:59" : "00:00:00";
  }

  // Dividir la hora en sus componentes
  const parts = time.split(":");
  
  // Si solo tiene horas y minutos (HH:MM), agregar los segundos
  if (parts.length === 2) {
    return `${time}:${isEndTime ? "59" : "00"}`;
  }
  
  // Si ya tiene el formato completo (HH:MM:SS), devolverla tal cual
  return time;
};

export default formatTimeForReport; 