/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import { Stepper } from "react-form-stepper";
import { LoadingButton } from "../../../../components";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import "./10dlc.css";

const AddOrEditCampaign = ({ saveFunction, info, brand, loading, editRejectedCampaign }) => {
  const defaulValues = {
    description: "User for customers service and marketing purposes for \nonboarding new and existing customers",
    messageFlow: "Customers that sign up on our website will begin to receive text messages indicating the current status of their account. They will have the ability to contact our customer service team through this channel. For marketing, customers will receive messages if they sign up for our mailing list",
    sample1: "",
    sample2: "",
    sample3: "",
    mms_media1: null,
    mms_media1Temp: null,
    directLending: false,
    embeddedPhone: false,
    ageGated: false,
    affiliateMarketing: false,
    embeddedLink: false,
    optinKeywords: "",
    optoutKeywords: "",
    helpKeywords: "",
    brandId: "",
    helpMessage: "",
    optoutMessage: "",
    usecase: "",
    privacyPolicyLink: "",
    termsAndConditionsLink: "",
    optinMessage: "",
  };

  const [step, setStep] = useState(0);

  const steps = [
    { name: "Personal Info", component: <h2>Hola 1</h2> },
    { name: "Company Info", component: <h2>Hola 2</h2> },
    { name: "Business Hours", component: <h2>Hola 3</h2> },
    { name: "Email Setup", component: <h2>Hola 4</h2> },
  ];

  const [form, setForm] = useState(defaulValues);  
  const [invalidUrl, setInvalidUrl] = useState([])

  const handleChangeLinks = (e) => {
    const linkPattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(:\d+)?(\/.*)?$/i

    const { name, value } = e.target
    setForm({ ...form, [name]: value })

    if (value !== "" && !linkPattern.test(value)) {
      setInvalidUrl((prev) => {
        if (!prev.includes(name)) {
          return [...prev, name]
        } else {
          return prev
        }
      })
    } else if (value === "" || linkPattern.test(value)) {
      setInvalidUrl((prev) => prev.filter((el) => el !== name))
    }
  }

  useEffect(() => {
    if (info) {
      setForm({
        description: info?.description || "",
        messageFlow: info?.messageFlow || "",
        sample1: info?.sample1 || "",
        sample2: info?.sample2 || "",
        sample3: info?.sample3 || "",
        mms_media1: info?.mms_media1,
        mms_media1Temp: info?.mms_media1Temp,
        directLending: info.directLending,
        embeddedPhone: info.embeddedPhone,
        ageGated: info.ageGated,
        affiliateMarketing: info.affiliateMarketing,
        embeddedLink: info.embeddedLink,
        optinKeywords: info?.optinKeywords || "",
        optoutKeywords: info?.optoutKeywords || "",
        helpKeywords: info?.helpKeywords || "",
        brandId: info?.brandId || "",
        helpMessage: info?.helpMessage || "",
        optoutMessage: info?.optoutMessage || "",
        usecase: info.usecase || "",
        privacyPolicyLink: info?.privacyPolicyLink || "",
        termsAndConditionsLink: info?.termsAndConditionsLink || "",
        optinMessage: info?.optinMessage || "",
      });
    } else {
      setForm(defaulValues);
    }
  }, [info]);

  useEffect(() => {
    let liComponent = document.querySelector(".go3842760039");
    let liComponent2 = document.querySelector(".go2441762052");

    if (liComponent2) {
      liComponent2?.addEventListener("click", () => {
        console.log("change");
      });
    }

    if (liComponent?.value === steps.length - 1) {
      console.log(liComponent.value);
    }
  }, []);

  const disabledFunction = () => {
    switch (step) {
      case 0:
        if (
          form.brandId === "" ||
          form.brandId === null ||
          form.description === "" ||
          form.usecase === "" || 
          invalidUrl.length > 0
        ) {
          return true;
        } else {
          return false;
        }

      case 1:
        if (
          !form.sample1 ||
          !form.sample2 ||
          !form.sample3 || 
          form.sample1.length < 20 || 
          form.sample1.length > 500 ||
          form.sample2.length < 20 || 
          form.sample2.length > 500 ||
          form.sample3.length < 20 || 
          form.sample3.length > 500
        ) {
          return true;
        } else {
          return false;
        }

      case 3:
        if (
          form.optinKeywords === "" ||
          form.optoutKeywords === "" ||
          form.helpKeywords === "" ||
          form.helpMessage === "" ||
          form.optoutMessage === "" || 
          form.affiliateMarketing === true ||
          form.optinMessage === "" || 
          form.helpMessage.length < 20 ||
          form.helpMessage.length > 320 ||
          form.optinMessage.length < 20 ||
          form.optinMessage.length > 320 ||
          form.optoutMessage.length < 20 ||
          form.optoutMessage.length > 320
        ) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };

  const onClickFunction = async () => {
    if (step === steps.length - 1) {
      await saveFunction(form, setStep);
    } else {
      if (step !== steps.length - 1) {
        setStep(step + 1);
      }
    }
  };

  const onBackClick = () => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };

  return (
    <>
      <Stepper
        steps={[
          { label: "Step 1" },
          { label: "Step 2" },
          { label: "Step 3" },
          { label: "Step 4 " },
        ]}
        activeStep={step}
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <TabContent activeTab={step.toString()}>
            <TabPane tabId="0" className="p-3">
              <Step1 
                form={form} 
                setForm={setForm} 
                brand={brand}
                handleChangeLinks={handleChangeLinks}
                invalidUrl={invalidUrl}
              />
            </TabPane>
            <TabPane tabId="1" className="p-3">
              <Step2 form={form} setForm={setForm} />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <Step3 form={form} setForm={setForm} />
            </TabPane>
            <TabPane tabId="3" className="p-3">
              <Step4 form={form} setForm={setForm} />
            </TabPane>
          </TabContent>
          <div className="w-100 d-flex justify-content-end gap-1 mb-2">
            {step !== 0 && (
              <button className="btn-light" onClick={onBackClick}>
                Back
              </button>
            )}

            {(!info || (info && step !== 3) || editRejectedCampaign) && (
              <button
                className="btn-primary loading"
                onClick={onClickFunction}
                disabled={disabledFunction()}
              >
                {loading && <LoadingButton />}
                {step === steps.length - 1 ? editRejectedCampaign ? "Update" : "Create Campaign" : "Next"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrEditCampaign;
