import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalError = ({ modal, closeModal, message }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>Error</ModalHeader>
      <ModalBody>{message || "An error has occurred"}</ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={closeModal}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalError;
