import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { AlertNotificactions } from "../index";
import "./InputRange.css";

const InputRange = ({ startValue, endValue, onChange, type, startName, endName }) => {
  // Determinar si es un input de tipo fecha para aplicar clases adicionales
  const isDateType = type === "date" || type === "datetime-local" || type === "month" || type === "week" || type === "time";
  
  // Estado local para almacenar el valor anterior de endValue y startValue
  const [prevEndValue, setPrevEndValue] = useState(endValue);
  const [prevStartValue, setPrevStartValue] = useState(startValue);
  
  // Estados para la alerta
  const [showAlertTime, setShowAlertTime] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  
  // Función para convertir tiempo a minutos (para comparación)
  const timeToMinutes = (timeStr) => {
    if (!timeStr) return 0;
    const [hours, minutes, seconds = "0"] = timeStr.split(":");
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10) + parseInt(seconds, 10) / 60;
  };
  
  // Función para comparar valores según el tipo de input
  const isStartGreaterThanEnd = (start, end) => {
    if (type === "time") {
      const startMinutes = timeToMinutes(start);
      const endMinutes = timeToMinutes(end);
      return startMinutes > endMinutes;
    }
    
    // Para otros tipos de fecha, comparación directa
    return start > end;
  };
  
  // Función para manejar el cambio en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Si es un cambio en el valor final y es un tipo de fecha o tiempo
    if (isDateType && name === endName) {
      // Si el valor final es menor que el inicial
      if (isStartGreaterThanEnd(startValue, value)) {
        // Mostrar alerta al usuario
        setAlertMessage("The end value cannot be less than the initial value");
        setShowAlertTime(3000); // Mostrar por 3 segundos
        
        // Si el valor final es menor que el inicial, usar el valor anterior
        e.target.value = prevEndValue;
        // Llamar al onChange original con el valor corregido
        onChange({
          target: {
            name,
            value: prevEndValue
          }
        });
        return;
      }
      // Actualizar el valor anterior si es válido
      setPrevEndValue(value);
    }
    
    // Si es un cambio en el valor inicial y es un tipo de fecha o tiempo
    if (isDateType && name === startName) {
      // Si el valor inicial es mayor que el valor final
      if (isStartGreaterThanEnd(value, endValue)) {
        // Mostrar alerta al usuario
        setAlertMessage("The initial value cannot be greater than the end value");
        setShowAlertTime(3000); // Mostrar por 3 segundos
        
        // Revertir al valor anterior
        e.target.value = prevStartValue;
        // Llamar al onChange original con el valor corregido
        onChange({
          target: {
            name,
            value: prevStartValue
          }
        });
        return;
      }
      // Actualizar el valor anterior si es válido
      setPrevStartValue(value);
    }
    
    // Llamar al onChange original
    onChange(e);
  };
  
  // Actualizar prevEndValue cuando cambia endValue desde props
  useEffect(() => {
    setPrevEndValue(endValue);
  }, [endValue]);
  
  // Actualizar prevStartValue cuando cambia startValue desde props
  useEffect(() => {
    setPrevStartValue(startValue);
  }, [startValue]);
  
  // Resetear la alerta después de mostrarla
  useEffect(() => {
    if (showAlertTime > 0) {
      const timer = setTimeout(() => {
        setShowAlertTime(0);
      }, showAlertTime + 300);
      
      return () => clearTimeout(timer);
    }
  }, [showAlertTime]);
  
  return (
    <>
      <AlertNotificactions
        type="warning"
        alertMessage={alertMessage}
        showTime={showAlertTime}
        title="Validation of range"
      />
      <Row>
        <Col className="d-flex justify-content-start">
          <div className="container-input-range">
            <label className="input-range-label">From:</label>
            <input
              type={type}
              className={`input-range-input ${isDateType ? 'input-range-date' : ''}`}
              name={startName}
              value={startValue}
              onChange={handleChange}
            />
            <div>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="input-range-separator"
              />
            </div>
            <label className="input-range-label">To:</label>
            <input
              type={type}
              className={`input-range-input ${isDateType ? 'input-range-date' : ''}`}
              name={endName}
              value={endValue}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InputRange;
