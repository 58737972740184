import calculateAvgWordsPerMinute from "./calculateAvgWordsPerMinute";
import calculateGeneralSentiment from "./calculateGeneralSentiment";
import convertMillisToTime from "./convertMillisToTime";
import returnCallTranscription from "./returnCallTranscription";

const formatCallTranscription = (call) => {
  try {
    const callTranscription = returnCallTranscription(call);
    const transcriptionJson = callTranscription?.transcription;

    if (!callTranscription || !Object.keys(callTranscription)?.length || !transcriptionJson) {
      call.transcriptionPreview = "";
      return callTranscription;
    }

    const parsedTranscription = JSON.parse(transcriptionJson);
    const arrayTranscription = parsedTranscription?.Transcript;

    if (!arrayTranscription || !arrayTranscription?.length) {
      call.transcriptionPreview = "";
      return callTranscription;
    }

    const formatedTranscription = arrayTranscription.map((element) => {
      return {
        ParticipantRole: element.ParticipantRole,
        Content: element.Content,
        Time: convertMillisToTime(element.BeginOffsetMillis),
        Id: element.Id,
      };
    });

    const lastContent = formatedTranscription[formatedTranscription?.length - 1]?.Content || "";
    const formatedLastContent = lastContent.length > 30 ? lastContent.slice(0, 30) + "..." : lastContent;

    const callSummary = parsedTranscription?.ConversationCharacteristics?.ContactSummary?.AutoGenerated?.OverallSummary?.Content || "The conversation was too short to generate a summary";
    const numberofparticipants = parsedTranscription?.Participants?.length || 0;
    const wordsPerMinute = calculateAvgWordsPerMinute(parsedTranscription);
    const overalSentiment = calculateGeneralSentiment(parsedTranscription);
    const personalInformationRedacted = parsedTranscription?.Categories?.MatchedCategories?.length > 0 ? "Yes" : "No";

    const newCallTranscriptionObj = { 
      ...callTranscription,
      callSummary,
      numberofparticipants,
      wordsPerMinute,
      overalSentiment,
      personalInformationRedacted,
      formatedTranscription, 
    };

    call.transcriptionPreview = formatedLastContent;
    call.callTranscription = true;
    return newCallTranscriptionObj
  } catch (error) {
    console.log(error);
    call.callTranscription = false;
    call.transcriptionPreview = "";
    return {};
  }
};

export default formatCallTranscription;
