import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { AudioMessage, CollapsePanel, DropdownButton, MenuRight } from "../../../../../components";
import { RecordSound, UploadFile } from "./components";
import { fetchVmGreeting, fetchVoicemailSettings, sendVmGreetingService } from "./services";
import Switch from "react-switch";
import Skeleton from "react-loading-skeleton";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionPatch from "../../../../../services/petitionPatch";

const Voicemail = ({ myExtension, setCompletedRequests, loading }) => {
  const [sendvoicemail, setSendvoicemail] = useState({ sendvoicemail: "no" });
  const [openMenu, setOpenMenu] = useState(false)
  const [fileOption, setFileOption] = useState("")
  const [loadingButton, setLoadingButton] = useState(false)
  const [vmGreeeting, setvmGreeeting] = useState(null)
  const [deleteVoicemail, setDeleteVoicemail] = useState({
    deletevoicemail: "no",
  });

  const closeMenu = () => {
    setOpenMenu(false)
    setFileOption("")
    setLoadingButton(false)
  }

  const handeClickFileOption = (option) => {
    if (loading) return;

    setFileOption(option)
    setOpenMenu(true)
  }

  const handleChangeSendVoicemail = () => {
    const currentStateSwitch = sendvoicemail;

    const data = {
      sendvoicemail: sendvoicemail.sendvoicemail === "yes" ? "no" : "yes",
    };

    setSendvoicemail(data);

    petitionPatch("voicemailUserSetings", { data })
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
        setSendvoicemail(currentStateSwitch);
      });
  };

  const handleChangeDeleteVoicemail = (key) => {
    const currentStateSwitch = deleteVoicemail;

    const data = {
      deletevoicemail: deleteVoicemail.deletevoicemail === "yes" ? "no" : "yes",
    };

    setDeleteVoicemail(data);

    petitionPatch("voicemailUserSetings", { data })
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
        setDeleteVoicemail(currentStateSwitch);
      });
  };

  const addUploadedAndRecordedFile = async (file) => {
    await sendVmGreetingService(file, setLoadingButton, closeMenu, setvmGreeeting)
  }

  const petition = async () => {
    const voicemailGreeting = await fetchVmGreeting()
    setvmGreeeting(voicemailGreeting)

    await fetchVoicemailSettings(setSendvoicemail, setDeleteVoicemail, setCompletedRequests)
  };

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length) {
      petition();
    }
  }, [myExtension]);

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Voicemails"
        subtitle="Send email or delete voicemail, when has been received"
      >
        <span className="itp-my-settings-card-title">Voicemail</span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                Send email when a voicemail is received to your direct mailbox
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={sendvoicemail.sendvoicemail === "yes" ? true : false}
                  onChange={handleChangeSendVoicemail}
                />
              </div>
            )}
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">Delete Voicemail</span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                Automatically delete messages when a user leaves a message from
                my mailbox.
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20}
                  width={40}
                  checked={
                    deleteVoicemail.deletevoicemail === "yes" ? true : false
                  }
                  onChange={handleChangeDeleteVoicemail}
                />
              </div>
            )}
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">Personal Greeting</span>
        {vmGreeeting ? (
          <>
            <Row className="d-flex align-items-center">
              <Col 
                xs={10} 
                sm={10} 
                md={10} 
                lg={10} 
                xl={10} 
                xxl={10}
              > 
                <AudioMessage
                  audioUrl={vmGreeeting}
                  audioType="audio/wav"
                  variant="secondary"
                  noFetch
                />
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                <DropdownButton
                  name="Modify"
                  className="mt-2"
                  actions={[
                    { name: "Upload File", handleClick: () => handeClickFileOption("upload") },
                    { name: "Record Sound", handleClick: () => handeClickFileOption("record") },
                  ]}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <DropdownButton
              name="Add"
              className="mt-2"
              actions={[
                { name: "Upload File", handleClick: () => handeClickFileOption("upload") },
                { name: "Record Sound", handleClick: () => handeClickFileOption("record") },
              ]}
            />
          </>
        )}
      </CollapsePanel>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Add Personal Greeting"
        to="My Settings"
      >
        {openMenu && fileOption === "upload" && (
          <UploadFile
            addMediaFile={addUploadedAndRecordedFile}
            loadingButton={loadingButton}
          />
        )}

        {openMenu && fileOption === "record"&& (
          <RecordSound
            addMediaFile={addUploadedAndRecordedFile}
            loadingButton={loadingButton}
          />
        )}
      </MenuRight>
    </>
  );
};

export default Voicemail;
