import petitionGet from "../../../../../../../services/petitionGet";
import arrayBufferToBase64 from "../../../../../../../utils/arrayBufferToBase64";

const fetchVmGreeting = async () => {
  try {
    const response = await petitionGet("vmGreeting", false, false, "arraybuffer")
    // Get the MIME type from the response headers
    const contentType = response.headers['content-type'] || 'audio/wav';

    // Convert the ArrayBuffer to a base64 string
    const base64Audio = arrayBufferToBase64(response.data)

    // Create the base64 audio source
    const audioSrc = `data:${contentType === "audio/basic" ? "audio/mp3" : contentType};base64,${base64Audio}`;
    return audioSrc
  } catch (error) {
    console.log(error)
    return null
  }
};

export default fetchVmGreeting;